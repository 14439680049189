import React, {useEffect, useState} from 'react';
import styled from 'styled-components/native';
import {View, Image, ScrollView, Dimensions,} from 'react-native';
import {useGetBeachQuery} from '../../../graphql-types';
import {useRoute, RouteProp, useNavigation} from '@react-navigation/native';
import {BeachStackParamList} from '../../../types';
import {useLoading} from '../../../hooks/useLoadingContext';
import BeachHeader from '../BeachHeader';
import {useMapLocation} from '../../../hooks/useMapLocationContext';
import constants from '../../../constants/constants';
import * as Device from 'expo-device';

const BeachContainer = styled(View)<{isDesktop?: boolean}>`
  ${({isDesktop}) => {
    if (isDesktop) {
  		return `
			max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
  		`;
  	}
  	
  	return ``;
  	}}
`;
const ContainerScroll = styled(ScrollView)`
`;

const Photo = styled(Image)`
  width: 100%;
  height: 300px;
`;

export type BeachScreenRouteProps = RouteProp<
  BeachStackParamList,
  'BeachScreen'
>;

type PhotosProps = {};

export default function Photos({}: PhotosProps) {
  const navigation = useNavigation();
	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);
  const {set: setMapLocation} = useMapLocation();
  const {params} = useRoute<BeachScreenRouteProps>();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading} = useGetBeachQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id: params.beach,
    },
  });

  useEffect(() => {
    if (loading) {
      setLoading();
    } else {
      clearLoading();
    }
  }, [loading]);

  useEffect(() => {
    if (!data || !data.beach || !data.beach.latitude || !data.beach.longitude) {
      return;
    }

    setMapLocation({
      latitude: beach.latitude,
      longitude: beach.longitude,
    });
  }, [data]);

  useEffect(() => {
    if (data && data.beach) {
      navigation.setOptions({title: data.beach.name});
    }
  }, [data]);

	useEffect(() => {
		const getDeviceType = async () => {
			const type = await Device.getDeviceTypeAsync();
			setDeviceType(type);
		};

		getDeviceType();
	});

  if (!data) {
    return null;
  }

  if (!data.beach) {
    // @todo failed to get beach
    return null;
  }

  const beach = data.beach;

  return (
    <BeachContainer isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      <BeachHeader name={data.beach.name} location={data.beach.location} />
		  <ContainerScroll>
			{data.beach.imageAttachments?.map((imageAttachment, idx) => (
			  <Photo key={idx} source={{uri: imageAttachment.previewUrl}} />
			))}
		  </ContainerScroll>
    </BeachContainer>
  );
}
