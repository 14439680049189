import React, {useEffect} from 'react';
import {useSearchBeachesQuery} from '../../graphql-types';
import {useLoading} from '../../hooks/useLoadingContext';
import BeachList from './BeachList';
import Text from '../Text';
import colors from '../../constants/colors';
import styled from 'styled-components/native';
import {View} from 'react-native';

type SearchResultsProps = {
  searchText: string;
  onPress: () => void;
};

const NoResultsFound = styled(View)`
  margin-top: 5px;
  background-color: ${colors.app.white};
  padding: 10px;
`;

export default function SearchResults({
  searchText,
  onPress,
}: SearchResultsProps) {
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading} = useSearchBeachesQuery({
    fetchPolicy: 'network-only',
    variables: {
      search: searchText,
    },
  });

  useEffect(() => {
    if (loading) {
      setLoading();
    }
    clearLoading();
  }, [loading]);

  if (!data || !data.searchBeaches) {
    // @todo error check this
    return null;
  }
	
  return (
    <>
      {data.searchBeaches.length ? (
        <BeachList beaches={data.searchBeaches} onPress={onPress} />
      ) : (
        <NoResultsFound>
          <Text>No results found.</Text>
        </NoResultsFound>
      )}
    </>
  );
}
