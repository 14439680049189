import React, {useContext, PropsWithChildren, useState} from 'react';
import {View, Text, ActivityIndicator} from 'react-native';
import styled from 'styled-components/native';
import colors from '../constants/colors';

type SetLoading = () => void;
type ClearLoading = () => void;

type LoadingContext = {
  loading: true[];
  set: SetLoading;
  clear: ClearLoading;
};

const LoadingContext = React.createContext<LoadingContext>({
  loading: [true],
  set: () => {},
  clear: () => {},
});

const Loading = styled(View)`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  z-index: 100000;
`;

const LoadingProvider = ({children, ...props}: PropsWithChildren<{}>) => {
  const [state, setState] = useState<true[]>([]);

  const set: SetLoading = () => {
    setState([...state, true]);
  };

  const clear: ClearLoading = () => {
    let cleared = [...state];
    cleared.pop();
    setState(cleared);
  };

  return (
    <>
      <LoadingContext.Provider value={{loading: state, set, clear}} {...props}>
        {children}
      </LoadingContext.Provider>
      {state.length ? (
        <Loading>
          <ActivityIndicator animating={true} size="large" color={colors.app.black} />
        </Loading>
      ) : null}
    </>
  );
};

const useLoading = () => useContext(LoadingContext);

export {LoadingProvider, useLoading};
