import styled from 'styled-components/native';
import {View, ImageBackground, Image} from 'react-native';
import Text from '../../components/Text';
import colors from '../../constants/colors';

export const SearchBox = styled(View)`
  position: absolute;
  top: ${({top}: {top: number}) => (top ? `${top}` : '10')}px;
  left: 10px;
  right: 10px;
  z-index: 10000;
`;

export const Container = styled.ScrollView.attrs(() => ({
  contentContainerStyle: {
    flexGrow: 1,
  },
}))`
  flex: 1;
  flex-grow: 1;
  display: flex;
`;

export const Background = styled(ImageBackground)`
  padding-top: 40px;
  flex: 1;
  resize-mode: cover;
  flex-grow: 1;
  background-color: ${colors.mote.primary.powderBlue};
`;

export const Header = styled(View)`
  z-index: 100000;
  position: absolute;
  top: 0;
  height: 40px;
  width: 100%;
  flex-direction: row;
  flex-grow: 0;
  background-color: ${colors.mote.primary.powderBlue};
`;

export const Cancel = styled(Image)`
  width: 18px;
  height: 18px;
  margin: 10px;
`;

export const Title = styled(Text)`
  flex-grow: 1;
  padding: 15px;
  font-weight: bold;
`;
