import React from 'react';
import Text from '../../Text';
import {BeachReportFragment} from '../../../graphql-types';
import styled from 'styled-components/native';
import colors from '../../../constants/colors';
import CalendarIcon from '../../../assets/images/calendar-icon.png';
import {Image, View, TouchableOpacity} from 'react-native';
import dateFormat from 'dateformat';
import {useNavigation, useRoute, RouteProp} from '@react-navigation/native';
import {BeachStackParamList} from '../../../types';

type DateTabsProps = {
  reports: BeachReportFragment[];
  reportId: string;
};

const TabsContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Tab = styled(TouchableOpacity)`
  flex-grow: 1;
  background-color: ${({active}: {active: boolean}) =>
    active ? colors.mote.primary.powderBlue : colors.mote.primary.blue};
  padding: 10px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const TabText = styled(Text)`
  padding-top: 3px;
  color: ${colors.app.white};
`;

const TabIcon = styled(Image)`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`;

export type BeachReportScreenRouteProps = RouteProp<
  BeachStackParamList,
  'BeachReportScreen'
>;

const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear()
  );
};
export default function DateTabs({reports, reportId}: DateTabsProps) {
  const navigation = useNavigation();
  const {params} = useRoute<BeachReportScreenRouteProps>();

  let reversed = [...reports].reverse();

  return (
    <TabsContainer>
      {reversed.map((report, idx) => {
        const date = new Date(report.createdAt);
        return (
          <Tab
            active={report.id == reportId}
            key={idx}
            onPress={() => {
              navigation.navigate('BeachReportScreen', {
                beach: params.beach,
                report: report.id,
              });
            }}>
            <TabIcon source={CalendarIcon} />
            <TabText>
              {isToday(date) ? 'Today' : dateFormat(date, 'mm/dd')}
            </TabText>
          </Tab>
        );
      })}
    </TabsContainer>
  );
}
