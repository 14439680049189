import React, {PropsWithChildren} from 'react';
import {
  StyleProp,
  ViewProps,
  TouchableOpacityProps,
  GestureResponderEvent,
} from 'react-native';
import {TouchableOpacity} from 'react-native';
import ListItem, {ListItemProps} from './ListItem';

export default function ListItemButton({
  children,
  style,
  listItemStyle,
  onPress,
  disabled,
  ...props
}: PropsWithChildren<
  {
    style?: StyleProp<TouchableOpacityProps>;
    listItemStyle?: StyleProp<ViewProps>;
    onPress?: (e: GestureResponderEvent) => void;
    disabled?: boolean;
  } & ListItemProps
>) {
  return (
    <TouchableOpacity disabled={disabled} style={style} onPress={onPress}>
      <ListItem style={listItemStyle} {...props} />
    </TouchableOpacity>
  );
}
