import React, {useEffect, useState} from 'react';
import Marker from './Marker';
import {
  BoundsInput,
  ClusterFragment,
  GetMappedReportsQuery,
  GetMappedReportsQueryVariables,
} from '../../graphql-types';
import {useLoading} from '../../hooks/useLoadingContext';
import {useNavigation} from '@react-navigation/native';
import Map from '../Map';
import Dialog from './csic/dialog';
import DefaultLocation from '../../constants/location';
import {convertRegionToBoundsInput} from '../../utils';
import {graphql, ChildDataProps} from '@apollo/client/react/hoc';
import {getMappedReportsQuery} from '../../graphql';
import {View, Image} from 'react-native';
import {useLayerSelection} from '../../hooks/useLayerSelectionContext';
import styled from 'styled-components/native';
import Text from '../Text';
import BeachMapMarkerIcon from '../../assets/images/map-marker-blue.png';
import LayerMapMarkerIcon from '../../assets/images/map-marker-lightblue.png';
import * as Device from 'expo-device';
import constants from '../../constants/constants';
import colors from '../../constants/colors';
import Banner from '../BannerMessage';


type MapLocation = {
  latitude: number;
  longitude: number;
};

type MappedReportProps = {
  location: MapLocation;
  layer: string;
};

type ChildProps = ChildDataProps<
  MappedReportProps,
  GetMappedReportsQuery,
  GetMappedReportsQueryVariables
>;

const withMappedReports = graphql<
  MappedReportProps,
  GetMappedReportsQuery,
  GetMappedReportsQueryVariables,
  ChildProps
>(getMappedReportsQuery, {
  options: ({location, layer}) => ({
    fetchPolicy: 'cache-and-network',
    variables: {
      layerId: layer,
      bounds: convertRegionToBoundsInput({
        ...DefaultLocation,
        latitude: location.latitude,
        longitude: location.longitude,
      }),
    },
  }),
});

export default withMappedReports(({data, location}) => {
  const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
    false,
  );
  const navigation = useNavigation();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const [bounds, setBounds] = useState<BoundsInput>(
    convertRegionToBoundsInput({
      ...DefaultLocation,
      latitude: location.latitude,
      longitude: location.longitude,
    }),
  );
  const [selected, setSelected] = useState<ClusterFragment | null>(null);
  const {layer} = useLayerSelection();

  useEffect(() => {
    const getDeviceType = async () => {
      const type = await Device.getDeviceTypeAsync();
      setDeviceType(type);
    };

    getDeviceType();
  });

  useEffect(() => {
    if (data?.loading) {
      return setLoading();
    }
    clearLoading();
  }, [data]);

  useEffect(() => {
    const updateSearchResult = async (region: BoundsInput) => {
      setLoading();
      await data?.refetch({
        layerId: layer,
        bounds: region,
      });
      clearLoading();
    };

    updateSearchResult(convertRegionToBoundsInput(DefaultLocation));
  }, []);

  const onSetBounds = (region: BoundsInput) => {
    const updateSearchResult = async (region: BoundsInput) => {
      setLoading();
      await data?.refetch({
        layerId: layer,
        bounds: region,
      });
      clearLoading();
    };

    setBounds(region);
    updateSearchResult(region);
  };

  return (
    <>
      {deviceType == Device.DeviceType.PHONE && selected ? (
        <Dialog
          isDesktop={false}
          onCancel={() => setSelected(null)}
          reports={selected.reports}
        />
      ) : null}
      <Map onSetBounds={onSetBounds} bounds={bounds}>
        {data?.beaches
          ? data.beaches.map(beach => (
              <Marker
                icon={BeachMapMarkerIcon}
                onPress={() => {
                  navigation.navigate('Beach', {
                    screen: 'BeachScreen',
                    params: {beach: beach.id},
                  });
                }}
                title={beach.name}
                key={beach.id}
                lat={beach.latitude}
                lng={beach.longitude}
              />
            ))
          : null}
        {data?.clusteredLayerReports?.clusters
          ? data.clusteredLayerReports.clusters.map((cluster, idx) => {
              return (
                <Marker
                  showCallout={cluster.key == selected?.key}
                  onPress={_ => {
                    setSelected(cluster);
                  }}
                  callout={
                    <Dialog
                      isDesktop={deviceType == Device.DeviceType.DESKTOP}
                      onCancel={() => setSelected(null)}
                      reports={cluster.reports}
                    />
                  }
                  icon={LayerMapMarkerIcon}
                  key={idx}
                  title={`${cluster.reports?.length ?? 0} reports`}
                  lat={cluster.center.latitude}
                  lng={cluster.center.longitude}
                />
              );
            })
          : null}
      </Map>
      
      {/* Comment out Banner if not needed */}
    {/* <Banner /> */}
    </>
  );
});
