import React from 'react';
import ForwardIcon from '../../../assets/images/forward-arrow.png';
import {useAuthenticatedUser} from '../../../hooks/useAuthenticatedUserContext';
import ListItemButton from '../../ListItemButton';
import {useNavigation} from '@react-navigation/native';

export default function CreateReport() {
  const navigation = useNavigation();
  const {user} = useAuthenticatedUser();

  if (!user) {
    return null;
  }

  const {
    user: {beaches},
  } = user;

  return (
    <>
      {beaches.map(beach => {
        return (
          <ListItemButton
            onPress={_ => {
              navigation.navigate('BCRSWizardScreen', {
                beach: beach.id,
                parameter: beach.firstParameter.id,
              });
            }}
            key={beach.id}
            icon={ForwardIcon}
            title={beach.name}
            description={`Ambassador report for ${beach.name}`}
          />
        );
      })}
    </>
  );
}
