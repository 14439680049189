import gql from 'graphql-tag';
import {ReportFragment} from '../fragments';

export const submitReportMutation = gql`
  mutation SubmitReport($input: SubmitReportInput!) {
    submitReport(input: $input) {
      success
      report {
        ...Report
      }
    }
  }
  ${ReportFragment}
`;

export const agreeWithReportMutation = gql`
  mutation AgreeWithReport($input: ValidateReportInput!) {
    agreeWithReport(input: $input) {
      success
      report {
        ...Report
      }
    }
  }
  ${ReportFragment}
`;

export const disagreeWithReportMutation = gql`
  mutation DisagreeWithReport($input: ValidateReportInput!) {
    disagreeWithReport(input: $input) {
      success
      report {
        ...Report
      }
    }
  }
  ${ReportFragment}
`;
