import gql from 'graphql-tag';
import {LayerFragment} from '../fragments';

export const getLayersQuery = gql`
  query GetLayers($type: LayerType) {
    layers(type: $type) {
      ...Layer
    }
  }
  ${LayerFragment}
`;

export const getLayerQuery = gql`
  query GetLayer($id: ID!) {
    layer(id: $id) {
      ...Layer
    }
  }
  ${LayerFragment}
`;
