import * as React from 'react';
import createModalNavigator from './createModalNavigator';
import createDesktopNavigator from './createDesktopNavigator';

import AlertsSubscribeScreen from '../screens/AlertsSubscribeScreen';
import ResourcesScreen from '../screens/ResourcesScreen';
import ContactScreen from '../screens/ContactScreen';
import {
  DesktopStackParamList,
  CreateReportStackParamList,
  WelcomeStackParamList,
  AlertsStackParamList,
  ResourcesStackParamList,
  ContactStackParamList,
  BeachStackParamList,
  EducationStackParamList,
  SearchStackParamList,
} from '../../types';
import WelcomeScreen from '../screens/WelcomeScreen';
import CreateReportScreen from '../screens/CreateReportScreen';
import SignInUserScreen from '../screens/SignInUserScreen';
import SelectParameterCategoryScreen from '../screens/SelectParameterCategoryScreen';
import {useAuthenticatedUser} from '../../hooks/useAuthenticatedUserContext';
import BCRSWizardScreen from '../screens/BCRSWizardScreen';
import CSICWizardScreen from '../screens/CSICWizardScreen';
import ReportSuccessScreen from '../screens/ReportSuccessScreen';
import ReportPendingScreen from '../screens/ReportPendingScreen';
import ReportFailedScreen from '../screens/ReportFailedScreen';
import BeachScreen from '../screens/BeachScreen';
import BCRSSelectPhotosScreen from '../screens/BCRSSelectPhotosScreen';
import BCRSReviewScreen from '../screens/BCRSReviewScreen';
import BeachReportScreen from '../screens/BeachReportScreen';
import BeachAmenitiesScreen from '../screens/BeachAmenitiesScreen';
import BeachPhotosScreen from '../screens/BeachPhotosScreen';
import {useAlertSubscription} from '../../hooks/useAlertSubscriptionContext';
import AlertsVerifyEmailScreen from '../screens/AlertsVerifyEmailScreen';
import BeachAlertsScreen from '../screens/BeachAlertsScreen';
import EducationScreen from '../screens/EducationScreen';
import FrequentlyAskedQuestionCategoriesScreen from '../screens/FrequentlyAskedQuestionsCategoriesScreen';
import VideosScreen from '../screens/VideosScreen';
import FrequentlyAskedQuestionsScreen from '../screens/FrequentlyAskedQuestionsScreen';
import VideoScreen from '../screens/VideoScreen';
import InfographicsScreen from '../screens/InfographicsScreen';
import SearchScreen from '../screens/SearchScreen';

const Desktop = createDesktopNavigator<DesktopStackParamList>();

export default function DesktopTabNavigator() {
  return (
    <>
      <Desktop.Navigator initialRouteName="Welcome">
        <Desktop.Screen
          name="Map"
          options={{title: 'Mote Beach Conditions Reporting System'}}>
          {() => <></>}
        </Desktop.Screen>
        <Desktop.Screen
          name="Welcome"
          options={{title: 'Mote Beach Conditions Reporting System'}}
          component={WelcomeScreenNavigator}
        />
        <Desktop.Screen
          options={{title: 'Create a Report'}}
          name="CreateReport"
          component={CreateReportScreenNavigator}
        />
        <Desktop.Screen
          name="Alerts"
          options={{title: 'Subscribe to Beach Alerts'}}
          component={AlertsScreenNavigator}
        />
        <Desktop.Screen
          name="Resources"
          options={{title: 'Beach & Beach Condition Resources'}}
          component={ResourcesScreenNavigator}
        />
        <Desktop.Screen
          name="Contact"
          options={{title: 'Contact Mote'}}
          component={ContactScreenNavigator}
        />
        <Desktop.Screen
          name="Education"
          options={{title: 'Education'}}
          component={EducationScreenNavigator}
        />
        <Desktop.Screen
          name="Beach"
          options={{title: 'Beach'}}
          component={BeachScreenNavigator}
        />
        <Desktop.Screen
          name="Search"
          options={{title: 'Search'}}
          component={SearchScreenNavigator}
        />
     </Desktop.Navigator>
    </>
  );
}

const WelcomeScreenStack = createModalNavigator<WelcomeStackParamList>();

function WelcomeScreenNavigator() {
  return (
    <WelcomeScreenStack.Navigator>
      <WelcomeScreenStack.Screen
        options={{title: 'Welcome to Mote Beach Conditions Reporting System'}}
        name="WelcomeScreen"
        component={WelcomeScreen}
      />
    </WelcomeScreenStack.Navigator>
  );
}

const AlertsScreenStack = createModalNavigator<AlertsStackParamList>();

function HasSubscriptionScreens() {
  return (
    <>
      <AlertsScreenStack.Screen
        name="BeachAlertsScreen"
        component={BeachAlertsScreen}
        options={{title: 'Manage Alerts'}}
      />
    </>
  );
}

function NoSubscriptionScreens() {
  return (
    <>
      <AlertsScreenStack.Screen
        name="AlertsSubscribeScreen"
        component={AlertsSubscribeScreen}
        options={{title: 'Subscribe to Beach Alerts'}}
      />
      <AlertsScreenStack.Screen
        name="AlertsVerifyEmailScreen"
        component={AlertsVerifyEmailScreen}
        options={{title: 'Verify Email'}}
      />
      <AlertsScreenStack.Screen
        name="BeachAlertsScreen"
        component={AlertsSubscribeScreen}
        options={{title: 'Manage Alerts'}}
      />
    </>
  );
}

function AlertsScreenNavigator() {
  const {subscription} = useAlertSubscription();
  return (
    <AlertsScreenStack.Navigator>
      {subscription ? HasSubscriptionScreens() : NoSubscriptionScreens()}
    </AlertsScreenStack.Navigator>
  );
}

const ResourcesScreenStack = createModalNavigator<ResourcesStackParamList>();

function ResourcesScreenNavigator() {
  return (
    <ResourcesScreenStack.Navigator>
      <ResourcesScreenStack.Screen
        options={{title: 'Beach & Beach Condition Resources'}}
        name="ResourcesScreen"
        component={ResourcesScreen}
      />
    </ResourcesScreenStack.Navigator>
  );
}

const ContactScreenStack = createModalNavigator<ContactStackParamList>();

function ContactScreenNavigator() {
  return (
    <ContactScreenStack.Navigator>
      <ContactScreenStack.Screen
        options={{title: 'Contact Mote'}}
        name="ContactScreen"
        component={ContactScreen}
      />
    </ContactScreenStack.Navigator>
  );
}

const EducationScreenStack = createModalNavigator<EducationStackParamList>();

function EducationScreenNavigator() {
  return (
    <EducationScreenStack.Navigator>
      <EducationScreenStack.Screen
        name="EducationScreen"
        component={EducationScreen}
        options={{title: 'Education'}}
      />
      <EducationScreenStack.Screen
        name="FrequentlyAskedQuestionCategories"
        component={FrequentlyAskedQuestionCategoriesScreen}
        options={{title: 'Frequently Asked Questions'}}
      />
      <EducationScreenStack.Screen
        name="FrequentlyAskedQuestions"
        component={FrequentlyAskedQuestionsScreen}
        options={{title: 'Frequently Asked Questions'}}
      />
      <EducationScreenStack.Screen
        name="Infographics"
        component={InfographicsScreen}
        options={{title: 'Infographics'}}
      />
      <EducationScreenStack.Screen
        name="Videos"
        component={VideosScreen}
        options={{title: 'Videos'}}
      />
      <EducationScreenStack.Screen
        name="Video"
        component={VideoScreen}
        options={{title: 'Video'}}
      />
    </EducationScreenStack.Navigator>
  );
}

const BeachScreenStack = createModalNavigator<BeachStackParamList>();

function BeachScreenNavigator() {
  return (
    <BeachScreenStack.Navigator>
      <BeachScreenStack.Screen
        options={{title: 'Beach'}}
        name="BeachScreen"
        component={BeachScreen}
      />
      <BeachScreenStack.Screen
        options={{title: 'Reports'}}
        name="BeachReportScreen"
        component={BeachReportScreen}
      />
      <BeachScreenStack.Screen
        options={{title: 'Amenities'}}
        name="BeachAmenitiesScreen"
        component={BeachAmenitiesScreen}
      />
      <BeachScreenStack.Screen
        options={{title: 'Photos'}}
        name="BeachPhotosScreen"
        component={BeachPhotosScreen}
      />
    </BeachScreenStack.Navigator>
  );
}

const SearchScreenStack = createModalNavigator<SearchStackParamList>();

function SearchScreenNavigator() {
  return (
    <SearchScreenStack.Navigator>
      <SearchScreenStack.Screen
        name="SearchScreen"
        component={SearchScreen}
      />
    </SearchScreenStack.Navigator>
  );
}

const CreateReportScreenStack = createModalNavigator<CreateReportStackParamList>();

function UnauthenticatedRoutes() {
  return (
    <>
      <CreateReportScreenStack.Screen
        options={{title: 'Sign to Beach Conditions Reporting System'}}
        name="CreateReportScreen"
        component={SignInUserScreen}
      />
    </>
  );
}

function AuthenticatedRoutes() {
  return (
    <>
      <CreateReportScreenStack.Screen
        options={{title: 'Create a Report'}}
        name="CreateReportScreen"
        component={CreateReportScreen}
      />
      <CreateReportScreenStack.Screen
        options={{title: 'Select a Layer'}}
        name="SelectParameterCategoryScreen"
        component={SelectParameterCategoryScreen}
      />
      <CreateReportScreenStack.Screen
        options={{title: 'Creating a Report'}}
        name="BCRSWizardScreen"
        component={BCRSWizardScreen}
      />
      <CreateReportScreenStack.Screen
        options={{title: 'Upload Photos'}}
        name="BCRSSelectPhotosScreen"
        component={BCRSSelectPhotosScreen}
      />
      <CreateReportScreenStack.Screen
        options={{title: 'Review'}}
        name="BCRSReviewScreen"
        component={BCRSReviewScreen}
      />
      <CreateReportScreenStack.Screen
        options={{title: 'Creating a Report'}}
        name="CSICWizardScreen"
        component={CSICWizardScreen}
      />
      <CreateReportScreenStack.Screen
        options={{title: 'Report Published'}}
        name="ReportSuccessScreen"
        component={ReportSuccessScreen}
      />
      <CreateReportScreenStack.Screen
        options={{title: 'Report Pending'}}
        name="ReportPendingScreen"
        component={ReportPendingScreen}
      />
      <CreateReportScreenStack.Screen
        options={{title: 'Report Failed'}}
        name="ReportFailedScreen"
        component={ReportFailedScreen}
      />
    </>
  );
}

function CreateReportScreenNavigator() {
  const {user} = useAuthenticatedUser();

  return (
    <CreateReportScreenStack.Navigator>
      {user ? AuthenticatedRoutes() : UnauthenticatedRoutes()}
    </CreateReportScreenStack.Navigator>
  );
}
