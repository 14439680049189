import React from 'react';
import {
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  TextStyle,
  Image,
  TouchableOpacityProps,
} from 'react-native';
import Text from '../components/Text';
import styled from 'styled-components/native';
import colors from '../constants/colors';

const Container = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
`;

const ButtonText = styled(Text)`
  flex-grow: 1;
  color: ${colors.mote.primary.blue};
`;

type ButtonProps = React.PropsWithChildren<
  TouchableOpacityProps & {
    style?: StyleProp<ViewStyle>;
    textStyle?: StyleProp<TextStyle>;
    icon?: any;
  }
>;

export default function Button({
  children,
  textStyle,
  icon,
  ...props
}: ButtonProps) {
  return (
    <Container {...props}>
      <ButtonText style={textStyle}>{children}</ButtonText>
      <Image source={icon} style={{width: 20, height: 20}} />
    </Container>
  );
}
