import gql from 'graphql-tag';
import {BeachFragment, ClusterFragment} from '../fragments';

export const getMappedReportsQuery = gql`
  query GetMappedReports($bounds: BoundsInput!, $layerId: ID!) {
    beaches(orderBy: [{column: NAME, order: ASC}]) {
      ...Beach
    }
    clusteredLayerReports(layerId: $layerId, bounds: $bounds) {
      clusters {
        ...Cluster
      }
    }
  }
  ${BeachFragment}
  ${ClusterFragment}
`;
