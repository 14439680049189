import React from 'react';
import {View, Image, Platform, Text} from 'react-native';
import styled from 'styled-components/native';
import Checked from '../../assets/images/check-success.png';

const Container = styled(View)`
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  ${Platform.select({
    web: 'min-height: 300px',
  })}
  height: 100%;
`;

const Check = styled(Image)`
  width: 60px;
  height: 60px;
`;

export default function Success() {
  return (
    <Container>
      <Check source={Checked} />
      <Text>Contact message sent!</Text>
    </Container>
  );
}
