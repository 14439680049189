import React, {useEffect} from 'react';
import WelcomeBackground from '../../assets/images/mobile-welcome-background.png';
import MoteLogo from '../../assets/images/logos/mote-white-logo.png';
import AppStoreBadgeImage from '../../assets/images/app-store-badge.png';
import PlayStoreBadgeImage from '../../assets/images/google-play-badge.png';
import * as S from './styles';
import {
  ImageSourcePropType,
  TouchableOpacity,
  View,
  Image,
  Platform,
} from 'react-native';
import CancelIcon from '../../assets/images/cancel-icon.png';
import {useNavigation} from '@react-navigation/native';
import TextBox from '../../components/TextBox';
import colors from '../../constants/colors';
import styled from 'styled-components/native';
import Text from '../../components/Text';
import Storage from '../../storage';

const LogoContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled(Image)`
  width: 95px;
  height: 36px;
  margin-right: 10px;
`;

const Title = styled(View)`
  display: flex;
  flex-direction: column;
`;

const TitleText = styled(Text)`
  font-size: 20px;
  color: ${colors.app.white};
  text-transform: uppercase;
`;

const Header = styled(TextBox)`
  background: transparent;
  font-size: 24px;
  color: ${colors.mote.primary.blue}
  font-weight: bold;
  padding-bottom: 0;
`;

const PlainText = styled(TextBox)`
  background: transparent;
  padding: 10px;
  font-size: ${Platform.select({native: 18, web: 12})}px;
`;

const Badges = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AppStoreBadge = styled(Image)`
  width: 150px;
  height: 49px;
  padding: 12px;
  margin-right: 10px;
`;

const PlayStoreBadge = styled(Image)`
  width: 150px;
  height: 49px;
  border-radius: 10px;
`;

const Links = styled(View)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LinkButton = styled(TouchableOpacity)`
  padding: 20px;
`;

const LinkText = styled(Text)`
  text-decoration: underline;
  color: ${colors.mote.primary.blue};
  font-size: 20px;
`;

export default function WelcomeScreen() {
  const navigation = useNavigation();

  useEffect(() => {
    const checkWelcomeScreenStatus = async () => {
      const hasSeenWelcomeScreen = await Storage.hasSeenWelcomeScreen();

      if (hasSeenWelcomeScreen) {
        navigation.navigate('Root', {screen: 'Map'});
      }

      await Storage.saveHasSeenWelcomeScreen();
    };

    checkWelcomeScreenStatus();
  }, []);

  return (
    <>
      <S.Header>
        <S.Title>Welcome</S.Title>
        <TouchableOpacity
          onPress={_ => {
            navigation.navigate('Root', {screen: 'Map'});
          }}>
          <S.Cancel source={CancelIcon as ImageSourcePropType} />
        </TouchableOpacity>
      </S.Header>
      <S.Container>
        <S.Background source={WelcomeBackground as ImageSourcePropType}>
          <LogoContainer>
            <Logo source={MoteLogo as ImageSourcePropType} />
            <Title>
              <TitleText>Beach Conditions </TitleText>
              <TitleText>Reporting System</TitleText>
            </Title>
          </LogoContainer>
          <Header>Welcome</Header>
          <PlainText>
            This service offers beach conditions information as reported by trained volunteers, known as Beach Ambassadors. It aims to guide and inform the public about conditions at participating locations. Please note that conditions can change throughout the day, and these changes may not always be reflected in the reports.
          </PlainText>
          <PlainText>
            Many of our volunteers are lifeguards and beach/park personnel whose primary responsibility is swimmer safety. Therefore, if a report is slightly outdated, it may be because the volunteer for that location is attending to other priorities and has not yet updated the information.
          </PlainText>
          <View style={{flexGrow: 1}}></View>
          <Badges>
            {Platform.select({
              web: (
                <>
                  <TouchableOpacity
                    onPress={() => {
                      window.open(
                        'https://apps.apple.com/us/app/bcrs-mote-marine-laboratory/id1553295061',
                        '_blank',
                      );
                    }}>
                    <AppStoreBadge source={AppStoreBadgeImage} />
                  </TouchableOpacity>
                  <TouchableOpacity
                    onPress={() => {
                      window.open(
                        'https://play.google.com/store/apps/details?id=org.visitbeaches.bcrsv2&hl=en_AU',
                        '_blank',
                      );
                    }}>
                    <PlayStoreBadge source={PlayStoreBadgeImage} />
                  </TouchableOpacity>
                </>
              ),
              default: null,
            })}
          </Badges>
          <View style={{flexGrow: 1}}></View>
          <Links>
            <LinkButton
              onPress={() => {
                navigation.navigate('Root', {screen: 'Map'});
              }}>
              <LinkText>Map & Beaches</LinkText>
            </LinkButton>
            <LinkButton
              onPress={() => {
                navigation.navigate('Root', {screen: 'Alerts'});
              }}>
              <LinkText>Beach Alerts</LinkText>
            </LinkButton>
            <LinkButton
              onPress={() => {
                navigation.navigate('Root', {screen: 'Resources'});
              }}>
              <LinkText>Resources</LinkText>
            </LinkButton>
            <LinkButton
              onPress={() => {
                navigation.navigate('Root', {screen: 'Contact'});
              }}>
              <LinkText>Contact Mote</LinkText>
            </LinkButton>
          </Links>
          <View style={{flexGrow: 1}}></View>
        </S.Background>
      </S.Container>
    </>
  );
}
