import React from 'react';
import ContentModal from '../components/content-modal';
import PersonIcon from '../../assets/images/person.png';
import styled from 'styled-components/native';
import SignInUser from '../../components/sign-in-user';
import {useNavigation} from '@react-navigation/native';
import {View} from 'react-native';

const Screen = styled(ContentModal)`
  position: absolute;
  top: 30vh;
  left: 50vw;
  margin-left: -10vw;
`;

const Margin = styled(View)`
  padding: 20px 0;
`;

export default function SignInUserScreen() {
  const navigation = useNavigation();

  return (
    <Screen
      title="Sign In"
      icon={PersonIcon}
      onCancel={e => {
        e.preventDefault();
        navigation.navigate('Root', {screen: 'Map'});
      }}>
      <Margin>
        <SignInUser />
      </Margin>
    </Screen>
  );
}
