import React, {useContext, PropsWithChildren, useState, useEffect} from 'react';
import DesktopNotification from '../desktop/components/Notification';
import MobileNotification from '../mobile/components/Notification';
import * as Device from 'expo-device';

export type NotificationType = 'error' | 'info' | 'success';

export type Notification = {
  type: NotificationType;
  message: string;
  timeout?: number;
};

type SetNotification = (notification: Notification) => void;

const NotificationContext = React.createContext<{
  set: SetNotification;
}>({
  set: _ => {},
});

const NotificationProvider = ({children, ...props}: PropsWithChildren<{}>) => {
  const [state, setState] = useState<Notification | false>(false);
  const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
    false,
  );

  const set: SetNotification = value => {
    setState(value);
  };

  useEffect(() => {
    const getDeviceType = async () => {
      let result = await Device.getDeviceTypeAsync();
      setDeviceType(result);
    };

    getDeviceType();
  }, []);

  useEffect(() => {
    if (state) {
      let timeout = setTimeout(
        () => {
          setState(false);
        },
        state.timeout ? state.timeout * 1000 : 1500,
      );

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [state]);

  return (
    <NotificationContext.Provider value={{set}} {...props}>
      {children}
      {state && deviceType === Device.DeviceType.DESKTOP ? (
        <DesktopNotification notification={state} />
      ) : null}
      {state && deviceType !== Device.DeviceType.DESKTOP ? (
        <MobileNotification notification={state} />
      ) : null}
    </NotificationContext.Provider>
  );
};

const useNotification = () => useContext(NotificationContext);

export {NotificationProvider, useNotification};
