import {Region} from 'react-native-maps';
import {BoundsInput} from './graphql-types';
import {ReactNativeFile} from 'apollo-upload-client';
import * as mime from 'react-native-mime-types';

export const getQueryVariable = (
  url: string | undefined | null,
  variable: string,
): string | null => {
  if (!url) {
    return null;
  }

  const queryString = url.split('?');
  if (!queryString[1]) {
    return null;
  }

  const query = queryString[1];
  const vars = query.split('&');

  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) == variable) {
      return decodeURIComponent(pair[1]);
    }
  }

  return null;
};

export function convertRegionToBoundsInput(region: Region): BoundsInput {
  const result = {
    northEast: {
      latitude: region.latitude + region.latitudeDelta,
      longitude: region.longitude + region.longitudeDelta,
    },
    southWest: {
      latitude: region.latitude - region.latitudeDelta,
      longitude: region.longitude - region.longitudeDelta,
    },
  };
  return result;
}

export function convertBoundsInputToRegion(bounds: BoundsInput): Region {
  const latitudeDelta =
    bounds.northEast.latitude > bounds.southWest.latitude
      ? (bounds.northEast.latitude - bounds.southWest.latitude) / 2
      : (bounds.southWest.latitude - bounds.northEast.latitude) / 2;

  const longitudeDelta =
    bounds.northEast.longitude > bounds.southWest.longitude
      ? (bounds.northEast.longitude - bounds.southWest.longitude) / 2
      : bounds.southWest.longitude - bounds.northEast.longitude;

  const latitude = bounds.northEast.latitude - latitudeDelta;
  const longitude = bounds.northEast.longitude - longitudeDelta;

  return {
    longitude,
    latitude,
    latitudeDelta,
    longitudeDelta,
  };
}

export async function getBlobForWeb(dataURI: string): Promise<Blob> {
  const response = await fetch(dataURI);
  return await response.blob();
}

export function getFileForNative(filepath: string): ReactNativeFile {
  const name = filepath.split('/').pop();

  return new ReactNativeFile({
    uri: filepath,
    type: mime.lookup(filepath) || 'image',
    name,
  });
}
