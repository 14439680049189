export default {
  prefixes: [
    'https://visitbeaches.org',
    'https://dev.visitbeaches.org',
    'bcrs://',
  ],
  config: {
    screens: {
      Root: {
        screens: {
          Map: {
            screens: {
              MapScreen: '/map',
            },
          },
          Alerts: {
            screens: {
              AlertsSubscribeScreen: 'alerts',
              BeachAlertsScreen: 'alerts/manage',
              AlertsVerifyEmailScreen: 'alerts/:email',
            },
          },
          Resources: {
            screens: {
              ResourcesScreen: 'resources',
            },
          },
          Contact: {
            screens: {
              ContactScreen: 'contact',
            },
          },
          Education: {
            screens: {
              EducationScreen: 'education',
              FrequentlyAskedQuestions: {
                screens: {
                  FrequentlyAskedQuestionCategories: 'education/faq',
                  FrequentlyAskedQuestions: 'education/faq/:category',
                },
              },
              Infographics: 'education/infographics',
              Videos: 'education/videos',
              Video: 'education/video',
            },
          },
        },
      },
      Welcome: '/',
      Beach: {
        screens: {
          BeachScreen: 'beach/:beach',
          BeachReportScreen: 'beach/:beach/report/:report',
          BeachPhotosScreen: 'beach/:beach/photos',
          BeachAmenitiesScreen: 'beach/:beach/amenities',
        },
      },
	  Search: {
		screens: {
			SearchScreen: 'search',
		},
	  },
      CreateReportScreen: 'create-report/',
      ReportSuccessScreen: '/create-report/success',
      ReportPendingScreen: '/create-report/pending',
      ReportFailedScreen: '/create-report/failed',
      SelectParameterCategoryScreen: 'create-report/:layer',
      CSICWizardScreen: 'create-report/csic/:layer/:category/:parameter',
      BCRSWizardScreen: 'create-report/bcrs/:beach/:parameter',
      BCRSSelectPhotosScreen: 'create-report/bcrs/:beach/photos',
      BCRSReviewScreen: 'create-report/bcrs/:beach/review',
      SignInUserScreen: '/sign-in',
    },
  },
};
