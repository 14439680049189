import React from 'react';
import BeachStock from '../../assets/images/beach-stock.jpeg';
import * as S from './styles';
import {ImageSourcePropType, TouchableOpacity, Image} from 'react-native';
import Wizard from '../../components/bcrs/wizard';
import styled from 'styled-components/native';
import Text from '../../components/Text';
import BackwardIcon from '../../assets/images/arrow-back.png';
import {useNavigation} from '@react-navigation/native';

const Title = styled(S.Title)`
  text-align: right;
`;

const Button = styled(TouchableOpacity)`
  flex-grow: 1;
  flex: 1;
  flex-direction: row;
`;

const ButtonText = styled(Text)`
  padding: 14px 5px;
`;

const ButtonIcon = styled(Image)`
  width: 24px;
  height: 24px;
  margin: 8px 0 0 10px;
`;

export default function BCRSWizardScreen() {
  const navigation = useNavigation();

  return (
    <S.Container>
      <S.Background source={BeachStock as ImageSourcePropType}>
        <S.Header>
          <Button
            onPress={_ => {
              if (navigation.canGoBack()) {
                return navigation.goBack();
              }

              navigation.navigate('Root', {screen: 'Map'});
            }}>
            <ButtonIcon source={BackwardIcon} />
            <ButtonText>Back</ButtonText>
          </Button>
          <Title>BCRS</Title>
        </S.Header>
        <Wizard />
      </S.Background>
    </S.Container>
  );
}
