import gql from 'graphql-tag';
import {AlertFragment} from './alert';

export const UserFragment = gql`
  fragment User on User {
    id
    name
    email
    alert {
      ...Alert
    }
  }
  ${AlertFragment}
`;
