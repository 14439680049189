import React from 'react';
import {FontAwesome} from '@expo/vector-icons';
import Text from '../../../../Text';
import * as WebBrowser from 'expo-web-browser';
import {FrequentlyAskedQuestionAnswer} from '../../../../../graphql-types';
import {Platform, TouchableOpacity, View} from 'react-native';
import styled from 'styled-components/native';
import colors from '../../../../../constants/colors';

const Button = styled(TouchableOpacity)`
  font-size: 8px;
`;

const LinkView = styled(View)`
  padding: 4px 15px;
`;

const LinkText = styled(Text)`
  color: ${colors.mote.primary.blue};
`;

export function Link({answer}: {answer: FrequentlyAskedQuestionAnswer}) {
  const {value} = answer;

  if (!value.link || !value.text) {
    return null;
  }

  return (
    <Button
      onPress={() => {
        if (Platform.OS === 'web') {
          window.open(value.link ?? 'https://google.com', '_blank');
        } else {
          WebBrowser.openBrowserAsync(value.link ?? 'https://google.com');
        }
      }}>
      <LinkView>
        <LinkText>
          <FontAwesome size={12} name="link" />
          &nbsp;&nbsp;
          {value.text}
        </LinkText>
      </LinkView>
    </Button>
  );
}
