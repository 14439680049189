import gql from 'graphql-tag';
import {
  ParameterCategoryFragment,
  ParameterCategoryFirstParameterFragment,
} from './parameter';

export const LayerFragment = gql`
  fragment Layer on Layer {
    id
    name
    slug
    description
    short
    parameterCategories {
      ...ParameterCategory
      ...ParameterCategoryFirstParameter
    }
  }
  ${ParameterCategoryFragment}
  ${ParameterCategoryFirstParameterFragment}
`;
