import * as React from 'react';
import ContentModal from '../components/content-modal';
import EducationIcon from '../../assets/images/education-icon-white.png';
import Education from '../../components/education';
import styled from 'styled-components/native';
import config from './config';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
`;

export default function EducationScreen() {
  return (
    <Screen title="Education" icon={EducationIcon} scrollEnable={true}>
      <Education />
    </Screen>
  );
}
