import React from 'react';
import {View, TouchableOpacity, GestureResponderEvent} from 'react-native';
import styled from 'styled-components/native';
import colors from '../../../constants/colors';
import Text from '../../Text';

const Container = styled(TouchableOpacity)`
  background-color: rgba(255, 255, 255, 0.6);
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1px;
`;

const Title = styled(View)``;
const TitleText = styled(Text)`
  font-size: 18px;
`;

const Value = styled(View)``;
const ValueText = styled(Text)`
  color: ${colors.mote.secondary.sharkGray};
  text-align: right;
`;

type ListItemProps = {
  title: string;
  values: string[];
  onPress?: (e: GestureResponderEvent) => void;
};

export default function ListItem({title, values, onPress}: ListItemProps) {
  return (
    <Container onPress={onPress}>
      <Title>
        <TitleText>{title}</TitleText>
      </Title>
      <Value>
        {values.map((value, idx) => (
          <ValueText key={idx}>{value}</ValueText>
        ))}
      </Value>
    </Container>
  );
}
