import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A datetime and timezone string in ISO 8601 format `Y-m-dTH:i:sO`, e.g. `2020-04-20T13:53:12+02:00`. */
  DateTimeTz: any;
  /** A datetime string with format `Y-m-d H:i:s`, e.g. `2018-05-23 13:43:32`. */
  DateTime: any;
  /** Can be used as an argument to upload files using https://github.com/jaydenseric/graphql-multipart-request-spec */
  Upload: any;
  /** A date string with format `Y-m-d`, e.g. `2011-05-23`. */
  Date: any;
  /** A JSON scalar type. */
  JSON: any;
};

export type Query = {
  __typename?: 'Query';
  me: User;
  layers?: Maybe<Array<Layer>>;
  layer: Layer;
  beach?: Maybe<Beach>;
  beaches: Array<Beach>;
  searchBeaches: Array<Beach>;
  parameter: Parameter;
  parameters: Array<Parameter>;
  clusteredLayerReports: ClusteredReports;
  alert?: Maybe<Alert>;
  frequentlyAskedQuestions: Array<FrequentlyAskedQuestion>;
  frequentlyAskedQuestionCategories: Array<FrequentlyAskedQuestionCategory>;
  infographics: Array<Infographic>;
};


export type QueryLayersArgs = {
  orderBy?: Maybe<Array<QueryLayersOrderByOrderByClause>>;
  type?: Maybe<LayerType>;
};


export type QueryLayerArgs = {
  id: Scalars['ID'];
};


export type QueryBeachArgs = {
  id: Scalars['ID'];
};


export type QueryBeachesArgs = {
  orderBy?: Maybe<Array<QueryBeachesOrderByOrderByClause>>;
};


export type QuerySearchBeachesArgs = {
  search: Scalars['String'];
};


export type QueryParameterArgs = {
  id: Scalars['ID'];
};


export type QueryClusteredLayerReportsArgs = {
  layerId: Scalars['ID'];
  bounds: BoundsInput;
};


export type QueryAlertArgs = {
  uuid: Scalars['String'];
};


export type QueryFrequentlyAskedQuestionsArgs = {
  frequentlyAskedQuestionCategory: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  type: LayerType;
  beaches: Array<Maybe<Beach>>;
  alert?: Maybe<Alert>;
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};

export enum LayerType {
  Bcrs = 'BCRS',
  Csic = 'CSIC'
}

export type Beach = {
  __typename?: 'Beach';
  id: Scalars['ID'];
  name: Scalars['String'];
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  imageAttachments?: Maybe<Array<ImageAttachment>>;
  city: City;
  location: Scalars['String'];
  amenities: Array<Amenitie>;
  lastThreeDaysOfReports: Array<Report>;
  firstParameter: Parameter;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};


export type BeachImageAttachmentsArgs = {
  orderBy?: Maybe<Array<BeachImageAttachmentsOrderByOrderByClause>>;
};

/** Order by clause for the `orderBy` argument on the query `imageAttachments`. */
export type BeachImageAttachmentsOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: BeachImageAttachmentsOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for the `orderBy` argument on field `imageAttachments` on type `Beach`. */
export enum BeachImageAttachmentsOrderByColumn {
  CreatedAt = 'CREATED_AT'
}

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

export type ImageAttachment = {
  __typename?: 'ImageAttachment';
  originalUrl: Scalars['String'];
  originalHeight: Scalars['Int'];
  originalWidth: Scalars['Int'];
  originalType: Scalars['String'];
  previewUrl: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};


export type City = {
  __typename?: 'City';
  name: Scalars['String'];
  county: County;
  state: State;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};

export type County = {
  __typename?: 'County';
  name: Scalars['String'];
  state: State;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};

export type State = {
  __typename?: 'State';
  name: Scalars['String'];
  abbreviation: Scalars['String'];
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};

export type Amenitie = {
  __typename?: 'Amenitie';
  beach: Beach;
  description: Scalars['String'];
  link?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  user: User;
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  agree: Scalars['Int'];
  disagree: Scalars['Int'];
  createdAt: Scalars['DateTimeTz'];
  reportParameters: Array<ReportParameter>;
  beachReport: Array<BeachReportCategory>;
  reportableType: ReportableType;
  reportableId: Scalars['ID'];
};

export type ReportParameter = {
  __typename?: 'ReportParameter';
  id: Scalars['ID'];
  parameter: Parameter;
  value?: Maybe<Scalars['String']>;
  display: Scalars['String'];
  parameterValues: Array<ParameterValue>;
};

export type Parameter = {
  __typename?: 'Parameter';
  id: Scalars['ID'];
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  prompt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  layer: Layer;
  first: Scalars['Boolean'];
  parameterCategory: ParameterCategory;
  type: ParameterType;
  rangeMin?: Maybe<Scalars['Int']>;
  rangeMax?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
  parameterValues: Array<ParameterValue>;
  nextParameter?: Maybe<Parameter>;
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};

export type Layer = {
  __typename?: 'Layer';
  id: Scalars['ID'];
  name: Scalars['String'];
  short: Scalars['String'];
  slug: Scalars['String'];
  icon: Scalars['String'];
  description: Scalars['String'];
  type: LayerType;
  parameterCategories: Array<ParameterCategory>;
  firstParameter?: Maybe<Parameter>;
  parameters: Array<Parameter>;
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};


export type LayerParametersArgs = {
  api?: Maybe<Scalars['Boolean']>;
};

export type ParameterCategory = {
  __typename?: 'ParameterCategory';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  icon?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  order: Scalars['Int'];
  first: Parameter;
};

export enum ParameterType {
  Select = 'SELECT',
  Multi = 'MULTI',
  Range = 'RANGE',
  Value = 'VALUE',
  Image = 'IMAGE'
}

export type ParameterValue = {
  __typename?: 'ParameterValue';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  api: Scalars['Boolean'];
  imagePath?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  parameter: Parameter;
  category: ParameterCategory;
  value: Scalars['String'];
  nextParameterByValue?: Maybe<NextParameterByValue>;
  createdAt: Scalars['DateTimeTz'];
  updatedAt: Scalars['DateTimeTz'];
};

export type NextParameterByValue = {
  __typename?: 'NextParameterByValue';
  id: Scalars['ID'];
  parameter: Parameter;
  value: ParameterValue;
  nextParameter: Parameter;
};

export type BeachReportCategory = {
  __typename?: 'BeachReportCategory';
  parameterCategory: ParameterCategory;
  reportParameters: Array<ReportParameter>;
};

export enum ReportableType {
  Beach = 'BEACH',
  Layer = 'LAYER'
}

export type Alert = {
  __typename?: 'Alert';
  id: Scalars['ID'];
  uuid: Scalars['String'];
  email: Scalars['String'];
  verifiedAt?: Maybe<Scalars['DateTime']>;
  beaches: Array<Beach>;
};


/** Order by clause for the `orderBy` argument on the query `layers`. */
export type QueryLayersOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryLayersOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for the `orderBy` argument on field `layers` on type `Query`. */
export enum QueryLayersOrderByColumn {
  Name = 'NAME'
}

/** Order by clause for the `orderBy` argument on the query `beaches`. */
export type QueryBeachesOrderByOrderByClause = {
  /** The column that is used for ordering. */
  column: QueryBeachesOrderByColumn;
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Allowed column names for the `orderBy` argument on field `beaches` on type `Query`. */
export enum QueryBeachesOrderByColumn {
  Name = 'NAME'
}

export type BoundsInput = {
  southWest: CoordinatesInput;
  northEast: CoordinatesInput;
};

export type CoordinatesInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ClusteredReports = {
  __typename?: 'ClusteredReports';
  clusters?: Maybe<Array<Cluster>>;
};

export type Cluster = {
  __typename?: 'Cluster';
  /** Key is just a MD5 hash of `center.latitude, center.longitude` */
  key: Scalars['String'];
  center: Coordinate;
  reports?: Maybe<Array<Report>>;
};

export type Coordinate = {
  __typename?: 'Coordinate';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type FrequentlyAskedQuestion = {
  __typename?: 'FrequentlyAskedQuestion';
  id: Scalars['ID'];
  category: FrequentlyAskedQuestionCategory;
  question: Scalars['String'];
  answers: Array<FrequentlyAskedQuestionAnswer>;
};

export type FrequentlyAskedQuestionCategory = {
  __typename?: 'FrequentlyAskedQuestionCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  frequentlyAskedQuestions: Array<FrequentlyAskedQuestion>;
};

export type FrequentlyAskedQuestionAnswer = {
  __typename?: 'FrequentlyAskedQuestionAnswer';
  id: Scalars['ID'];
  type: FrequentlyAskedQuestionAnswerType;
  value: FrequentlyAskedQuestionAnswerValue;
};

export enum FrequentlyAskedQuestionAnswerType {
  Text = 'TEXT',
  Link = 'LINK',
  Bullet = 'BULLET'
}

export type FrequentlyAskedQuestionAnswerValue = {
  __typename?: 'FrequentlyAskedQuestionAnswerValue';
  text?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  bullet?: Maybe<Scalars['String']>;
};

export type Infographic = {
  __typename?: 'Infographic';
  id: Scalars['ID'];
  name: Scalars['String'];
  thumbnailUrl: Scalars['String'];
  url: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  googleSignIn: SignInResult;
  facebookSignIn: SignInResult;
  appleSignIn: SignInResult;
  saveImageAttachment: SaveImageAttachmentResult;
  uploadImageAttachmentForReport: UploadImageAttachmentResult;
  sendContactMessage?: Maybe<Result>;
  submitReport?: Maybe<SubmitReportResult>;
  agreeWithReport?: Maybe<ValidationResult>;
  disagreeWithReport?: Maybe<ValidationResult>;
  subscribeToAlerts: WithAlertResult;
  verifyEmail: WithAlertResult;
  subscribeToBeach: WithAlertResult;
  unsubscribeFromBeach: WithAlertResult;
  unsubscribeFromAlerts: Result;
};


export type MutationGoogleSignInArgs = {
  idToken: Scalars['String'];
  client?: Maybe<GoogleOAuthClient>;
};


export type MutationFacebookSignInArgs = {
  accessToken: Scalars['String'];
};


export type MutationAppleSignInArgs = {
  input: AppleSignInInput;
};


export type MutationUploadImageAttachmentForReportArgs = {
  reportId: Scalars['ID'];
  image: Scalars['Upload'];
};


export type MutationSendContactMessageArgs = {
  input?: Maybe<SendContactMessageInput>;
};


export type MutationSubmitReportArgs = {
  input: SubmitReportInput;
};


export type MutationAgreeWithReportArgs = {
  input: ValidateReportInput;
};


export type MutationDisagreeWithReportArgs = {
  input: ValidateReportInput;
};


export type MutationSubscribeToAlertsArgs = {
  input: SubscribeToAlertsInput;
};


export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};


export type MutationSubscribeToBeachArgs = {
  input: ManageSubscriptionInput;
};


export type MutationUnsubscribeFromBeachArgs = {
  input: ManageSubscriptionInput;
};


export type MutationUnsubscribeFromAlertsArgs = {
  uuid: Scalars['String'];
};

export enum GoogleOAuthClient {
  Android = 'ANDROID',
  Ios = 'IOS',
  Web = 'WEB'
}

export type SignInResult = {
  __typename?: 'SignInResult';
  success?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  accessToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export type AppleSignInInput = {
  identityToken: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type SaveImageAttachmentResult = {
  __typename?: 'SaveImageAttachmentResult';
  success: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
};


export type UploadImageAttachmentResult = {
  __typename?: 'UploadImageAttachmentResult';
  success?: Maybe<Scalars['Boolean']>;
  error?: Maybe<Error>;
};

export type Error = {
  __typename?: 'Error';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type SendContactMessageInput = {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type Result = {
  __typename?: 'Result';
  success: Scalars['Boolean'];
};

export type SubmitReportInput = {
  createdAt: Scalars['DateTimeTz'];
  reportable: ReportableInput;
  location: LocationInput;
  reportParameters: Array<ReportParametersInput>;
};

export type ReportableInput = {
  type?: Maybe<ReportableType>;
  id?: Maybe<Scalars['ID']>;
};

export type LocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type ReportParametersInput = {
  parameterId: Scalars['ID'];
  value?: Maybe<Scalars['String']>;
  reportParameterValues: Array<ReportParameterValueInput>;
};

export type ReportParameterValueInput = {
  parameterValueId?: Maybe<Scalars['ID']>;
  value?: Maybe<Scalars['String']>;
};

export type SubmitReportResult = {
  __typename?: 'SubmitReportResult';
  success: Scalars['Boolean'];
  report?: Maybe<Report>;
};

export type ValidateReportInput = {
  reportId: Scalars['ID'];
};

export type ValidationResult = {
  __typename?: 'ValidationResult';
  success?: Maybe<Scalars['Boolean']>;
  report?: Maybe<Report>;
};

export type SubscribeToAlertsInput = {
  email: Scalars['String'];
  newsletter: Scalars['Boolean'];
};

export type WithAlertResult = {
  __typename?: 'WithAlertResult';
  success: Scalars['Boolean'];
  alert?: Maybe<Alert>;
};

export type VerifyEmailInput = {
  email: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type ManageSubscriptionInput = {
  uuid: Scalars['String'];
  beachId: Scalars['ID'];
};



/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}

export type AlertFragment = (
  { __typename?: 'Alert' }
  & Pick<Alert, 'id' | 'uuid' | 'email'>
  & { beaches: Array<(
    { __typename?: 'Beach' }
    & BeachFragment
  )> }
);

export type StateFragment = (
  { __typename?: 'State' }
  & Pick<State, 'name' | 'abbreviation' | 'latitude' | 'longitude'>
);

export type CountyFragment = (
  { __typename?: 'County' }
  & Pick<County, 'name' | 'latitude' | 'longitude'>
  & { state: (
    { __typename?: 'State' }
    & StateFragment
  ) }
);

export type CityFragment = (
  { __typename?: 'City' }
  & Pick<City, 'name' | 'latitude' | 'longitude'>
  & { county: (
    { __typename?: 'County' }
    & CountyFragment
  ), state: (
    { __typename?: 'State' }
    & StateFragment
  ) }
);

export type BeachFragment = (
  { __typename?: 'Beach' }
  & Pick<Beach, 'id' | 'name' | 'website' | 'logo' | 'latitude' | 'longitude' | 'location'>
  & { amenities: Array<(
    { __typename?: 'Amenitie' }
    & Pick<Amenitie, 'description' | 'link'>
  )>, imageAttachments?: Maybe<Array<(
    { __typename?: 'ImageAttachment' }
    & ImageAttachmentFragment
  )>>, city: (
    { __typename?: 'City' }
    & CityFragment
  ) }
);

export type FirstParameterFragment = (
  { __typename?: 'Beach' }
  & { firstParameter: (
    { __typename?: 'Parameter' }
    & ParameterFragment
  ) }
);

export type ImageAttachmentFragment = (
  { __typename?: 'ImageAttachment' }
  & Pick<ImageAttachment, 'originalUrl' | 'previewUrl' | 'thumbnailUrl'>
);

export type LayerFragment = (
  { __typename?: 'Layer' }
  & Pick<Layer, 'id' | 'name' | 'slug' | 'description' | 'short'>
  & { parameterCategories: Array<(
    { __typename?: 'ParameterCategory' }
    & ParameterCategoryFragment
    & ParameterCategoryFirstParameterFragment
  )> }
);

export type ParameterValueFragment = (
  { __typename?: 'ParameterValue' }
  & Pick<ParameterValue, 'id' | 'name' | 'description' | 'value' | 'imagePath' | 'icon'>
);

export type NextParameterByValueFragment = (
  { __typename?: 'ParameterValue' }
  & { nextParameterByValue?: Maybe<(
    { __typename?: 'NextParameterByValue' }
    & { nextParameter: (
      { __typename?: 'Parameter' }
      & ParameterFragment
    ) }
  )> }
);

export type ParameterCategoryFragment = (
  { __typename?: 'ParameterCategory' }
  & Pick<ParameterCategory, 'id' | 'name' | 'icon' | 'description' | 'slug' | 'order'>
);

export type ParameterFragment = (
  { __typename?: 'Parameter' }
  & Pick<Parameter, 'id' | 'name' | 'icon' | 'prompt' | 'description' | 'type' | 'rangeMin' | 'rangeMax' | 'unit' | 'first'>
  & { parameterCategory: (
    { __typename?: 'ParameterCategory' }
    & ParameterCategoryFragment
  ), parameterValues: Array<(
    { __typename?: 'ParameterValue' }
    & ParameterValueFragment
  )> }
);

export type ParameterWithNextFragment = (
  { __typename?: 'Parameter' }
  & { parameterCategory: (
    { __typename?: 'ParameterCategory' }
    & ParameterCategoryFragment
  ), parameterValues: Array<(
    { __typename?: 'ParameterValue' }
    & ParameterValueFragment
    & NextParameterByValueFragment
  )>, nextParameter?: Maybe<(
    { __typename?: 'Parameter' }
    & ParameterFragment
  )> }
  & ParameterFragment
);

export type ParameterCategoryFirstParameterFragment = (
  { __typename?: 'ParameterCategory' }
  & { first: (
    { __typename?: 'Parameter' }
    & ParameterFragment
  ) }
);

export type ReportParameterFragment = (
  { __typename?: 'ReportParameter' }
  & Pick<ReportParameter, 'value' | 'display'>
  & { parameter: (
    { __typename?: 'Parameter' }
    & ParameterFragment
  ), parameterValues: Array<(
    { __typename?: 'ParameterValue' }
    & ParameterValueFragment
  )> }
);

export type ReportFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'agree' | 'disagree' | 'createdAt' | 'latitude' | 'longitude' | 'reportableType' | 'reportableId'>
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ), reportParameters: Array<(
    { __typename?: 'ReportParameter' }
    & ReportParameterFragment
  )> }
);

export type BeachReportFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'createdAt' | 'latitude' | 'longitude'>
  & { user: (
    { __typename?: 'User' }
    & UserFragment
  ), beachReport: Array<(
    { __typename?: 'BeachReportCategory' }
    & { parameterCategory: (
      { __typename?: 'ParameterCategory' }
      & ParameterCategoryFragment
    ), reportParameters: Array<(
      { __typename?: 'ReportParameter' }
      & Pick<ReportParameter, 'value'>
      & { parameter: (
        { __typename?: 'Parameter' }
        & ParameterFragment
      ), parameterValues: Array<(
        { __typename?: 'ParameterValue' }
        & ParameterValueFragment
      )> }
    )> }
  )> }
);

export type WithLastThreeReportsFragment = (
  { __typename?: 'Beach' }
  & { lastThreeDaysOfReports: Array<(
    { __typename?: 'Report' }
    & BeachReportFragment
  )> }
);

export type ClusterFragment = (
  { __typename?: 'Cluster' }
  & Pick<Cluster, 'key'>
  & { center: (
    { __typename?: 'Coordinate' }
    & Pick<Coordinate, 'latitude' | 'longitude'>
  ), reports?: Maybe<Array<(
    { __typename?: 'Report' }
    & ReportFragment
  )>> }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'name' | 'email'>
  & { alert?: Maybe<(
    { __typename?: 'Alert' }
    & AlertFragment
  )> }
);

export type SubscribeToAlertsMutationVariables = Exact<{
  input: SubscribeToAlertsInput;
}>;


export type SubscribeToAlertsMutation = (
  { __typename?: 'Mutation' }
  & { subscribeToAlerts: (
    { __typename?: 'WithAlertResult' }
    & Pick<WithAlertResult, 'success'>
    & { alert?: Maybe<(
      { __typename?: 'Alert' }
      & AlertFragment
    )> }
  ) }
);

export type VerifyEmailMutationVariables = Exact<{
  input: VerifyEmailInput;
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail: (
    { __typename?: 'WithAlertResult' }
    & Pick<WithAlertResult, 'success'>
    & { alert?: Maybe<(
      { __typename?: 'Alert' }
      & AlertFragment
    )> }
  ) }
);

export type SubscribeToBeachMutationVariables = Exact<{
  input: ManageSubscriptionInput;
}>;


export type SubscribeToBeachMutation = (
  { __typename?: 'Mutation' }
  & { subscribeToBeach: (
    { __typename?: 'WithAlertResult' }
    & Pick<WithAlertResult, 'success'>
    & { alert?: Maybe<(
      { __typename?: 'Alert' }
      & AlertFragment
    )> }
  ) }
);

export type UnsubscribeFromBeachMutationVariables = Exact<{
  input: ManageSubscriptionInput;
}>;


export type UnsubscribeFromBeachMutation = (
  { __typename?: 'Mutation' }
  & { unsubscribeFromBeach: (
    { __typename?: 'WithAlertResult' }
    & Pick<WithAlertResult, 'success'>
    & { alert?: Maybe<(
      { __typename?: 'Alert' }
      & AlertFragment
    )> }
  ) }
);

export type SendContactMessageMutationVariables = Exact<{
  input?: Maybe<SendContactMessageInput>;
}>;


export type SendContactMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendContactMessage?: Maybe<(
    { __typename?: 'Result' }
    & Pick<Result, 'success'>
  )> }
);

export type UploadImageAttachmentForReportMutationVariables = Exact<{
  reportId: Scalars['ID'];
  image: Scalars['Upload'];
}>;


export type UploadImageAttachmentForReportMutation = (
  { __typename?: 'Mutation' }
  & { uploadImageAttachmentForReport: (
    { __typename?: 'UploadImageAttachmentResult' }
    & Pick<UploadImageAttachmentResult, 'success'>
  ) }
);

export type SubmitReportMutationVariables = Exact<{
  input: SubmitReportInput;
}>;


export type SubmitReportMutation = (
  { __typename?: 'Mutation' }
  & { submitReport?: Maybe<(
    { __typename?: 'SubmitReportResult' }
    & Pick<SubmitReportResult, 'success'>
    & { report?: Maybe<(
      { __typename?: 'Report' }
      & ReportFragment
    )> }
  )> }
);

export type AgreeWithReportMutationVariables = Exact<{
  input: ValidateReportInput;
}>;


export type AgreeWithReportMutation = (
  { __typename?: 'Mutation' }
  & { agreeWithReport?: Maybe<(
    { __typename?: 'ValidationResult' }
    & Pick<ValidationResult, 'success'>
    & { report?: Maybe<(
      { __typename?: 'Report' }
      & ReportFragment
    )> }
  )> }
);

export type DisagreeWithReportMutationVariables = Exact<{
  input: ValidateReportInput;
}>;


export type DisagreeWithReportMutation = (
  { __typename?: 'Mutation' }
  & { disagreeWithReport?: Maybe<(
    { __typename?: 'ValidationResult' }
    & Pick<ValidationResult, 'success'>
    & { report?: Maybe<(
      { __typename?: 'Report' }
      & ReportFragment
    )> }
  )> }
);

export type GoogleSignInMutationVariables = Exact<{
  idToken: Scalars['String'];
  client: GoogleOAuthClient;
}>;


export type GoogleSignInMutation = (
  { __typename?: 'Mutation' }
  & { googleSignIn: (
    { __typename?: 'SignInResult' }
    & Pick<SignInResult, 'accessToken' | 'message'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type FacebookSignInMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type FacebookSignInMutation = (
  { __typename?: 'Mutation' }
  & { facebookSignIn: (
    { __typename?: 'SignInResult' }
    & Pick<SignInResult, 'accessToken' | 'message'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type AppleSignInMutationVariables = Exact<{
  input: AppleSignInInput;
}>;


export type AppleSignInMutation = (
  { __typename?: 'Mutation' }
  & { appleSignIn: (
    { __typename?: 'SignInResult' }
    & Pick<SignInResult, 'accessToken' | 'message'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'createdAt' | 'updatedAt'>
    )> }
  ) }
);

export type GetAlertQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetAlertQuery = (
  { __typename?: 'Query' }
  & { alert?: Maybe<(
    { __typename?: 'Alert' }
    & AlertFragment
  )> }
);

export type GetBeachesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBeachesQuery = (
  { __typename?: 'Query' }
  & { beaches: Array<(
    { __typename?: 'Beach' }
    & BeachFragment
  )> }
);

export type SearchBeachesQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type SearchBeachesQuery = (
  { __typename?: 'Query' }
  & { searchBeaches: Array<(
    { __typename?: 'Beach' }
    & BeachFragment
  )> }
);

export type GetBeachQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetBeachQuery = (
  { __typename?: 'Query' }
  & { beach?: Maybe<(
    { __typename?: 'Beach' }
    & BeachFragment
    & WithLastThreeReportsFragment
  )> }
);

export type GetFrequentlyAskedQuestionsCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFrequentlyAskedQuestionsCategoriesQuery = (
  { __typename?: 'Query' }
  & { frequentlyAskedQuestionCategories: Array<(
    { __typename?: 'FrequentlyAskedQuestionCategory' }
    & Pick<FrequentlyAskedQuestionCategory, 'id' | 'name' | 'description'>
  )> }
);

export type GetFrequentlyAskedQuestionsQueryVariables = Exact<{
  frequentlyAskedQuestionCategory: Scalars['ID'];
}>;


export type GetFrequentlyAskedQuestionsQuery = (
  { __typename?: 'Query' }
  & { frequentlyAskedQuestions: Array<(
    { __typename?: 'FrequentlyAskedQuestion' }
    & Pick<FrequentlyAskedQuestion, 'id' | 'question'>
    & { answers: Array<(
      { __typename?: 'FrequentlyAskedQuestionAnswer' }
      & Pick<FrequentlyAskedQuestionAnswer, 'id' | 'type'>
      & { value: (
        { __typename?: 'FrequentlyAskedQuestionAnswerValue' }
        & Pick<FrequentlyAskedQuestionAnswerValue, 'text' | 'link' | 'bullet'>
      ) }
    )> }
  )> }
);

export type GetInfographicsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInfographicsQuery = (
  { __typename?: 'Query' }
  & { infographics: Array<(
    { __typename?: 'Infographic' }
    & Pick<Infographic, 'id' | 'name' | 'thumbnailUrl' | 'url'>
  )> }
);

export type GetLayersQueryVariables = Exact<{
  type?: Maybe<LayerType>;
}>;


export type GetLayersQuery = (
  { __typename?: 'Query' }
  & { layers?: Maybe<Array<(
    { __typename?: 'Layer' }
    & LayerFragment
  )>> }
);

export type GetLayerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetLayerQuery = (
  { __typename?: 'Query' }
  & { layer: (
    { __typename?: 'Layer' }
    & LayerFragment
  ) }
);

export type GetParameterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetParameterQuery = (
  { __typename?: 'Query' }
  & { parameter: (
    { __typename?: 'Parameter' }
    & ParameterWithNextFragment
  ) }
);

export type AllParametersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllParametersQuery = (
  { __typename?: 'Query' }
  & { parameters: Array<(
    { __typename?: 'Parameter' }
    & ParameterWithNextFragment
  )> }
);

export type GetMappedReportsQueryVariables = Exact<{
  bounds: BoundsInput;
  layerId: Scalars['ID'];
}>;


export type GetMappedReportsQuery = (
  { __typename?: 'Query' }
  & { beaches: Array<(
    { __typename?: 'Beach' }
    & BeachFragment
  )>, clusteredLayerReports: (
    { __typename?: 'ClusteredReports' }
    & { clusters?: Maybe<Array<(
      { __typename?: 'Cluster' }
      & ClusterFragment
    )>> }
  ) }
);

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'User' }
    & { beaches: Array<Maybe<(
      { __typename?: 'Beach' }
      & BeachFragment
      & FirstParameterFragment
    )>> }
    & UserFragment
  ) }
);

export const ParameterCategoryFragmentDoc = gql`
    fragment ParameterCategory on ParameterCategory {
  id
  name
  icon
  description
  slug
  order
}
    `;
export const ParameterValueFragmentDoc = gql`
    fragment ParameterValue on ParameterValue {
  id
  name
  description
  value
  imagePath
  icon
}
    `;
export const ParameterFragmentDoc = gql`
    fragment Parameter on Parameter {
  id
  name
  icon
  prompt
  description
  type
  rangeMin
  rangeMax
  unit
  first
  parameterCategory {
    ...ParameterCategory
  }
  parameterValues {
    ...ParameterValue
  }
}
    ${ParameterCategoryFragmentDoc}
${ParameterValueFragmentDoc}`;
export const FirstParameterFragmentDoc = gql`
    fragment FirstParameter on Beach {
  firstParameter {
    ...Parameter
  }
}
    ${ParameterFragmentDoc}`;
export const ParameterCategoryFirstParameterFragmentDoc = gql`
    fragment ParameterCategoryFirstParameter on ParameterCategory {
  first {
    ...Parameter
  }
}
    ${ParameterFragmentDoc}`;
export const LayerFragmentDoc = gql`
    fragment Layer on Layer {
  id
  name
  slug
  description
  short
  parameterCategories {
    ...ParameterCategory
    ...ParameterCategoryFirstParameter
  }
}
    ${ParameterCategoryFragmentDoc}
${ParameterCategoryFirstParameterFragmentDoc}`;
export const NextParameterByValueFragmentDoc = gql`
    fragment NextParameterByValue on ParameterValue {
  nextParameterByValue {
    nextParameter {
      ...Parameter
    }
  }
}
    ${ParameterFragmentDoc}`;
export const ParameterWithNextFragmentDoc = gql`
    fragment ParameterWithNext on Parameter {
  ...Parameter
  parameterCategory {
    ...ParameterCategory
  }
  parameterValues {
    ...ParameterValue
    ...NextParameterByValue
  }
  nextParameter {
    ...Parameter
  }
}
    ${ParameterFragmentDoc}
${ParameterCategoryFragmentDoc}
${ParameterValueFragmentDoc}
${NextParameterByValueFragmentDoc}`;
export const ImageAttachmentFragmentDoc = gql`
    fragment ImageAttachment on ImageAttachment {
  originalUrl
  previewUrl
  thumbnailUrl
}
    `;
export const StateFragmentDoc = gql`
    fragment State on State {
  name
  abbreviation
  latitude
  longitude
}
    `;
export const CountyFragmentDoc = gql`
    fragment County on County {
  name
  latitude
  longitude
  state {
    ...State
  }
}
    ${StateFragmentDoc}`;
export const CityFragmentDoc = gql`
    fragment City on City {
  name
  latitude
  longitude
  county {
    ...County
  }
  state {
    ...State
  }
}
    ${CountyFragmentDoc}
${StateFragmentDoc}`;
export const BeachFragmentDoc = gql`
    fragment Beach on Beach {
  id
  name
  website
  logo
  latitude
  longitude
  amenities {
    description
    link
  }
  imageAttachments(orderBy: [{column: CREATED_AT, order: DESC}]) {
    ...ImageAttachment
  }
  city {
    ...City
  }
  location
}
    ${ImageAttachmentFragmentDoc}
${CityFragmentDoc}`;
export const AlertFragmentDoc = gql`
    fragment Alert on Alert {
  id
  uuid
  email
  beaches {
    ...Beach
  }
}
    ${BeachFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  alert {
    ...Alert
  }
}
    ${AlertFragmentDoc}`;
export const BeachReportFragmentDoc = gql`
    fragment BeachReport on Report {
  id
  user {
    ...User
  }
  createdAt
  latitude
  longitude
  beachReport {
    parameterCategory {
      ...ParameterCategory
    }
    reportParameters {
      parameter {
        ...Parameter
      }
      parameterValues {
        ...ParameterValue
      }
      value
    }
  }
}
    ${UserFragmentDoc}
${ParameterCategoryFragmentDoc}
${ParameterFragmentDoc}
${ParameterValueFragmentDoc}`;
export const WithLastThreeReportsFragmentDoc = gql`
    fragment WithLastThreeReports on Beach {
  lastThreeDaysOfReports {
    ...BeachReport
  }
}
    ${BeachReportFragmentDoc}`;
export const ReportParameterFragmentDoc = gql`
    fragment ReportParameter on ReportParameter {
  parameter {
    ...Parameter
  }
  parameterValues {
    ...ParameterValue
  }
  value
  display
}
    ${ParameterFragmentDoc}
${ParameterValueFragmentDoc}`;
export const ReportFragmentDoc = gql`
    fragment Report on Report {
  id
  user {
    ...User
  }
  agree
  disagree
  createdAt
  latitude
  longitude
  reportParameters {
    ...ReportParameter
  }
  reportableType
  reportableId
}
    ${UserFragmentDoc}
${ReportParameterFragmentDoc}`;
export const ClusterFragmentDoc = gql`
    fragment Cluster on Cluster {
  key
  center {
    latitude
    longitude
  }
  reports {
    ...Report
  }
}
    ${ReportFragmentDoc}`;
export const SubscribeToAlertsDocument = gql`
    mutation SubscribeToAlerts($input: SubscribeToAlertsInput!) {
  subscribeToAlerts(input: $input) {
    success
    alert {
      ...Alert
    }
  }
}
    ${AlertFragmentDoc}`;
export type SubscribeToAlertsMutationFn = Apollo.MutationFunction<SubscribeToAlertsMutation, SubscribeToAlertsMutationVariables>;

/**
 * __useSubscribeToAlertsMutation__
 *
 * To run a mutation, you first call `useSubscribeToAlertsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToAlertsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToAlertsMutation, { data, loading, error }] = useSubscribeToAlertsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeToAlertsMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToAlertsMutation, SubscribeToAlertsMutationVariables>) {
        return Apollo.useMutation<SubscribeToAlertsMutation, SubscribeToAlertsMutationVariables>(SubscribeToAlertsDocument, baseOptions);
      }
export type SubscribeToAlertsMutationHookResult = ReturnType<typeof useSubscribeToAlertsMutation>;
export type SubscribeToAlertsMutationResult = Apollo.MutationResult<SubscribeToAlertsMutation>;
export type SubscribeToAlertsMutationOptions = Apollo.BaseMutationOptions<SubscribeToAlertsMutation, SubscribeToAlertsMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($input: VerifyEmailInput!) {
  verifyEmail(input: $input) {
    success
    alert {
      ...Alert
    }
  }
}
    ${AlertFragmentDoc}`;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, baseOptions);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const SubscribeToBeachDocument = gql`
    mutation SubscribeToBeach($input: ManageSubscriptionInput!) {
  subscribeToBeach(input: $input) {
    success
    alert {
      ...Alert
    }
  }
}
    ${AlertFragmentDoc}`;
export type SubscribeToBeachMutationFn = Apollo.MutationFunction<SubscribeToBeachMutation, SubscribeToBeachMutationVariables>;

/**
 * __useSubscribeToBeachMutation__
 *
 * To run a mutation, you first call `useSubscribeToBeachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubscribeToBeachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [subscribeToBeachMutation, { data, loading, error }] = useSubscribeToBeachMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubscribeToBeachMutation(baseOptions?: Apollo.MutationHookOptions<SubscribeToBeachMutation, SubscribeToBeachMutationVariables>) {
        return Apollo.useMutation<SubscribeToBeachMutation, SubscribeToBeachMutationVariables>(SubscribeToBeachDocument, baseOptions);
      }
export type SubscribeToBeachMutationHookResult = ReturnType<typeof useSubscribeToBeachMutation>;
export type SubscribeToBeachMutationResult = Apollo.MutationResult<SubscribeToBeachMutation>;
export type SubscribeToBeachMutationOptions = Apollo.BaseMutationOptions<SubscribeToBeachMutation, SubscribeToBeachMutationVariables>;
export const UnsubscribeFromBeachDocument = gql`
    mutation UnsubscribeFromBeach($input: ManageSubscriptionInput!) {
  unsubscribeFromBeach(input: $input) {
    success
    alert {
      ...Alert
    }
  }
}
    ${AlertFragmentDoc}`;
export type UnsubscribeFromBeachMutationFn = Apollo.MutationFunction<UnsubscribeFromBeachMutation, UnsubscribeFromBeachMutationVariables>;

/**
 * __useUnsubscribeFromBeachMutation__
 *
 * To run a mutation, you first call `useUnsubscribeFromBeachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnsubscribeFromBeachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unsubscribeFromBeachMutation, { data, loading, error }] = useUnsubscribeFromBeachMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnsubscribeFromBeachMutation(baseOptions?: Apollo.MutationHookOptions<UnsubscribeFromBeachMutation, UnsubscribeFromBeachMutationVariables>) {
        return Apollo.useMutation<UnsubscribeFromBeachMutation, UnsubscribeFromBeachMutationVariables>(UnsubscribeFromBeachDocument, baseOptions);
      }
export type UnsubscribeFromBeachMutationHookResult = ReturnType<typeof useUnsubscribeFromBeachMutation>;
export type UnsubscribeFromBeachMutationResult = Apollo.MutationResult<UnsubscribeFromBeachMutation>;
export type UnsubscribeFromBeachMutationOptions = Apollo.BaseMutationOptions<UnsubscribeFromBeachMutation, UnsubscribeFromBeachMutationVariables>;
export const SendContactMessageDocument = gql`
    mutation SendContactMessage($input: SendContactMessageInput) {
  sendContactMessage(input: $input) {
    success
  }
}
    `;
export type SendContactMessageMutationFn = Apollo.MutationFunction<SendContactMessageMutation, SendContactMessageMutationVariables>;

/**
 * __useSendContactMessageMutation__
 *
 * To run a mutation, you first call `useSendContactMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendContactMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendContactMessageMutation, { data, loading, error }] = useSendContactMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendContactMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendContactMessageMutation, SendContactMessageMutationVariables>) {
        return Apollo.useMutation<SendContactMessageMutation, SendContactMessageMutationVariables>(SendContactMessageDocument, baseOptions);
      }
export type SendContactMessageMutationHookResult = ReturnType<typeof useSendContactMessageMutation>;
export type SendContactMessageMutationResult = Apollo.MutationResult<SendContactMessageMutation>;
export type SendContactMessageMutationOptions = Apollo.BaseMutationOptions<SendContactMessageMutation, SendContactMessageMutationVariables>;
export const UploadImageAttachmentForReportDocument = gql`
    mutation UploadImageAttachmentForReport($reportId: ID!, $image: Upload!) {
  uploadImageAttachmentForReport(reportId: $reportId, image: $image) {
    success
  }
}
    `;
export type UploadImageAttachmentForReportMutationFn = Apollo.MutationFunction<UploadImageAttachmentForReportMutation, UploadImageAttachmentForReportMutationVariables>;

/**
 * __useUploadImageAttachmentForReportMutation__
 *
 * To run a mutation, you first call `useUploadImageAttachmentForReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImageAttachmentForReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImageAttachmentForReportMutation, { data, loading, error }] = useUploadImageAttachmentForReportMutation({
 *   variables: {
 *      reportId: // value for 'reportId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadImageAttachmentForReportMutation(baseOptions?: Apollo.MutationHookOptions<UploadImageAttachmentForReportMutation, UploadImageAttachmentForReportMutationVariables>) {
        return Apollo.useMutation<UploadImageAttachmentForReportMutation, UploadImageAttachmentForReportMutationVariables>(UploadImageAttachmentForReportDocument, baseOptions);
      }
export type UploadImageAttachmentForReportMutationHookResult = ReturnType<typeof useUploadImageAttachmentForReportMutation>;
export type UploadImageAttachmentForReportMutationResult = Apollo.MutationResult<UploadImageAttachmentForReportMutation>;
export type UploadImageAttachmentForReportMutationOptions = Apollo.BaseMutationOptions<UploadImageAttachmentForReportMutation, UploadImageAttachmentForReportMutationVariables>;
export const SubmitReportDocument = gql`
    mutation SubmitReport($input: SubmitReportInput!) {
  submitReport(input: $input) {
    success
    report {
      ...Report
    }
  }
}
    ${ReportFragmentDoc}`;
export type SubmitReportMutationFn = Apollo.MutationFunction<SubmitReportMutation, SubmitReportMutationVariables>;

/**
 * __useSubmitReportMutation__
 *
 * To run a mutation, you first call `useSubmitReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitReportMutation, { data, loading, error }] = useSubmitReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitReportMutation(baseOptions?: Apollo.MutationHookOptions<SubmitReportMutation, SubmitReportMutationVariables>) {
        return Apollo.useMutation<SubmitReportMutation, SubmitReportMutationVariables>(SubmitReportDocument, baseOptions);
      }
export type SubmitReportMutationHookResult = ReturnType<typeof useSubmitReportMutation>;
export type SubmitReportMutationResult = Apollo.MutationResult<SubmitReportMutation>;
export type SubmitReportMutationOptions = Apollo.BaseMutationOptions<SubmitReportMutation, SubmitReportMutationVariables>;
export const AgreeWithReportDocument = gql`
    mutation AgreeWithReport($input: ValidateReportInput!) {
  agreeWithReport(input: $input) {
    success
    report {
      ...Report
    }
  }
}
    ${ReportFragmentDoc}`;
export type AgreeWithReportMutationFn = Apollo.MutationFunction<AgreeWithReportMutation, AgreeWithReportMutationVariables>;

/**
 * __useAgreeWithReportMutation__
 *
 * To run a mutation, you first call `useAgreeWithReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAgreeWithReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [agreeWithReportMutation, { data, loading, error }] = useAgreeWithReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAgreeWithReportMutation(baseOptions?: Apollo.MutationHookOptions<AgreeWithReportMutation, AgreeWithReportMutationVariables>) {
        return Apollo.useMutation<AgreeWithReportMutation, AgreeWithReportMutationVariables>(AgreeWithReportDocument, baseOptions);
      }
export type AgreeWithReportMutationHookResult = ReturnType<typeof useAgreeWithReportMutation>;
export type AgreeWithReportMutationResult = Apollo.MutationResult<AgreeWithReportMutation>;
export type AgreeWithReportMutationOptions = Apollo.BaseMutationOptions<AgreeWithReportMutation, AgreeWithReportMutationVariables>;
export const DisagreeWithReportDocument = gql`
    mutation DisagreeWithReport($input: ValidateReportInput!) {
  disagreeWithReport(input: $input) {
    success
    report {
      ...Report
    }
  }
}
    ${ReportFragmentDoc}`;
export type DisagreeWithReportMutationFn = Apollo.MutationFunction<DisagreeWithReportMutation, DisagreeWithReportMutationVariables>;

/**
 * __useDisagreeWithReportMutation__
 *
 * To run a mutation, you first call `useDisagreeWithReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisagreeWithReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disagreeWithReportMutation, { data, loading, error }] = useDisagreeWithReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDisagreeWithReportMutation(baseOptions?: Apollo.MutationHookOptions<DisagreeWithReportMutation, DisagreeWithReportMutationVariables>) {
        return Apollo.useMutation<DisagreeWithReportMutation, DisagreeWithReportMutationVariables>(DisagreeWithReportDocument, baseOptions);
      }
export type DisagreeWithReportMutationHookResult = ReturnType<typeof useDisagreeWithReportMutation>;
export type DisagreeWithReportMutationResult = Apollo.MutationResult<DisagreeWithReportMutation>;
export type DisagreeWithReportMutationOptions = Apollo.BaseMutationOptions<DisagreeWithReportMutation, DisagreeWithReportMutationVariables>;
export const GoogleSignInDocument = gql`
    mutation GoogleSignIn($idToken: String!, $client: GoogleOAuthClient!) {
  googleSignIn(idToken: $idToken, client: $client) {
    user {
      id
      name
      email
      createdAt
      updatedAt
    }
    accessToken
    message
  }
}
    `;
export type GoogleSignInMutationFn = Apollo.MutationFunction<GoogleSignInMutation, GoogleSignInMutationVariables>;

/**
 * __useGoogleSignInMutation__
 *
 * To run a mutation, you first call `useGoogleSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleSignInMutation, { data, loading, error }] = useGoogleSignInMutation({
 *   variables: {
 *      idToken: // value for 'idToken'
 *      client: // value for 'client'
 *   },
 * });
 */
export function useGoogleSignInMutation(baseOptions?: Apollo.MutationHookOptions<GoogleSignInMutation, GoogleSignInMutationVariables>) {
        return Apollo.useMutation<GoogleSignInMutation, GoogleSignInMutationVariables>(GoogleSignInDocument, baseOptions);
      }
export type GoogleSignInMutationHookResult = ReturnType<typeof useGoogleSignInMutation>;
export type GoogleSignInMutationResult = Apollo.MutationResult<GoogleSignInMutation>;
export type GoogleSignInMutationOptions = Apollo.BaseMutationOptions<GoogleSignInMutation, GoogleSignInMutationVariables>;
export const FacebookSignInDocument = gql`
    mutation FacebookSignIn($accessToken: String!) {
  facebookSignIn(accessToken: $accessToken) {
    user {
      id
      name
      email
      createdAt
      updatedAt
    }
    accessToken
    message
  }
}
    `;
export type FacebookSignInMutationFn = Apollo.MutationFunction<FacebookSignInMutation, FacebookSignInMutationVariables>;

/**
 * __useFacebookSignInMutation__
 *
 * To run a mutation, you first call `useFacebookSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFacebookSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [facebookSignInMutation, { data, loading, error }] = useFacebookSignInMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useFacebookSignInMutation(baseOptions?: Apollo.MutationHookOptions<FacebookSignInMutation, FacebookSignInMutationVariables>) {
        return Apollo.useMutation<FacebookSignInMutation, FacebookSignInMutationVariables>(FacebookSignInDocument, baseOptions);
      }
export type FacebookSignInMutationHookResult = ReturnType<typeof useFacebookSignInMutation>;
export type FacebookSignInMutationResult = Apollo.MutationResult<FacebookSignInMutation>;
export type FacebookSignInMutationOptions = Apollo.BaseMutationOptions<FacebookSignInMutation, FacebookSignInMutationVariables>;
export const AppleSignInDocument = gql`
    mutation AppleSignIn($input: AppleSignInInput!) {
  appleSignIn(input: $input) {
    user {
      id
      name
      email
      createdAt
      updatedAt
    }
    accessToken
    message
  }
}
    `;
export type AppleSignInMutationFn = Apollo.MutationFunction<AppleSignInMutation, AppleSignInMutationVariables>;

/**
 * __useAppleSignInMutation__
 *
 * To run a mutation, you first call `useAppleSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAppleSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [appleSignInMutation, { data, loading, error }] = useAppleSignInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAppleSignInMutation(baseOptions?: Apollo.MutationHookOptions<AppleSignInMutation, AppleSignInMutationVariables>) {
        return Apollo.useMutation<AppleSignInMutation, AppleSignInMutationVariables>(AppleSignInDocument, baseOptions);
      }
export type AppleSignInMutationHookResult = ReturnType<typeof useAppleSignInMutation>;
export type AppleSignInMutationResult = Apollo.MutationResult<AppleSignInMutation>;
export type AppleSignInMutationOptions = Apollo.BaseMutationOptions<AppleSignInMutation, AppleSignInMutationVariables>;
export const GetAlertDocument = gql`
    query GetAlert($uuid: String!) {
  alert(uuid: $uuid) {
    ...Alert
  }
}
    ${AlertFragmentDoc}`;

/**
 * __useGetAlertQuery__
 *
 * To run a query within a React component, call `useGetAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAlertQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetAlertQuery(baseOptions?: Apollo.QueryHookOptions<GetAlertQuery, GetAlertQueryVariables>) {
        return Apollo.useQuery<GetAlertQuery, GetAlertQueryVariables>(GetAlertDocument, baseOptions);
      }
export function useGetAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAlertQuery, GetAlertQueryVariables>) {
          return Apollo.useLazyQuery<GetAlertQuery, GetAlertQueryVariables>(GetAlertDocument, baseOptions);
        }
export type GetAlertQueryHookResult = ReturnType<typeof useGetAlertQuery>;
export type GetAlertLazyQueryHookResult = ReturnType<typeof useGetAlertLazyQuery>;
export type GetAlertQueryResult = Apollo.QueryResult<GetAlertQuery, GetAlertQueryVariables>;
export const GetBeachesDocument = gql`
    query GetBeaches {
  beaches(orderBy: [{column: NAME, order: ASC}]) {
    ...Beach
  }
}
    ${BeachFragmentDoc}`;

/**
 * __useGetBeachesQuery__
 *
 * To run a query within a React component, call `useGetBeachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeachesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBeachesQuery(baseOptions?: Apollo.QueryHookOptions<GetBeachesQuery, GetBeachesQueryVariables>) {
        return Apollo.useQuery<GetBeachesQuery, GetBeachesQueryVariables>(GetBeachesDocument, baseOptions);
      }
export function useGetBeachesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBeachesQuery, GetBeachesQueryVariables>) {
          return Apollo.useLazyQuery<GetBeachesQuery, GetBeachesQueryVariables>(GetBeachesDocument, baseOptions);
        }
export type GetBeachesQueryHookResult = ReturnType<typeof useGetBeachesQuery>;
export type GetBeachesLazyQueryHookResult = ReturnType<typeof useGetBeachesLazyQuery>;
export type GetBeachesQueryResult = Apollo.QueryResult<GetBeachesQuery, GetBeachesQueryVariables>;
export const SearchBeachesDocument = gql`
    query SearchBeaches($search: String!) {
  searchBeaches(search: $search) {
    ...Beach
  }
}
    ${BeachFragmentDoc}`;

/**
 * __useSearchBeachesQuery__
 *
 * To run a query within a React component, call `useSearchBeachesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchBeachesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchBeachesQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useSearchBeachesQuery(baseOptions?: Apollo.QueryHookOptions<SearchBeachesQuery, SearchBeachesQueryVariables>) {
        return Apollo.useQuery<SearchBeachesQuery, SearchBeachesQueryVariables>(SearchBeachesDocument, baseOptions);
      }
export function useSearchBeachesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchBeachesQuery, SearchBeachesQueryVariables>) {
          return Apollo.useLazyQuery<SearchBeachesQuery, SearchBeachesQueryVariables>(SearchBeachesDocument, baseOptions);
        }
export type SearchBeachesQueryHookResult = ReturnType<typeof useSearchBeachesQuery>;
export type SearchBeachesLazyQueryHookResult = ReturnType<typeof useSearchBeachesLazyQuery>;
export type SearchBeachesQueryResult = Apollo.QueryResult<SearchBeachesQuery, SearchBeachesQueryVariables>;
export const GetBeachDocument = gql`
    query GetBeach($id: ID!) {
  beach(id: $id) {
    ...Beach
    ...WithLastThreeReports
  }
}
    ${BeachFragmentDoc}
${WithLastThreeReportsFragmentDoc}`;

/**
 * __useGetBeachQuery__
 *
 * To run a query within a React component, call `useGetBeachQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBeachQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBeachQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBeachQuery(baseOptions?: Apollo.QueryHookOptions<GetBeachQuery, GetBeachQueryVariables>) {
        return Apollo.useQuery<GetBeachQuery, GetBeachQueryVariables>(GetBeachDocument, baseOptions);
      }
export function useGetBeachLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBeachQuery, GetBeachQueryVariables>) {
          return Apollo.useLazyQuery<GetBeachQuery, GetBeachQueryVariables>(GetBeachDocument, baseOptions);
        }
export type GetBeachQueryHookResult = ReturnType<typeof useGetBeachQuery>;
export type GetBeachLazyQueryHookResult = ReturnType<typeof useGetBeachLazyQuery>;
export type GetBeachQueryResult = Apollo.QueryResult<GetBeachQuery, GetBeachQueryVariables>;
export const GetFrequentlyAskedQuestionsCategoriesDocument = gql`
    query GetFrequentlyAskedQuestionsCategories {
  frequentlyAskedQuestionCategories {
    id
    name
    description
  }
}
    `;

/**
 * __useGetFrequentlyAskedQuestionsCategoriesQuery__
 *
 * To run a query within a React component, call `useGetFrequentlyAskedQuestionsCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrequentlyAskedQuestionsCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrequentlyAskedQuestionsCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFrequentlyAskedQuestionsCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetFrequentlyAskedQuestionsCategoriesQuery, GetFrequentlyAskedQuestionsCategoriesQueryVariables>) {
        return Apollo.useQuery<GetFrequentlyAskedQuestionsCategoriesQuery, GetFrequentlyAskedQuestionsCategoriesQueryVariables>(GetFrequentlyAskedQuestionsCategoriesDocument, baseOptions);
      }
export function useGetFrequentlyAskedQuestionsCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFrequentlyAskedQuestionsCategoriesQuery, GetFrequentlyAskedQuestionsCategoriesQueryVariables>) {
          return Apollo.useLazyQuery<GetFrequentlyAskedQuestionsCategoriesQuery, GetFrequentlyAskedQuestionsCategoriesQueryVariables>(GetFrequentlyAskedQuestionsCategoriesDocument, baseOptions);
        }
export type GetFrequentlyAskedQuestionsCategoriesQueryHookResult = ReturnType<typeof useGetFrequentlyAskedQuestionsCategoriesQuery>;
export type GetFrequentlyAskedQuestionsCategoriesLazyQueryHookResult = ReturnType<typeof useGetFrequentlyAskedQuestionsCategoriesLazyQuery>;
export type GetFrequentlyAskedQuestionsCategoriesQueryResult = Apollo.QueryResult<GetFrequentlyAskedQuestionsCategoriesQuery, GetFrequentlyAskedQuestionsCategoriesQueryVariables>;
export const GetFrequentlyAskedQuestionsDocument = gql`
    query GetFrequentlyAskedQuestions($frequentlyAskedQuestionCategory: ID!) {
  frequentlyAskedQuestions(
    frequentlyAskedQuestionCategory: $frequentlyAskedQuestionCategory
  ) {
    id
    question
    answers {
      id
      type
      value {
        text
        link
        bullet
      }
    }
  }
}
    `;

/**
 * __useGetFrequentlyAskedQuestionsQuery__
 *
 * To run a query within a React component, call `useGetFrequentlyAskedQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrequentlyAskedQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrequentlyAskedQuestionsQuery({
 *   variables: {
 *      frequentlyAskedQuestionCategory: // value for 'frequentlyAskedQuestionCategory'
 *   },
 * });
 */
export function useGetFrequentlyAskedQuestionsQuery(baseOptions?: Apollo.QueryHookOptions<GetFrequentlyAskedQuestionsQuery, GetFrequentlyAskedQuestionsQueryVariables>) {
        return Apollo.useQuery<GetFrequentlyAskedQuestionsQuery, GetFrequentlyAskedQuestionsQueryVariables>(GetFrequentlyAskedQuestionsDocument, baseOptions);
      }
export function useGetFrequentlyAskedQuestionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFrequentlyAskedQuestionsQuery, GetFrequentlyAskedQuestionsQueryVariables>) {
          return Apollo.useLazyQuery<GetFrequentlyAskedQuestionsQuery, GetFrequentlyAskedQuestionsQueryVariables>(GetFrequentlyAskedQuestionsDocument, baseOptions);
        }
export type GetFrequentlyAskedQuestionsQueryHookResult = ReturnType<typeof useGetFrequentlyAskedQuestionsQuery>;
export type GetFrequentlyAskedQuestionsLazyQueryHookResult = ReturnType<typeof useGetFrequentlyAskedQuestionsLazyQuery>;
export type GetFrequentlyAskedQuestionsQueryResult = Apollo.QueryResult<GetFrequentlyAskedQuestionsQuery, GetFrequentlyAskedQuestionsQueryVariables>;
export const GetInfographicsDocument = gql`
    query GetInfographics {
  infographics {
    id
    name
    thumbnailUrl
    url
  }
}
    `;

/**
 * __useGetInfographicsQuery__
 *
 * To run a query within a React component, call `useGetInfographicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInfographicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInfographicsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInfographicsQuery(baseOptions?: Apollo.QueryHookOptions<GetInfographicsQuery, GetInfographicsQueryVariables>) {
        return Apollo.useQuery<GetInfographicsQuery, GetInfographicsQueryVariables>(GetInfographicsDocument, baseOptions);
      }
export function useGetInfographicsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInfographicsQuery, GetInfographicsQueryVariables>) {
          return Apollo.useLazyQuery<GetInfographicsQuery, GetInfographicsQueryVariables>(GetInfographicsDocument, baseOptions);
        }
export type GetInfographicsQueryHookResult = ReturnType<typeof useGetInfographicsQuery>;
export type GetInfographicsLazyQueryHookResult = ReturnType<typeof useGetInfographicsLazyQuery>;
export type GetInfographicsQueryResult = Apollo.QueryResult<GetInfographicsQuery, GetInfographicsQueryVariables>;
export const GetLayersDocument = gql`
    query GetLayers($type: LayerType) {
  layers(type: $type) {
    ...Layer
  }
}
    ${LayerFragmentDoc}`;

/**
 * __useGetLayersQuery__
 *
 * To run a query within a React component, call `useGetLayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayersQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetLayersQuery(baseOptions?: Apollo.QueryHookOptions<GetLayersQuery, GetLayersQueryVariables>) {
        return Apollo.useQuery<GetLayersQuery, GetLayersQueryVariables>(GetLayersDocument, baseOptions);
      }
export function useGetLayersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLayersQuery, GetLayersQueryVariables>) {
          return Apollo.useLazyQuery<GetLayersQuery, GetLayersQueryVariables>(GetLayersDocument, baseOptions);
        }
export type GetLayersQueryHookResult = ReturnType<typeof useGetLayersQuery>;
export type GetLayersLazyQueryHookResult = ReturnType<typeof useGetLayersLazyQuery>;
export type GetLayersQueryResult = Apollo.QueryResult<GetLayersQuery, GetLayersQueryVariables>;
export const GetLayerDocument = gql`
    query GetLayer($id: ID!) {
  layer(id: $id) {
    ...Layer
  }
}
    ${LayerFragmentDoc}`;

/**
 * __useGetLayerQuery__
 *
 * To run a query within a React component, call `useGetLayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLayerQuery(baseOptions?: Apollo.QueryHookOptions<GetLayerQuery, GetLayerQueryVariables>) {
        return Apollo.useQuery<GetLayerQuery, GetLayerQueryVariables>(GetLayerDocument, baseOptions);
      }
export function useGetLayerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLayerQuery, GetLayerQueryVariables>) {
          return Apollo.useLazyQuery<GetLayerQuery, GetLayerQueryVariables>(GetLayerDocument, baseOptions);
        }
export type GetLayerQueryHookResult = ReturnType<typeof useGetLayerQuery>;
export type GetLayerLazyQueryHookResult = ReturnType<typeof useGetLayerLazyQuery>;
export type GetLayerQueryResult = Apollo.QueryResult<GetLayerQuery, GetLayerQueryVariables>;
export const GetParameterDocument = gql`
    query GetParameter($id: ID!) {
  parameter(id: $id) {
    ...ParameterWithNext
  }
}
    ${ParameterWithNextFragmentDoc}`;

/**
 * __useGetParameterQuery__
 *
 * To run a query within a React component, call `useGetParameterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParameterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParameterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParameterQuery(baseOptions?: Apollo.QueryHookOptions<GetParameterQuery, GetParameterQueryVariables>) {
        return Apollo.useQuery<GetParameterQuery, GetParameterQueryVariables>(GetParameterDocument, baseOptions);
      }
export function useGetParameterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParameterQuery, GetParameterQueryVariables>) {
          return Apollo.useLazyQuery<GetParameterQuery, GetParameterQueryVariables>(GetParameterDocument, baseOptions);
        }
export type GetParameterQueryHookResult = ReturnType<typeof useGetParameterQuery>;
export type GetParameterLazyQueryHookResult = ReturnType<typeof useGetParameterLazyQuery>;
export type GetParameterQueryResult = Apollo.QueryResult<GetParameterQuery, GetParameterQueryVariables>;
export const AllParametersDocument = gql`
    query AllParameters {
  parameters {
    ...ParameterWithNext
  }
}
    ${ParameterWithNextFragmentDoc}`;

/**
 * __useAllParametersQuery__
 *
 * To run a query within a React component, call `useAllParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllParametersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllParametersQuery(baseOptions?: Apollo.QueryHookOptions<AllParametersQuery, AllParametersQueryVariables>) {
        return Apollo.useQuery<AllParametersQuery, AllParametersQueryVariables>(AllParametersDocument, baseOptions);
      }
export function useAllParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllParametersQuery, AllParametersQueryVariables>) {
          return Apollo.useLazyQuery<AllParametersQuery, AllParametersQueryVariables>(AllParametersDocument, baseOptions);
        }
export type AllParametersQueryHookResult = ReturnType<typeof useAllParametersQuery>;
export type AllParametersLazyQueryHookResult = ReturnType<typeof useAllParametersLazyQuery>;
export type AllParametersQueryResult = Apollo.QueryResult<AllParametersQuery, AllParametersQueryVariables>;
export const GetMappedReportsDocument = gql`
    query GetMappedReports($bounds: BoundsInput!, $layerId: ID!) {
  beaches(orderBy: [{column: NAME, order: ASC}]) {
    ...Beach
  }
  clusteredLayerReports(layerId: $layerId, bounds: $bounds) {
    clusters {
      ...Cluster
    }
  }
}
    ${BeachFragmentDoc}
${ClusterFragmentDoc}`;

/**
 * __useGetMappedReportsQuery__
 *
 * To run a query within a React component, call `useGetMappedReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMappedReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMappedReportsQuery({
 *   variables: {
 *      bounds: // value for 'bounds'
 *      layerId: // value for 'layerId'
 *   },
 * });
 */
export function useGetMappedReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetMappedReportsQuery, GetMappedReportsQueryVariables>) {
        return Apollo.useQuery<GetMappedReportsQuery, GetMappedReportsQueryVariables>(GetMappedReportsDocument, baseOptions);
      }
export function useGetMappedReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMappedReportsQuery, GetMappedReportsQueryVariables>) {
          return Apollo.useLazyQuery<GetMappedReportsQuery, GetMappedReportsQueryVariables>(GetMappedReportsDocument, baseOptions);
        }
export type GetMappedReportsQueryHookResult = ReturnType<typeof useGetMappedReportsQuery>;
export type GetMappedReportsLazyQueryHookResult = ReturnType<typeof useGetMappedReportsLazyQuery>;
export type GetMappedReportsQueryResult = Apollo.QueryResult<GetMappedReportsQuery, GetMappedReportsQueryVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    ...User
    beaches {
      ...Beach
      ...FirstParameter
    }
  }
}
    ${UserFragmentDoc}
${BeachFragmentDoc}
${FirstParameterFragmentDoc}`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;