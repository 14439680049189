import React from 'react';
import RootNavigation from './navigation/RootNavigation';
import DesktopNavigation from './navigation/DesktopNavigation';

export default function Desktop() {
  return (
    <>
      <RootNavigation navigator={DesktopNavigation} />
    </>
  );
}
