import gql from 'graphql-tag';
import {BeachFragment, WithLastThreeReportsFragment} from '../fragments';

export const getBeachesQuery = gql`
  query GetBeaches {
    beaches(orderBy: [{column: NAME, order: ASC}]) {
      ...Beach
    }
  }
  ${BeachFragment}
`;

export const searchBeachesQuery = gql`
  query SearchBeaches($search: String!) {
    searchBeaches(search: $search) {
      ...Beach
    }
  }
  ${BeachFragment}
`;

export const getBeachQuery = gql`
  query GetBeach($id: ID!) {
    beach(id: $id) {
      ...Beach
      ...WithLastThreeReports
    }
  }
  ${BeachFragment}
  ${WithLastThreeReportsFragment}
`;
