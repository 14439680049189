import React, { useEffect } from 'react';
import { useGetLayerQuery } from '../../../graphql-types';
import { useError } from '../../../hooks/useErrorContext';
import { useRoute, RouteProp } from '@react-navigation/native';
import { RootStackParamList } from '../../../types';
import ParameterCategoryList from './ParameterCategoryList';
import { useLoading } from '../../../hooks/useLoadingContext';

export type SelectParameterCategoryRouteProp = RouteProp<
  RootStackParamList,
  'SelectParameterCategoryScreen'
>;

export default function SelectParameterCategory() {
  const {
    params: { layer },
  } = useRoute<SelectParameterCategoryRouteProp>();
  const { data, loading, error } = useGetLayerQuery({
    variables: { id: layer },
  });
  const { set: setError } = useError();
  const { set: setLoading, clear: clearLoading } = useLoading();

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error]);

  useEffect(() => {
    if (loading) {
      return setLoading();
    }
    clearLoading();
  }, [loading]);

  if (loading) {
    return null;
  }

  if (!data?.layer?.parameterCategories) {
    return null;
  }

  return (
    <ParameterCategoryList
      parameterCategories={data.layer.parameterCategories}
    />
  );
}
