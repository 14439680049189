import * as React from 'react';
import {
  useNavigationBuilder,
  DefaultNavigatorOptions,
  StackRouter,
  StackRouterOptions,
  StackNavigationState,
  createNavigatorFactory,
  StackActionHelpers,
} from '@react-navigation/native';

// Props accepted by the view
type ModalNavigationConfig = {};

// Supported screen options
type ModalNavigationOptions = {
  title?: string;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type ModalNavigationEventMap = {};

// The props accepted by the component is a combination of 3 things
type Props = DefaultNavigatorOptions<ModalNavigationOptions> &
  StackRouterOptions &
  ModalNavigationConfig;

type ParamListBase = {};

function ModalNavigator({children, screenOptions, initialRouteName}: Props) {
  const {state, descriptors} = useNavigationBuilder<
    StackNavigationState<ParamListBase>,
    StackRouterOptions,
    StackActionHelpers<ParamListBase>,
    ModalNavigationOptions,
    ModalNavigationEventMap
  >(StackRouter, {
    children,
    screenOptions,
    initialRouteName,
  });

  return <>{descriptors[state.routes[state.index].key].render()}</>;
}

export default createNavigatorFactory<
  StackNavigationState<ParamListBase>,
  ModalNavigationOptions,
  ModalNavigationEventMap,
  typeof ModalNavigator
>(ModalNavigator);
