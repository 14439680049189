import React from 'react';

import TabNavigation from './navigation/TabNavigation';
import RootNavigation from './navigation/RootNavigation';
import {StatusBar, Platform} from 'react-native';
import colors from '../constants/colors';

export default function Mobile() {
  return (
    <>
      <StatusBar
        barStyle={'light-content'}
        backgroundColor={colors.tabBar.inactiveBackgroundColor}
        hidden={Platform.OS == 'ios'}
      />
      <RootNavigation navigator={TabNavigation} />
    </>
  );
}
