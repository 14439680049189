import React from 'react';
import styled from 'styled-components/native';
import {ActivityIndicator, ImageBackground} from 'react-native';
import BeachStock from '../assets/images/beach-stock.jpeg';
import colors from '../constants/colors';

const Container = styled(ImageBackground)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default function WebLoading() {
  return (
    <Container source={BeachStock}>
      <ActivityIndicator animating={true} size="large" color={colors.app.black} />
    </Container>
  );
}
