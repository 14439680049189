import React from 'react';
import styled from 'styled-components/native';
import TextBox from '../TextBox';
import colors from '../../constants/colors';

const StyledTextBox = styled(TextBox)`
  padding: 5px 15px;
  color: ${colors.app.error};
  font-size: 12px;
`;

export default function FormError({children}: React.PropsWithChildren<{}>) {
  return <StyledTextBox>{children}</StyledTextBox>;
}
