import React, {useEffect} from 'react';
import {View, Platform} from 'react-native';
import styled from 'styled-components/native';
import _TextBox from '../TextBox';
import {useNavigation} from '@react-navigation/native';
import GoogleSignIn from './GoogleSignIn';
import AppleSignIn from './AppleSignIn';
import FacebookSignIn from './FacebookSignIn';
import * as WebBrowser from 'expo-web-browser';

if (Platform.OS == 'web') {
  WebBrowser.maybeCompleteAuthSession({});
}

const Container = styled(View)`
  align-items: center;
  display: flex;
  flex-grow: 1;
`;

const Buttons = styled(View)`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items-center;
`;

export default function SignInUser() {
  const navigation = useNavigation();

  useEffect(() => {
    const goToMap = (e: any) => {
      e.preventDefault();
      navigation.navigate('MapScreen');
    };

    navigation.addListener('beforeRemove', goToMap);

    return () => {
      navigation.removeListener('beforeRemove', goToMap);
    };
  }, []);

  return (
    <Container>
      <Buttons>
        {Platform.select({
          ios: <AppleSignIn />,
          default: null,
        })}
        <GoogleSignIn />
        <FacebookSignIn />
      </Buttons>
    </Container>
  );
}
