import React from 'react';
import YouTube from 'react-youtube';

export default function YouTubeVideo({id}: {id: string}) {
  return (
    <YouTube
      videoId={id} // defaults -> null
    />
  );
}
