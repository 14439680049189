import React from 'react';
import {
  ParameterValueFragment,
  NextParameterByValueFragment,
} from '../../../graphql-types';
import {TouchableOpacity, Image} from 'react-native';
import styled from 'styled-components/native';
import Text from '../../Text';

type ValueProps = {
  parameterValue: ParameterValueFragment & NextParameterByValueFragment;
  onPress: () => void;
};

const Touchable = styled(TouchableOpacity)`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ImageValue = styled(Image)`
  width: 100%;
  height: 200px;
`;

const Help = styled(Text)`
  font-size: 12px;
`;

export default function Value({parameterValue, onPress}: ValueProps) {
  if (!parameterValue.imagePath) {
    return null;
  }

  return (
    <Touchable onPress={onPress}>
      <Help>Tap to select</Help>
      <ImageValue source={{uri: parameterValue.imagePath}} />
    </Touchable>
  );
}
