import React from 'react';
import styled from 'styled-components/native';
import config from './config';
import ContentModal from '../components/content-modal';
import Photos from '../../components/beach/photos';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
  display: flex;
  flex-direction: column;
`;

export default function BeachPhotosScreen() {
  return (
    <Screen title={'Pictures'}>
      <Photos />
    </Screen>
  );
}
