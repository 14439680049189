import React, {useState} from 'react';
import Text from '../../Text';
import {ReportParameter} from '../../../graphql-types';
import styled from 'styled-components/native';
import {View, Image} from 'react-native';
import {TouchableOpacity} from 'react-native';
import HelpIcon from '../../../assets/images/help-icon.png';
import {IParameterInfo} from '../../../mobile/components/ParameterInfo';

const Container = styled(TouchableOpacity)`
  width: ${({single}: {single?: boolean}) => (single ? '100%' : '50%')}
  margin-bottom: 10px;
  position: relative;
`;

const ParameterTitle = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const ParameterName = styled(Text)`
  font-weight: bold;
  padding-bottom: 5px;
`;

const ParameterValueName = styled(View)``;
const ParameterValueNameText = styled(Text)`
  font-size: 12px;
`;
const ParameterValueDescription = styled(View)`
  padding-bottom: 5px;
`;
const ParameterValueDescriptionText = styled(Text)`
  font-size: 10px;
`;
const ParameterValueIcon = styled(Image)`
  width: 30px;
  height: 30px;
`;

const ParameterValueValue = styled(View)`
  flex: 1;
`;

const ParameterValueContainer = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InfoIcon = styled(Image)`
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-bottom: 5px;
`;

type BeachReportParameterProps = {
  reportParameter: ReportParameter;
  single?: boolean;
  setParameterInfo: (value: IParameterInfo) => void;
};

export default function BeachReportParameter({
  reportParameter,
  single,
  setParameterInfo,
}: BeachReportParameterProps) {
  return (
    <Container
      onPress={_ =>
        setParameterInfo({
          name: reportParameter.parameter.name,
          description: reportParameter.parameter.description,
          values: reportParameter.parameter.parameterValues.map(value => {
            return {
              name: value.name,
              description: value.description,
            };
          }),
        })
      }
      single={single}>
      <ParameterTitle>
        <ParameterName>{reportParameter.parameter.name}</ParameterName>
        <InfoIcon source={HelpIcon} />
      </ParameterTitle>
      {reportParameter.value ? (
        <Text>
          {reportParameter.value}
          {reportParameter.parameter.unit}
        </Text>
      ) : (
        reportParameter.parameterValues.map((parameterValue, index) => (
          <ParameterValueContainer key={index}>
            {parameterValue.icon ? (
              <ParameterValueIcon source={{uri: parameterValue.icon}} />
            ) : null}
            <ParameterValueValue>
              <ParameterValueName>
                <ParameterValueNameText>
                  {parameterValue.name}
                </ParameterValueNameText>
              </ParameterValueName>
              {reportParameter.parameter.name === 'Flag' ? (
                <ParameterValueDescription>
                  <ParameterValueDescriptionText>
                    {parameterValue.description}
                  </ParameterValueDescriptionText>
                </ParameterValueDescription>
              ) : null}
            </ParameterValueValue>
          </ParameterValueContainer>
        ))
      )}
    </Container>
  );
}
