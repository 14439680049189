import React from 'react';
import {
  useSubscribeToBeachMutation,
  useUnsubscribeFromBeachMutation,
} from '../graphql-types';
import styled from 'styled-components/native';
import {ImageBackground, Image, View, TouchableOpacity} from 'react-native';
import BeachStock from '../assets/images/beach-stock.jpeg';
import MailIcon from '../assets/images/mail-icon-black.png';
import CheckedIcon from '../assets/images/checkbox-checked.png';
import UncheckedIcon from '../assets/images/checkbox-unchecked.png';
import Text from './Text';
import colors from '../constants/colors';
import {useNavigation} from '@react-navigation/native';
import {useLoading} from '../hooks/useLoadingContext';
import {useAlertSubscription} from '../hooks/useAlertSubscriptionContext';
import {useNotification} from '../hooks/useNotificationContext';

const Container = styled(ImageBackground)``;

const ContainerOverlay = styled(TouchableOpacity)`
  background-color: rgba(255, 255, 255, 0.7);
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  flex: 1;
  resize-mode: cover;
  flex-grow: 1;
`;

const Icon = styled(Image)`
  width: 24px;
  height: 24px;
`;

const Content = styled(Text)`
  flex-grow: 1;
  padding: 5px 5px 5px 5px;
`;

const SignUpText = styled(Text)`
  color: ${colors.mote.primary.blue};
  text-decoration: underline;
  font-weight: bold;
`;

type SubscriptionProps = {
  beachId: string;
};

export default function Subscription({beachId}: SubscriptionProps) {
  const {subscription, set: setSubscription} = useAlertSubscription();
  const navigation = useNavigation();
  const [subscribeToBeach] = useSubscribeToBeachMutation();
  const [unsubscribeFromBeach] = useUnsubscribeFromBeachMutation();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {set: setNotificaiton} = useNotification();

  const subscribedToBeach =
    subscription && subscription.beaches.find(beach => beach.id === beachId);

  if (!subscription) {
    return (
      <Container source={BeachStock}>
        <ContainerOverlay onPress={() => navigation.navigate('Alerts')}>
          <Icon source={MailIcon} />
          <Content>
            <SignUpText>Sign Up </SignUpText>to receive reports in your inbox.{' '}
          </Content>
        </ContainerOverlay>
      </Container>
    );
  }

  const handleToggle = async () => {
    setLoading();
    if (!subscribedToBeach) {
      const {data} = await subscribeToBeach({
        variables: {
          input: {
            uuid: subscription.uuid,
            beachId,
          },
        },
      });

      if (data?.subscribeToBeach.success && data.subscribeToBeach.alert) {
        setSubscription(data.subscribeToBeach.alert);
        setNotificaiton({
          type: 'success',
          message: 'Subscribed to beach alert.',
        });
      }
    } else {
      const {data} = await unsubscribeFromBeach({
        variables: {
          input: {
            uuid: subscription.uuid,
            beachId,
          },
        },
      });

      if (
        data?.unsubscribeFromBeach.success &&
        data.unsubscribeFromBeach.alert
      ) {
        setSubscription(data.unsubscribeFromBeach.alert);
        setNotificaiton({
          type: 'success',
          message: 'Unsubscribed from beach alert.',
        });
      }
    }
    clearLoading();
  };

  return (
    <Container source={BeachStock}>
      <ContainerOverlay
        onPress={() => {
          handleToggle();
        }}>
        <Icon source={MailIcon} />
        <Content>Receive Email Reports for this Beach.</Content>
        <Icon source={subscribedToBeach ? CheckedIcon : UncheckedIcon} />
      </ContainerOverlay>
    </Container>
  );
}
