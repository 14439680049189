import React from 'react';
import {MarkerProps} from './Marker';
import styled from 'styled-components';

const Icon = styled.img`
  width: 25px;
`;

const Container = styled.a`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  cursor: ${props => (props.onClick ? 'pointer' : 'default')};
`;

const Callout = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -125%);
`;

export default function Marker({
  title,
  onPress,
  icon,
  callout,
  showCallout,
}: MarkerProps) {
  return (
    <Container onClick={onPress} title={title}>
      {callout && showCallout ? <Callout>{callout}</Callout> : null}
      <Icon src={icon as string} />
    </Container>
  );
}
