import React, {useState, useEffect} from 'react';
import ForwardIcon from '../../assets/images/forward-icon.png';
import TextBox from '../../components/TextBox';
import {Field} from '../shared/styles';
import {Image, ImageSourcePropType, TouchableOpacity, View} from 'react-native';
import styled from 'styled-components/native';
import ListItemButton from '../ListItemButton';
import {useSubscribeToAlertsMutation} from '../../graphql-types';
import {useNavigation} from '@react-navigation/native';
import {useAlertSubscription} from '../../hooks/useAlertSubscriptionContext';
import {useLoading} from '../../hooks/useLoadingContext';
import Text from '../Text';
import CheckedIcon from '../../assets/images/checkbox-checked.png';
import UncheckedIcon from '../../assets/images/checkbox-unchecked.png';
import colors from '../../constants/colors';

const Container = styled(View)`
  background-color: ${colors.mote.primary.lightBlue};
`;

const MoteSubscribe = styled(TouchableOpacity)`
  background-color: ${colors.mote.primary.lightBlue};
  display: flex;
  flex-direction: row;
  padding: 15px;
`;

const MoteSubscribeLabel = styled(Text)`
  padding: 5px 10px;
`;

const Error = styled(TextBox)`
  color: red;
  font-size: 12px;
`;

const Checkbox = styled(Image)`
  width: 24px;
  height: 24px;
`;

export default function AlertsSubscribe() {
  const navigation = useNavigation();
  const [subscribeToAlerts] = useSubscribeToAlertsMutation();
  const [email, setEmail] = useState<string>('');
  const [moteNewsletter, setMoteNewsletter] = useState(false);
  const {set: setSubcription} = useAlertSubscription();
  const [error, setError] = useState<string | null>(null);
  const {set: setLoading, clear: clearLoading} = useLoading();

  const clearState = () => {
    setError(null);
    setEmail('');
  };

  useEffect(() => {
    navigation.addListener('blur', _ => clearState());

    return () => {
      navigation.removeListener('blur', _ => clearState());
    };
  }, []);

  const submit = async () => {
    setLoading();
    setError(null);
    if (!email.length) {
      clearLoading();
      return setError('Please include a valid email.');
    }

    try {
      const {data} = await subscribeToAlerts({
        variables: {
          input: {
            email,
            newsletter: moteNewsletter,
          },
        },
      });

      if (data?.subscribeToAlerts.success) {
        if (data.subscribeToAlerts.alert) {
          setSubcription(data.subscribeToAlerts.alert);
          clearLoading();
          return navigation.navigate('BeachAlertsScreen');
        }

        clearLoading();
        navigation.navigate('AlertsVerifyEmailScreen', {
          email,
        });
      }
    } catch (e) {
      clearLoading();
      setError('Please include a valid email.');
    }
  };

  return (
    <Container>
      <TextBox>
        Instead of checking reports each day, you can subscribe to beach
        condition reports and have them sent straight to your inbox.
      </TextBox>
      <TextBox bold={true}>Enter your email to begin.</TextBox>
      <Field
        placeholder="user@domain.com"
        value={email}
        onChangeText={text => setEmail(text)}
      />
      {error ? <Error>{error}</Error> : null}
      <MoteSubscribe onPress={() => setMoteNewsletter(!moteNewsletter)}>
        <Checkbox
          source={
            (moteNewsletter
              ? CheckedIcon
              : UncheckedIcon) as ImageSourcePropType
          }
        />
        <MoteSubscribeLabel>
          Sign me up for Mote's twice monthly e-newsletter!
        </MoteSubscribeLabel>
      </MoteSubscribe>
      <ListItemButton
        title="Subscribe to Beach Alerts"
        icon={ForwardIcon}
        onPress={submit}
      />
      <View style={{flexGrow: 1}} />
    </Container>
  );
}
