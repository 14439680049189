import React from 'react';
import NativeDialog, {DialogProps} from './index.shared';
import styled from 'styled-components/native';

const StyledDialog = styled(NativeDialog)<{isDesktop?: boolean}>`
  ${({isDesktop}) => {
    if (isDesktop) {
      return `
        width: 300px;
        padding-bottom: 12px;
      `;
    }
    return `
      position: absolute;
      top: 100px;
      left: 10px;
      right: 10px;
      z-index: 1000;
    `;
  }}
`;

export default function Dialog(props: DialogProps) {
  return <StyledDialog isDesktop={props.isDesktop} {...props} />;
}
