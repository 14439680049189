import gql from 'graphql-tag';

export const googleSignInMutation = gql`
  mutation GoogleSignIn($idToken: String!, $client: GoogleOAuthClient!) {
    googleSignIn(idToken: $idToken, client: $client) {
      user {
        id
        name
        email
        createdAt
        updatedAt
      }
      accessToken
      message
    }
  }
`;

export const facebookSignInMutation = gql`
  mutation FacebookSignIn($accessToken: String!) {
    facebookSignIn(accessToken: $accessToken) {
      user {
        id
        name
        email
        createdAt
        updatedAt
      }
      accessToken
      message
    }
  }
`;

export const appleSignInMutation = gql`
  mutation AppleSignIn($input: AppleSignInInput!) {
    appleSignIn(input: $input) {
      user {
        id
        name
        email
        createdAt
        updatedAt
      }
      accessToken
      message
    }
  }
`;
