import React, {useEffect} from 'react';
import styled from 'styled-components/native';
import {View} from 'react-native';
import {useGetBeachQuery, BeachFragment} from '../../graphql-types';
import {useRoute, RouteProp, useNavigation} from '@react-navigation/native';
import {BeachStackParamList} from '../../types';
import {useLoading} from '../../hooks/useLoadingContext';
import Tabs from './Tabs';
import BeachHeader from './BeachHeader';
import {useMapLocation} from '../../hooks/useMapLocationContext';

const BeachContainer = styled(View)``;

export type BeachScreenRouteProps = RouteProp<
  BeachStackParamList,
  'BeachScreen'
>;

type BeachProps = {};

const hasPhotos = (beach: BeachFragment) => {
  if (!beach.imageAttachments) {
    return false;
  }

  return beach.imageAttachments.length > 0;
};

export default function Beach({}: BeachProps) {
  const navigation = useNavigation();
  const {set: setMapLocation} = useMapLocation();
  const {params} = useRoute<BeachScreenRouteProps>();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading} = useGetBeachQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: params.beach,
    },
  });

  useEffect(() => {
    if (loading) {
      setLoading();
    } else {
      clearLoading();
    }
  }, [loading]);

  useEffect(() => {
    if (!data || !data.beach || !data.beach.latitude || !data.beach.longitude) {
      return;
    }
    setMapLocation({
      latitude: beach.latitude,
      longitude: beach.longitude,
    });
  }, [data]);

  useEffect(() => {
    if (data && data.beach) {
     navigation.setOptions({title: data.beach.name});
    }
  }, [data]);

  if (!data) {
    return null;
  }

  if (!data.beach) {
    // @todo failed to get beach
    return null;
  }

  const beach = data.beach;
  const reports = data.beach.lastThreeDaysOfReports;

  return (
    <BeachContainer>
      <BeachHeader name={data.beach.name} location={data.beach.location} />
      <Tabs
        beachId={params.beach}
        reportId={reports && reports[0] && reports[0].id}
        hasPhotos={hasPhotos(data.beach)}
        hasAmenities={data.beach.amenities.length > 0}
      />
    </BeachContainer>
  );
}
