import React from 'react';
import {View} from 'react-native';
import Text from '../components/Text';
import styled from 'styled-components/native';
import colors from '../constants/colors';


const Container = styled(View)`
  padding: 8px;
  flex-direction: column;
  display: flex;
  margin-bottom: 1px;
  justify-content: center;
  align-items: center;
  background: ${colors.mote.primary.lightBlue};
`;

const TitleText = styled(Text)`
  font-size: 18px;
`;

const SubText = styled(Text)`
  font-size: 14px;
`;

const LinkText = styled(Text)`
	font-size: 18px;
`;

export default function Hotline() {
  return (
    <Container>
      <TitleText>BCRS Information Hotline</TitleText>
      <SubText>To hear the latest beach conditions reports, call:</SubText>
      <LinkText><a href="tel:9412322437">941-Beaches</a> (941-232-2437)</LinkText>
    </Container>
  );
}
