import * as React from 'react';
import ContentModal from '../components/content-modal';
import CreateReportIcon from '../../assets/images/create-report-icon.png';
import styled from 'styled-components/native';
import CreateBCRSReport from '../../components/bcrs/create-report';
import CreateCSICReport from '../../components/csic/create-report';
import {useAuthenticatedUser} from '../../hooks/useAuthenticatedUserContext';
import {LayerType} from '../../graphql-types';
import config from './config';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
  z-index: 100000;
`;

export default function CreateReportScreen() {
  const {user} = useAuthenticatedUser();

  if (!user) {
    // at this point, user should be already authenticated
    return null;
  }

  return (
    <Screen title="Create Report" icon={CreateReportIcon}>
      {user.type === LayerType.Csic ? (
        <CreateCSICReport />
      ) : (
        <CreateBCRSReport />
      )}
    </Screen>
  );
}
