import React, {useEffect, useState} from 'react';
import {BeachStackParamList} from '../../../types';
import {useRoute, RouteProp, useNavigation} from '@react-navigation/native';
import {useLoading} from '../../../hooks/useLoadingContext';
import {useGetBeachQuery} from '../../../graphql-types';
import DateTabs from './DateTabs';
import styled from 'styled-components/native';
import {View, ScrollView, Dimensions} from 'react-native';
import BeachHeader from '../BeachHeader';
import dateFormat from 'dateformat';
import colors from '../../../constants/colors';
import constants from '../../../constants/constants';
import Text from '../../Text';
import BeachReportCategory from './BeachReportCategory';
import {IParameterInfo} from '../../../mobile/components/ParameterInfo';
import * as Device from 'expo-device';
import AlertsSubscribeScreen from '../../../desktop/screens/AlertsSubscribeScreen';

export type BeachReportScreenRouteProps = RouteProp<
  BeachStackParamList,
  'BeachReportScreen'
>;

const Container = styled(View)<{isDesktop?: boolean}>`
  ${({isDesktop}) => {
    if (isDesktop) {
      return `
 		 	display: flex;
  			flex-grow: 1;
			max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
  		`;
    }

    return `
  			display: flex;
  			flex-grow: 1;
  			max-height: ${Dimensions.get('window').height}
  	`;
  }}
`;

const ContainerScroll = styled(ScrollView)``;

const WhenReportHappened = styled(View)`
  background-color: ${colors.mote.primary.powderBlue};
  padding: 10px;
  display: flex;
  align-items: center;
`;

const WhenReportHappenedText = styled(Text)`
  color: ${colors.app.white};
  font-size: 11px;
`;

const BeachMessage = styled(View)`
  background-color: #ff5758;
  padding: 20px;
  display: flex;
  align-items: center;
`;

const BeachMessageText = styled(Text)`
  color: black;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
`;

type BeachReportProps = {
  setParameterInfo: (value: IParameterInfo) => void;
};

/* Takes a report date, returns true if that date is three days old or older.
	Slightly convoluted since I have to compare year/month/day to make sure I
	don't get false positives on last successive months or years.
	Get yesterday by subtracting date, for zero this will roll back to last month. */
function threeDaysOld(d) {
  const reportDate = dateFormat(d, 'yyyymmdd');
  const today = new Date();
  const todayDate = dateFormat(today, 'yyyymmdd');
  const yesterdayDate = dateFormat(
    new Date().setDate(today.getDate() - 1),
    'yyyymmdd',
  );
  const isToday = reportDate === todayDate;
  const isYesterday = reportDate === yesterdayDate;
  return !isToday && !isYesterday;
}

export default function BeachReport({setParameterInfo}: BeachReportProps) {
  const navigation = useNavigation();
  const {params} = useRoute<BeachReportScreenRouteProps>();
  const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
    false,
  );

  const [beachAlert, setBeachAlert] = useState<string | undefined>(undefined);

  const getBeachAlertMessageInline = async (id: number) => {
    const location_alert_messages_url = `https://datafetch.visitbeaches.org/content/blam.json?beach_id=${id}`;
    let result = await fetch(location_alert_messages_url);

    // Check if the response is okay
    if (!result.ok) {
      throw new Error(`Error fetching data: ${result.statusText}`);
    }

    const result_json = await result.json();
    const alert = result_json?.rows?.[0]?.alert;

    if (alert) {
      setBeachAlert(alert);
    }

    // if (result) {
    //   let result_json = await result.json();
    //   if (result_json) {
    //     const al_tmp = result_json.rows;
    //     if (al_tmp.length > 0) {
    //       const alert = result_json.rows[0].alert;
    //       setBeachAlert(alert);
    //     }
    //   }
    // }
  };

  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading} = useGetBeachQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id: params.beach,
    },
  });

  useEffect(() => {
    if (loading) {
      return setLoading();
    }

    clearLoading();
  }, [loading]);

  useEffect(() => {
    if (data && data.beach) {
      navigation.setOptions({title: data.beach.name});
      getBeachAlertMessageInline(parseInt(data.beach.id));
    }
  }, [data]);

  useEffect(() => {
    const getDeviceType = async () => {
      const type = await Device.getDeviceTypeAsync();
      setDeviceType(type);
    };

    getDeviceType();
  });

  if (!data) {
    return null;
  }

  if (!data.beach) {
    // @todo failed to get beach
    return null;
  }

  if (!data.beach.lastThreeDaysOfReports) {
    // @todo no reports? shouldn't have got this far
    return null;
  }

  const report = data.beach.lastThreeDaysOfReports.find(
    (report) => report.id === params.report,
  );

  return (
    <Container isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      <BeachHeader name={data.beach.name} location={data.beach.location} />

      {beachAlert != undefined && (
        <BeachMessage>
          <BeachMessageText>{beachAlert || ''}</BeachMessageText>
        </BeachMessage>
      )}

      <DateTabs
        reports={data.beach.lastThreeDaysOfReports}
        reportId={params.report}
      />
      <WhenReportHappened>
        <WhenReportHappenedText>
          {threeDaysOld(report?.createdAt)
            ? dateFormat(
                report?.createdAt,
                '"Report made on " dddd, mmmm d, yyyy "at" h:MM TT',
              )
            : dateFormat(
                report?.createdAt,
                '"Report made" DDDD, mmmm d, yyyy "at" h:MM TT',
              )}
        </WhenReportHappenedText>
      </WhenReportHappened>
      <ContainerScroll>
        {report?.beachReport.map((beachReportCategory, idx) => {
          return (
            <BeachReportCategory
              setParameterInfo={setParameterInfo}
              key={idx}
              beachReportCategory={beachReportCategory}
              index={idx}
            />
          );
        })}
      </ContainerScroll>
    </Container>
  );
}
