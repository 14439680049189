import AsyncStorage from '@react-native-async-storage/async-storage';
import {UserFragment} from '../graphql-types';

const setUser = async (user: UserFragment) => {
  await AsyncStorage.setItem('@user', JSON.stringify(user));
};

const clearUser = async () => {
  await AsyncStorage.clear();
};

const getUser = async () => {
  let user = await AsyncStorage.getItem('@user');

  if (user) {
    return JSON.parse(user);
  }

  return undefined;
};

export {setUser, clearUser, getUser};
