import React from 'react';
import BeachStock from '../../assets/images/beach-stock.jpeg';
import * as S from './styles';
import {ImageSourcePropType, TouchableOpacity, Image} from 'react-native';
import styled from 'styled-components/native';
import Text from '../../components/Text';
import BackwardIcon from '../../assets/images/arrow-back.png';
import {useNavigation} from '@react-navigation/native';
import Amenities from '../../components/beach/amenities';

const Button = styled(TouchableOpacity)`
  flex-grow: 1;
  flex: 1;
  flex-direction: row;
`;

const ButtonText = styled(Text)`
  padding: 14px 5px;
`;

const ButtonIcon = styled(Image)`
  width: 24px;
  height: 24px;
  margin: 8px 0 0 10px;
`;

export default function BeachAmenitiesScreen() {
  const navigation = useNavigation();

  return (
    <>
      <S.Header>
       <S.Title>Beach Amenities</S.Title>
        <Button
          onPress={_ => {
            if (navigation.canGoBack()) {
              return navigation.goBack();
            }

            navigation.navigate('Root', {screen: 'Map'});
          }}>
          <ButtonIcon source={BackwardIcon} />
          <ButtonText>Back</ButtonText>
        </Button>
      </S.Header>
      <S.Container>
        <S.Background source={BeachStock as ImageSourcePropType}>
          <Amenities />
        </S.Background>
      </S.Container>
    </>
  );
}
