import React from 'react';
import {View} from 'react-native';
import styled from 'styled-components/native';
import Text from '../../components/Text';
import colors from '../../constants/colors';
import {
  Notification as INotification,
  NotificationType,
} from '../../hooks/useNotificationContext';

const Box = styled(View)`
  position: absolute;
  top: 90px;
  left: 50%;
  z-index: 10000;
  width: 300px;
`;

const Container = styled(View)`
  position: relative;
  left: -50%;
  background-color: #fff;
  border-width: 2px;
  border-style: solid;
  border-color: ${({type}: {type: NotificationType}) => {
    switch (type) {
      case 'info':
        return colors.mote.secondary.manateeGray;
      case 'error':
        return colors.app.error;
      case 'success':
        return colors.mote.primary.blue;
    }
  }};
  width: 300px;
  padding: 10px 15px 5px 15px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);	// App.black color with 30% alpha
  text-align: center;
`;

const Message = styled(Text)`
  color: #000;
`;

export default function Notification({
  notification,
}: {
  notification: INotification;
}) {
  return (
    <Box>
      <Container type={notification.type}>
        <Message>{notification.message}</Message>
      </Container>
    </Box>
  );
}
