import React, {useEffect, useState} from 'react';
import styled from 'styled-components/native';
import {View, ScrollView, Dimensions} from 'react-native';
import ListItemButton from '../../../ListItemButton';
import ArrowForward from '../../../../assets/images/forward-arrow.png';
import {useLoading} from '../../../../hooks/useLoadingContext';
import {useGetFrequentlyAskedQuestionsQuery} from '../../../../graphql-types';
import {EducationStackParamList} from '../../../../types';
import {RouteProp, useRoute} from '@react-navigation/native';
import Question from './Question';
import Text from '../../../Text';
import constants from '../../../../constants/constants';
import * as Device from 'expo-device';
import colors from '../../../../constants/colors';

const Container = styled(ScrollView)`
  ${({isDesktop}) => {
    if (isDesktop) {
  		return `
  			display: flex;
  			flex-grow: 1;
			max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
			background-color: ${colors.app.white};
  			`;
  	}
  	
  	return `
  			display: flex;
  			flex-grow: 1;
  			background: ${colors.app.white};
  	`;
  	}}
`;

export type FrequentlyAskedQuestionsRouteProps = RouteProp<
  EducationStackParamList,
  'FrequentlyAskedQuestions'
>;

export default function FrequentlyAskedQuestions() {
	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);

	useEffect(() => {
		const getDeviceType = async () => {
			const type = await Device.getDeviceTypeAsync();
			setDeviceType(type);
		};

		getDeviceType();
	});

  const {set: setLoading, clear: clearLoading} = useLoading();
  const {params} = useRoute<FrequentlyAskedQuestionsRouteProps>();
  const {data, loading} = useGetFrequentlyAskedQuestionsQuery({
    variables: {
      frequentlyAskedQuestionCategory: params.category,
    },
  });

  useEffect(() => {
    if (loading) {
      return setLoading();
    }

    clearLoading();
  }, [loading]);

  if (!data || !data.frequentlyAskedQuestions) {
    return null;
  }

  return (
    <Container isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      {data.frequentlyAskedQuestions.map(question => (
        <Question question={question} key={question.id} />
      ))}
    </Container>
  );
}
