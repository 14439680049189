import AsyncStorage from '@react-native-async-storage/async-storage';

const setToken = async (token: string) => {
  await AsyncStorage.setItem('@token', token);
};

const getToken = async () => {
  let token = await AsyncStorage.getItem('@token');
  return token;
};

const clearToken = async () => {
  await AsyncStorage.removeItem('@token');
};

export {setToken, clearToken, getToken};
