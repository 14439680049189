import React from 'react';
import Text from '../../Text';
import styled from 'styled-components/native';
import {View, Image} from 'react-native';
import {BeachReportCategory as BeachReportCategoryType} from '../../../graphql-types';
import BeachReportParameter from './BeachReportParameter';
import colors from '../../../constants/colors';
import {IParameterInfo} from '../../../mobile/components/ParameterInfo';

const Container = styled(View)`
  padding: 10px;
  background-color: ${({zebra}: {zebra: boolean}) =>
    zebra ? colors.app.zebraGray : colors.app.white};
`;

const CategoryName = styled(View)`
  padding-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CategoryNameText = styled(Text)`
  color: ${colors.mote.primary.blue}
  font-weight: bold
`;

const ReportParametersContainer = styled(View)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

type BeachReportParametersCategoryProps = {
  setParameterInfo: (value: IParameterInfo) => void;
  beachReportCategory: BeachReportCategoryType;
  index: number;
};

export default function BeachReportCategory({
  beachReportCategory,
  index,
  setParameterInfo,
}: BeachReportParametersCategoryProps) {
  return (
    <Container zebra={!!(index % 2)}>
      {beachReportCategory.reportParameters.length > 1 && (
        <CategoryName>
          <CategoryNameText>
            {beachReportCategory.parameterCategory.name}
          </CategoryNameText>
        </CategoryName>
      )}
      <ReportParametersContainer>
        {beachReportCategory.reportParameters.map((reportParameter, index) => {
          return (
            <BeachReportParameter
              setParameterInfo={setParameterInfo}
              key={index}
              reportParameter={reportParameter}
              single={beachReportCategory.reportParameters.length === 1}
            />
          );
        })}
      </ReportParametersContainer>
    </Container>
  );
}
