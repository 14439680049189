import React, {useState, useEffect} from 'react';
import {RootStackParamList} from '../../../types';
import {RouteProp, useRoute, useNavigation} from '@react-navigation/native';
import ReportParameter, {
  NextParameter,
  StoreReportParameterValue,
} from '../../report-parameter';
import {Report} from '../../../storage/report';
import {StackActions} from '@react-navigation/native';
import {useReport} from '../../../hooks/useReportContext';
import {useLocation} from '../../../hooks/useLocationContext';
import {ReportableType} from '../../../graphql-types';

export type BCRSWizardScreenRouteProps = RouteProp<
  RootStackParamList,
  'BCRSWizardScreen'
>;

export default function Wizard() {
  const {store: storeReport, get: getReport} = useReport();
  const {params} = useRoute<BCRSWizardScreenRouteProps>();
  const navigation = useNavigation();
  const {location} = useLocation();

  const [report, setReport] = useState<Report>({
    photos: [],
    createdAt: new Date(),
    completed: false,
    reportable: {
      type: ReportableType.Beach,
      id: params.beach,
    },
    location: {
      latitude: location ? location.latitude : 0,
      longitude: location ? location.longitude : 0,
    },
    reportParameters: [],
  });

  const reportKey = ['bcrs', params.beach, 'current'];

  useEffect(() => {
    let isSubscribed = true;

    const fetchReport = async () => {
      let report = await getReport(reportKey);
      if (isSubscribed) {
        if (report && !report.completed) {
          setReport(report);
        }
      }
    };

    fetchReport();

    return () => {
      isSubscribed = false;
    };
  }, [params]);

  const nextParameter: NextParameter = nextParameterId => {
    const publishReport = async () => {
      navigation.navigate('BCRSSelectPhotosScreen', {beach: params.beach});
    };

    if (!nextParameterId) {
      return publishReport();
    }

    // Since this is the same screen, we want to add to stack with `push`, instead of navigate
    const push = StackActions.push('BCRSWizardScreen', {
      ...params,
      parameter: nextParameterId,
    });

    navigation.dispatch(push);
  };

  const storeReportParameterValue: StoreReportParameterValue = reportParameterValue => {
    const saveReport = async () => {
      let reportParameters = report.reportParameters;

      const oldReportParameter = reportParameters.find(
        reportParameter =>
          reportParameter.parameterId == reportParameterValue.parameterId,
      );

      if (oldReportParameter) {
        reportParameters = reportParameters.map(reportParameter => {
          if (
            reportParameter.parameterId === reportParameterValue.parameterId
          ) {
            return reportParameterValue;
          }

          return reportParameter;
        });

        // clear out old next parameters based on updated selection
        const oldNextParameterId = oldReportParameter.nextParameterId;

        if (oldNextParameterId !== reportParameterValue.nextParameterId) {
          let oldNextReportParameter = reportParameters.find(
            reportParameter =>
              reportParameter.parameterId === oldNextParameterId,
          );

          // follow the flow removing reportParameters
          while (oldNextReportParameter) {
            reportParameters = reportParameters.filter(reportParameter => {
              return (
                reportParameter.parameterId !==
                oldNextReportParameter?.parameterId
              );
            });

            oldNextReportParameter = reportParameters.find(
              reportParameter =>
                reportParameter.parameterId ===
                oldNextReportParameter?.nextParameterId,
            );
          }
        }
      } else {
        reportParameters.push(reportParameterValue);
      }

      let newReport = {
        ...report,
        reportParameters,
      };

      await storeReport(reportKey, newReport);
      setReport(newReport);
    };

    saveReport();
  };

  return (
    <ReportParameter
      report={report}
      nextParameter={nextParameter}
      storeReportParameterValue={storeReportParameterValue}
      parameterId={params.parameter}
    />
  );
}
