import * as React from 'react';
import ContentModal from '../components/content-modal';
import FaqIcon from '../../assets/images/faq-icon-white.png';
import styled from 'styled-components/native';
import config from './config';
import FrequentlyAskedQuestionsCategories from '../../components/education/faq';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
`;

export default function FrequentlyAskedQuestionCategoriesScreen() {
  return (
    <Screen title="Frequently Asked Questions" icon={FaqIcon}>
      <FrequentlyAskedQuestionsCategories />
    </Screen>
  );
}
