import React from 'react';
import TextBox from '../../TextBox';
import styled from 'styled-components/native';
import {ParameterTypeProps} from '..';
import colors from '../../../constants/colors';
import {View} from 'react-native';
import Text from '../../Text';
import Picker from './Picker';
import ListItemButton from '../../ListItemButton';
import ForwardIcon from '../../../assets/images/forward-icon.png';
import {ParameterType} from '../../../graphql-types';

const Prompt = styled(TextBox)`
  padding-top: 20px;
  font-size: 18px;
  margin-bottom: 1px;
  background: ${colors.app.white};
`;

const Description = styled(TextBox)`
  background: ${colors.app.white};
  padding-bottom: 0;
`;

const RangeSelector = styled(View)`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.6); 	// color.app.white, alpha 60%
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const Label = styled(Text)`
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

const NextButton = styled(ListItemButton)`
  background: ${colors.app.white};
`;

export default function Range({
  parameter,
  storeReportParameterValue,
  nextParameter,
  reportParameter,
}: ParameterTypeProps) {
  if (
    parameter.rangeMin === null ||
    parameter.rangeMax === null ||
    typeof parameter.rangeMin === 'undefined' ||
    typeof parameter.rangeMax === 'undefined'
  ) {
    return null;
  }

  return (
    <>
      {parameter.parameterCategory ? (
        <Description>{parameter.parameterCategory.name}</Description>
      ) : null}
      {parameter.description ? (
        <Description>{parameter.description}</Description>
      ) : null}
      <Prompt bold={true}>{parameter.prompt ?? parameter.name}</Prompt>
      <RangeSelector>
        <Label>
          {parameter.name} ({parameter.unit})
        </Label>
        <Picker
          min={parameter.rangeMin ?? 0}
          max={parameter.rangeMax ?? 100}
          unit={parameter.unit}
          selected={
            reportParameter && reportParameter.value
              ? parseInt(reportParameter.value)
              : (parameter.rangeMin + parameter.rangeMax) / 2
          }
          onValueChange={value => {
            storeReportParameterValue({
              nextParameterId: parameter.nextParameter?.id,
              parameterId: parameter.id,
              value: `${value}`,
            });
          }}
        />
      </RangeSelector>
      <View style={{flexGrow: 1}} />
      <NextButton
        title="Next"
        icon={ForwardIcon}
        onPress={() => {
          nextParameter(parameter.nextParameter?.id);
        }}
      />
    </>
  );
}
