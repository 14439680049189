import * as React from 'react';
import BeachStock from '../../assets/images/beach-stock.jpeg';
import * as S from './styles';
import {ImageSourcePropType, TouchableOpacity, Image} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import CancelIcon from '../../assets/images/cancel-icon.png';
import Education from '../../components/education';
import Text from '../../components/Text';
import styled from 'styled-components/native';
import BackwardIcon from '../../assets/images/arrow-back.png';
import colors from '../../constants/colors';

const Button = styled(TouchableOpacity)`
  flex-grow: 1;
  flex: 1;
  flex-direction: row;
`;

const ButtonText = styled(Text)`
  padding: 14px 5px;
`;

const ButtonIcon = styled(Image)`
  width: 24px;
  height: 24px;
  margin: 8px 0 0 10px;
`;

export default function EducationScreen() {
  const navigation = useNavigation();

  return (
    <>
      <S.Header>
        <S.Title>Education</S.Title>
        <Button
          onPress={_ => {
					navigation.reset({
						index:0,
						routes:[
							{	name: 'Root',
								state: {
									index:0,
									routes: [{screen: "Map"},],
								}
							}
						]
					})
          }}>
          <ButtonIcon source={BackwardIcon} />
          <ButtonText>Back to Map</ButtonText>
        </Button>
      </S.Header>
      <S.Container>
        <S.Background source={BeachStock as ImageSourcePropType}>
          <Education />
        </S.Background>
      </S.Container>
    </>
  );
}
