import React, {PropsWithChildren, useEffect, useState} from 'react';
import {getQueryVariable} from '../utils';
import {Linking, Platform} from 'react-native';
import {useToken} from '../hooks/useTokenContext';
import Storage from '../storage';
import {useError} from '../hooks/useErrorContext';
import WebLoading from './WebLoading';

export default function TokenController({children}: PropsWithChildren<{}>) {
  const {set} = useToken();
  const {set: setError} = useError();
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    const fetchToken = async () => {
      try {
        let storedToken = await Storage.getToken();
        if (storedToken) {
          set(storedToken);
        }

        const url = await Linking.getInitialURL();
        const urlToken = getQueryVariable(url, 'token');
        if (urlToken) {
          set(urlToken);
        }

        setLoaded(true);
      } catch (e) {
        setError(e);
      }
    };

    fetchToken();
  }, []);

  const handleLink = ({url}: {url: string}) => {
    const urlToken = getQueryVariable(url, 'token');
    if (urlToken) {
      set(urlToken);
    }
  };

  useEffect(() => {
    Linking.addEventListener('url', handleLink);

    return () => {
      Linking.removeEventListener('url', handleLink);
    };
  });

  if (!loaded) {
    return Platform.select({
      web: <WebLoading />,
      default: null,
    });
  }

  return <>{children}</>;
}
