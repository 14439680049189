import React, {PropsWithChildren} from 'react';
import {
  StyleProp,
  ViewProps,
  TouchableOpacityProps,
  GestureResponderEvent,
  Image,
  Linking,
  Pressable,
} from 'react-native';
import {TouchableOpacity} from 'react-native';

export default function LogoButton({
  image,
  linkurl,
  style,
  onPress,
  ...props
}: PropsWithChildren & TouchableOpacityProps) {
  const handlePress = () => {
    if (linkurl) {
      Linking.openURL(linkurl);
    }
    if (onPress) {
      onPress();
    }
  };

  return (
    <TouchableOpacity style={style} onPress={handlePress}>
      <Image source={image} style={style} resizeMode="contain" />
    </TouchableOpacity>
  );
}