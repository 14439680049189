import React from 'react';
import * as S from './styles';
import SignInUser from '../../components/sign-in-user';
import BeachStock from '../../assets/images/beach-stock.jpeg';
import {ImageSourcePropType, TouchableOpacity} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import CancelIcon from '../../assets/images/cancel-icon.png';

export default function SignInUserScreen() {
  const navigation = useNavigation();
  
  return (
    <>
      <S.Header>
        <S.Title>Sign In</S.Title>
        <TouchableOpacity
          onPress={e => navigation.navigate('Root', {screen: 'Map'})}>
          <S.Cancel source={CancelIcon as ImageSourcePropType} />
        </TouchableOpacity>
      </S.Header>
      <S.Container>
        <S.Background source={BeachStock as ImageSourcePropType}>
          <SignInUser />
        </S.Background>
      </S.Container>
    </>
  );
}
