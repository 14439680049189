import React, {PropsWithChildren, useEffect} from 'react';
import {useAuthenticatedUser} from '../hooks/useAuthenticatedUserContext';
import {useGetMeQuery} from '../graphql-types';
import {useToken} from '../hooks/useTokenContext';
import {useError} from '../hooks/useErrorContext';
import {useLoading} from '../hooks/useLoadingContext';
import {Platform} from 'react-native';
import WebLoading from './WebLoading';

function LoadAuthenticatedUserController() {
  const {data, error, loading} = useGetMeQuery({
    fetchPolicy: 'no-cache',
  });
  const {set: setUser} = useAuthenticatedUser();
  const {set: setError} = useError();
  const {clear: clearToken} = useToken();
  const {set: setLoading, clear: clearLoading} = useLoading();

  useEffect(() => {
    if (data?.me) {
      return setUser(data.me);
    }
  }, [data]);

  useEffect(() => {
    if (loading) {
      setLoading();
    } else {
      clearLoading();
    }
  }, [loading]);

  // @todo handle bad token?
  useEffect(() => {
    if (error) {
      setError(error);
      clearToken();
    }
  }, [error]);

  return Platform.select({
    web: <WebLoading />,
    default: null,
  });
}

export default function AuthenticatedUserController({
  children,
}: PropsWithChildren<{}>) {
  const {token} = useToken();
  const {clear, user} = useAuthenticatedUser();

  useEffect(() => {
    if (!token) {
      clear();
    }
  }, [token]);

  if (token && !user) {
    return <LoadAuthenticatedUserController />;
  }

  return <>{children}</>;
}
