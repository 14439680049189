import React, {useEffect, useState} from 'react';
import styled from 'styled-components/native';
import {View, Platform, Image, StyleSheet, ScrollView, Dimensions} from 'react-native';
import resources from '../constants/resources';
import ListItemButton from './ListItemButton';
import * as WebBrowser from 'expo-web-browser';
import Hotline from './Hotline';
import constants from '../constants/constants';
import * as Device from 'expo-device';
import LogoBox from './LogoBox';
import colors from '../constants/colors';

const Container = styled(ScrollView)`
  ${({isDesktop}) => {
    if (isDesktop) {
  		return `
 		 	display: flex;
  			flex-grow: 1;
			max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
  		`;
  	}
  	
  	return `
  			display: flex;
  			flex-grow: 1;
  			background: ${colors.app.white};
  	`;
  	}}
`;

//	Style for resource items to look like a link.
const styles = StyleSheet.create({
	title: {
     	color: "blue",
     	textDecorationLine: "underline",
     	cursor: "pointer"
    }
});

export default function Resources() {
	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);

	useEffect(() => {
		const getDeviceType = async () => {
			const type = await Device.getDeviceTypeAsync();
			setDeviceType(type);
		};

		getDeviceType();
	});

  return (
    <Container isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      <Hotline />
  	  {resources.map((resource, idx) => (
        <ListItemButton
          key={idx}
          title={resource.name}
          titleStyle={styles.title}
          description={resource.description}
          onPress={() => {
            if (Platform.OS === 'web') {
              window.open(resource.link, '_blank');
            } else {
              WebBrowser.openBrowserAsync(resource.link);
            }
          }}
        />
      ))}
      { deviceType !== Device.DeviceType.DESKTOP ? (<LogoBox />) : null}
    </Container>
  );
}
