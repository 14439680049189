import React, {useState, useEffect} from 'react';
import {View, Image, TouchableOpacity} from 'react-native';
import Text from '../Text';
import styled from 'styled-components/native';
import ExpandMoreIcon from '../../assets/images/expand-more.png';
import CancelIcon from '../../assets/images/cancel-icon.png';
import colors from '../../constants/colors';
import TextInput from '../../components/TextInput';
import BeachList from './BeachList';
import {useLoading} from '../../hooks/useLoadingContext';
import {useGetBeachesQuery} from '../../graphql-types';
import SearchResults from './SearchResults';
import {useNavigation} from '@react-navigation/native';
import MagGlass from '../../assets/images/search.png';

const Container = styled(View)`
  background: ${colors.app.white};
  padding: 13px 20px 5px 20px;
`;

const SearchBox = styled(View)`
  background: ${colors.app.white};
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  elevation: 5;
  border-radius: 5px;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
`;

const ClearButton = styled(TouchableOpacity)`
  padding: 8px;
`;

const Cancel = styled(Image)`
  width: 24px;
  height: 24px;
`;

const ExpandBeaches = styled(TouchableOpacity)`
  font-family: tablet-gothic;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px 2px 5px;
  font-size: 14px;
  color: ${colors.app.black};
`;

const ExpandLabel = styled(Text)`
  padding-top: 3px;
  padding-left: 5px;
  color: ${colors.mote.secondary.sharkGray};
`;

const MagIcon = styled(Image)`
	height: 25px;
	width: 25px;
	margin-top: 10px;
	margin-left: 5px;
`;

const ExpandIcon = styled(Image)`
  width: 12px;
  height: 12px;
  padding: 0px 0px 0px 5px;
`;

const SearchBoxTextInput = styled(TextInput)`
  flex-grow: 1;
  width: 240px;
`;
  
export default function Search() {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const {data, loading} = useGetBeachesQuery();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const navigation = useNavigation();
  
  function openBeachesModal(text: string) {
	navigation.reset({
		index:0,
		routes:[
			{	name: 'Search',
				state: {
					index:0,
					routes: [{name: "Search", params: {searchParam: text}},],
				}
			}
		]
	})
   }

  function allBeaches() {
  	setSearchText(undefined);
  	global.saveSearch = "";
  	openBeachesModal("");
  }
  
  function textCommit(e) {
  	global.saveSearch = searchText;
  	if (e.nativeEvent.text) {
  		openBeachesModal(searchText)
  	}
  }

 useEffect(() => {
    if (loading) {
      return setLoading();
    }
    clearLoading();
  }, [loading]);

  if (!data?.beaches) {
    return null;
  }
  
  const showBeachList = () => {
    setSearchText(undefined);
    openBeachesModal()
  };
  
  return (
    <Container>
      <SearchBox>
    	<MagIcon source={MagGlass}/>
        <SearchBoxTextInput
          value={searchText ? searchText : ''}
          placeholder='beach name, city, county, or state'
          onChangeText={setSearchText}
          onSubmitEditing={textCommit}
        />
        {searchText ? (
          <ClearButton onPress={() => setSearchText(undefined)}>
            <Cancel source={CancelIcon} />
          </ClearButton>
        ) : null}
      </SearchBox>

      <ExpandBeaches onPress={allBeaches}>
        <ExpandIcon source={ExpandMoreIcon}/>
        <ExpandLabel>Show Beach Locations</ExpandLabel>
      </ExpandBeaches>
    </Container>
  );
}
