import * as React from 'react';
import ContentModal from '../components/content-modal';
import VideosIcon from '../../assets/images/videos-icon-white.png';
import styled from 'styled-components/native';
import config from './config';
import Videos from '../../components/education/videos';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
`;

export default function VideosScreen() {
  return (
    <Screen title="Videos" icon={VideosIcon}>
      <Videos />
    </Screen>
  );
}
