import React, {useEffect, useState} from 'react';
import {Dimensions} from 'react-native';
import YouTube from 'react-youtube';

export default function Video({id}: {id: string}) {
  const [dimensions, setDimensions] = useState({window, screen});

  const onChange = ({window, screen}) => {
    setDimensions({window, screen});
  };

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  });

  return (
    <YouTube
      play={false}
      opts={{
        width: dimensions.screen.width,
        height: (dimensions.screen.width * 9) / 16,
      }}
      videoId={id} // defaults -> null
    />
  );
}
