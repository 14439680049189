import React from 'react';
import {
  ParameterCategoryFragment,
  ParameterCategoryFirstParameterFragment,
} from '../../../graphql-types';
import ForwardIcon from '../../../assets/images/forward-arrow.png';
import ListItemButton from '../../ListItemButton';
import {useNavigation, useRoute} from '@react-navigation/native';
import {SelectParameterCategoryRouteProp} from '.';

type ParameterCategoryListProps = {
  parameterCategories: ParameterCategoryFragment[];
};

export default function ParameterCategoryList({
  parameterCategories,
}: ParameterCategoryListProps) {
  const navigation = useNavigation();
  const {
    params: {layer},
  } = useRoute<SelectParameterCategoryRouteProp>();

  return (
    <>
      {parameterCategories.map(
        (
          category: ParameterCategoryFragment &
            ParameterCategoryFirstParameterFragment,
        ) => {
          return (
            <ListItemButton
              onPress={_ => {
                navigation.navigate('CSICWizardScreen', {
                  layer: layer,
                  category: category.id,
                  parameter: category.first.id,
                });
              }}
              key={category.id}
              title={category.name}
              description={category?.description}
              icon={ForwardIcon}
              iconLeft={{uri: category.icon ?? undefined}}
            />
          );
        },
      )}
    </>
  );
}
