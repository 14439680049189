import React, {useEffect} from 'react';
import {
  LayerFragment,
  useGetLayersQuery,
  LayerType,
} from '../../../graphql-types';
import ForwardIcon from '../../../assets/images/forward-arrow.png';
import {useNavigation} from '@react-navigation/native';
import {useLoading} from '../../../hooks/useLoadingContext';
import ListItemButton from '../../ListItemButton';
import {useLocation} from '../../../hooks/useLocationContext';

export default function CreateReport() {
  const {location} = useLocation();
  const navigation = useNavigation();
  const {data, loading} = useGetLayersQuery({
    fetchPolicy: 'cache-and-network',
    variables: {type: LayerType.Csic},
  });
  const {set: setLoading, clear: clearLoading} = useLoading();

  useEffect(() => {
    if (loading) {
      setLoading();
    }
  }, [loading]);

  useEffect(() => {
    if (!loading) {
      clearLoading();
    }
  }, [loading]);

  if (!data?.layers) {
    // error occured, handle somewhere else
    return null;
  }

  if (!location) {
    // @todo cannot proceed without location
    return null;
  }

  return (
    <>
      {data?.layers.map(function (layer: LayerFragment) {
        return (
          <ListItemButton
            key={layer.id}
            icon={ForwardIcon}
            title={layer.name}
            description={layer.description}
            onPress={() => {
              navigation.navigate('SelectParameterCategoryScreen', {
                layer: layer.id,
              });
            }}
          />
        );
      })}
    </>
  );
}
