export default {
  prefixes: [
    'https://visitbeaches.org',
    'https://dev.visitbeaches.org',
    'bcrs://',
  ],
  config: {
    screens: {
      Root: {
        screens: {
          Map: 'map',
          Beach: {
            screens: {
              BeachScreen: 'beach/:beach',
              BeachReportScreen: 'beach/:beach/report/:report',
              BeachPhotosScreen: 'beach/:beach/photos',
              BeachAmenitiesScreen: 'beach/:beach/amenities',
            },
          },
          CreateReport: {
            screens: {
              CreateReportScreen: 'create-report',
              ReportSuccessScreen: 'report-success',
              ReportPendingScreen: 'report-pending',
              ReportFailedScreen: 'report-failed',
              SelectParameterCategoryScreen: 'create-report/:layer',
              CSICWizardScreen:
                'create-report/csic/:layer/:category/:parameter',
              BCRSWizardScreen: 'create-report/bcrs/:beach/:parameter',
              BCRSSelectPhotosScreen: 'create-report/bcrs/:beach/photos',
              BCRSReviewScreen: 'create-report/bcrs/:beach/review',
            },
          },
          Welcome: {
            screens: {
              WelcomeScreen: '/',
            },
          },
          Alerts: {
            screens: {
              AlertsSubscribeScreen: 'alerts',
              BeachAlertsScreen: 'alerts/manage',
              AlertsVerifyEmailScreen: 'alerts/:email',
            },
          },
          Resources: {
            screens: {
              ResourcesScreen: 'resources',
            },
          },
          Education: {
            screens: {
              EducationScreen: 'education',
              FrequentlyAskedQuestions: 'education/faq/:category',
              FrequentlyAskedQuestionCategories: 'education/faq',
              Infographics: 'education/infographics',
              Videos: 'education/videos',
              Video: 'education/videos/:video',
            },
          },
          Contact: {
            screens: {
              ContactScreen: 'contact',
            },
          },
          Search: {
          	screens: {
          		SearchScreen: 'search',
          	},
          },
        },
      },
      SignInUserScreen: '/sign-in',
    },
  },
};
