import React, {useState} from 'react';
import styled from 'styled-components/native';
import {
  View,
  Image,
  TouchableOpacity,
  StyleProp,
  ViewStyle,
  ScrollView,
} from 'react-native';
import colors from '../../../../constants/colors';
import Text from '../../../Text';
import WhiteCancelIcon from '../../../../assets/images/cancel-icon-white.png';
import {ReportFragment} from '../../../../graphql-types';
import Report from './Report';

const Container = styled(View)`
  background-color: ${colors.mote.primary.blue};
  border-radius: 5px;
  z-index: 10000000;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
`;

const ScrollContainer = styled(ScrollView)`
  max-height: 300px;
  over
`;

const Header = styled(View)`
  display: flex;
  flex-direction: row;
  padding: 8px;
`;

const CloseButton = styled(TouchableOpacity)``;

const CloseIcon = styled(Image)`
  width: 24px;
  height: 24px;
`;

const Title = styled(Text)`
  color: ${colors.app.white};
  padding: 5px;
  flex-grow: 1;
`;

export type DialogProps = {
  reports?: ReportFragment[] | null;
  style?: StyleProp<ViewStyle>;
  onCancel?: () => void;
  isDesktop?: boolean;
};

export default function Dialog({
  reports,
  style,
  onCancel,
  isDesktop,
}: DialogProps) {
  const [selected, setSelected] = useState<string | null>(null);

  if (!reports) {
    return null;
  }

  return (
    <Container style={style}>
      <Header>
        <Title>Community Science Reports</Title>
        <CloseButton onPress={_ => onCancel && onCancel()}>
          <CloseIcon source={WhiteCancelIcon} />
        </CloseButton>
      </Header>
      <ScrollContainer>
        {reports.map(report => (
          <Report
            key={report.id}
            selected={selected == report.id}
            report={report}
            setSelected={setSelected}
          />
        ))}
      </ScrollContainer>
    </Container>
  );
}
