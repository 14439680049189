import {Ionicons} from '@expo/vector-icons';
import * as Font from 'expo-font';
import {useEffect, useState} from 'react';
import {Asset} from 'expo-asset';
import BeachStock from '../assets/images/beach-stock.jpeg';

export default function useCachedResources() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const loadFonts = async () => {
      await Font.loadAsync({
        ...Ionicons.font,
        'tablet-gothic': require('../assets/fonts/tablet-gothic.otf'),
        'tablet-gothic-bold': require('../assets/fonts/tablet-gothic-bold.otf'),
      });
    };

    const loadImages = async () => {
      await Asset.fromModule(BeachStock).downloadAsync();
    };

    const loadResources = async () => {
      await loadFonts();
      await loadImages();
      setLoaded(true);
    };

    loadResources();
  }, []);

  return loaded;
}
