import React from 'react';
import styled from 'styled-components/native';
import {View, Image, ImageSourcePropType, TouchableOpacity} from 'react-native';
import Text from '../../components/Text';
import HelpIcon from '../../assets/images/help-icon.png';
import CancelIcon from '../../assets/images/cancel-icon.png';
import colors from '../../constants/colors';

const Container = styled(View)`
  position: absolute;
  bottom: 3;
  left: 3;
  right: 3;
  border: 2px solid ${colors.mote.primary.darkBlue};
  border-radius: 5px;
  padding: 5px 10px 20px 10px;
  z-index: 10000;
  background-color: ${colors.mote.primary.lightBlue};
  elevation: 2;
`;

const Parameter = styled(View)`
  margin-bottom: 10px;
`;

const ParameterName = styled(View)`
  display: flex;
  flex-direction: row;
`;

const ParameterNameText = styled(Text)`
  flex-grow: 1;
  font-size: 18px;
`;
const ParameterDescription = styled(Text)``;

const ParameterValue = styled(View)`
  display: flex;
  margin-bottom: 5px;
`;

const ParameterValueName = styled(Text)`
  font-weight: bold;
  margin-right: 5px;
  font-size: 18px;
`;

const ParameterValueDescription = styled(Text)``;

const InfoIcon = styled(Image)`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 2px;
`;

const CloseIcon = styled(Image)`
  width: 15px;
  height: 15px;
  margin-left: 10px;
`;

const CloseText = styled(Text)`
  flex-grow: 1;
  text-align: right;
`;

const CloseButton = styled(TouchableOpacity)`
  display: flex;
  flex-direction: row;
`;

type ParameterValueInfo = {
  name: string;
  description?: string | null;
};

export type IParameterInfo = {
  name: string;
  description?: string | null;
  values: ParameterValueInfo[];
};

export default function ParameterInfo({
  parameterInfo,
  setParameterInfo,
}: {
  parameterInfo: IParameterInfo;
  setParameterInfo: (value: IParameterInfo | false) => void;
}) {
  let {name, description, values} = parameterInfo;
  return (
    <Container>
      <CloseButton onPress={() => setParameterInfo(false)}>
        <CloseText></CloseText>
        <CloseIcon source={CancelIcon} />
      </CloseButton>
      <Parameter>
        <ParameterName>
          <InfoIcon source={HelpIcon} />
          <ParameterNameText>{name}</ParameterNameText>
        </ParameterName>
        {description ? (
          <ParameterDescription>{description}</ParameterDescription>
        ) : null}
      </Parameter>
      {values.map(value => (
        <ParameterValue key={value.name}>
          <ParameterValueName>{value.name}</ParameterValueName>
          {value.description ? (
            <ParameterValueDescription>
              {value.description}
            </ParameterValueDescription>
          ) : null}
        </ParameterValue>
      ))}
    </Container>
  );
}
