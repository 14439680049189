import React, {useEffect, useState} from 'react';
import styled from 'styled-components/native';
import {View, ScrollView, Dimensions} from 'react-native';
import {useGetInfographicsQuery} from '../../../graphql-types';
import {useLoading} from '../../../hooks/useLoadingContext';
import Infograph from './Infographic';
import constants from '../../../constants/constants';
import * as Device from 'expo-device';
import colors from '../../../constants/colors';

const Container = styled(ScrollView)`
  ${({isDesktop}) => {
    if (isDesktop) {
  		return `
 		 	display: flex;
  			flex-grow: 1;
			max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
  			background: ${colors.app.white};
  		`;
  	}
  	
  	return `
  			display: flex;
  			flex-grow: 1;
  			background: ${colors.app.white};
  	`;
  	}}
`;

export default function Infographics() {
	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);

	useEffect(() => {
		const getDeviceType = async () => {
			const type = await Device.getDeviceTypeAsync();
			setDeviceType(type);
		};

		getDeviceType();
	});

  const {data, loading} = useGetInfographicsQuery();
  const {set: setLoading, clear: clearLoading} = useLoading();

  useEffect(() => {
    if (loading) {
      return setLoading();
    }

    clearLoading();
  }, [loading]);

  if (!data || !data.infographics) {
    return null;
  }
  return (
    <Container isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      {data.infographics.map(infographic => (
        <Infograph key={infographic.id} infographic={infographic} />
      ))}
    </Container>
  );
}
