import React, { useState, useEffect } from 'react';
import { RootStackParamList } from '../../../types';
import { RouteProp, useRoute, useNavigation } from '@react-navigation/native';
import ReportParameter, {
  NextParameter,
  StoreReportParameterValue,
} from '../../report-parameter';
import { Report } from '../../../storage/report';
import { StackActions } from '@react-navigation/native';
import { useReport } from '../../../hooks/useReportContext';
import { useLocation } from '../../../hooks/useLocationContext';
import { ReportableType } from '../../../graphql-types';

export type CSICWizardScreenRouteProps = RouteProp<
  RootStackParamList,
  'CSICWizardScreen'
>;

export default function Wizard() {
  const {
    submit: submitReport,
    store: storeReport,
    get: getReport,
  } = useReport();
  const { params } = useRoute<CSICWizardScreenRouteProps>();
  const { location } = useLocation();
  const [report, setReport] = useState<Report>({
    photos: [],
    createdAt: new Date(),
    completed: false,
    reportable: {
      type: ReportableType.Layer,
      id: params.layer,
    },
    location: {
      latitude: location ? location.latitude : 0,
      longitude: location ? location.longitude : 0,
    },
    reportParameters: [],
  });
  const navigation = useNavigation();

  const reportKey = ['csic', params.layer, params.category, 'current'];

  if (!location) {
    // throw a error failing to get location, cant proceed
    return null;
  }

  useEffect(() => {
    let isSubscribed = true;

    const fetchReport = async () => {
      let report = await getReport(reportKey);
      if (isSubscribed) {
        if (report && !report.completed) {
          setReport(report);
        }
      }
    };

    fetchReport();

    return () => {
      isSubscribed = false;
    };
  }, [params]);

  const nextParameter: NextParameter = nextParameterId => {
    const publishReport = async () => {
      let result = await submitReport(reportKey);

      switch (result) {
        case 'success':
          navigation.reset({
            index: 0,
            routes: [{ name: 'ReportSuccessScreen' }],
          });
          break;
        case 'pending':
          navigation.reset({
            index: 0,
            routes: [{ name: 'ReportPendingScreen' }],
          });
          break;
        case 'failed':
        default:
          navigation.reset({
            index: 0,
            routes: [{ name: 'ReportFailedScreen' }],
          });
      }
    };

    if (!nextParameterId) {
      return publishReport();
    }

    // Since this is the same screen, we want to add to stack with `push`, instead of navigate
    const push = StackActions.push('CSICWizardScreen', {
      ...params,
      parameter: nextParameterId,
    });

    navigation.dispatch(push);
  };

  const storeReportParameterValue: StoreReportParameterValue = reportParameterValue => {
    const saveReport = async () => {
      let reportParameters = report ? report.reportParameters : [];
      const oldReportParameterValue = reportParameters.find(
        reportParameter => reportParameter.parameterId == reportParameterValue.parameterId,
      );

      if (oldReportParameterValue) {
        // replace
        reportParameters.map(reportParameter => {
          if (reportParameter.parameterId == reportParameterValue.parameterId) {
            return reportParameterValue;
          }

          return reportParameter;
        });
      } else {
        reportParameters.push(reportParameterValue);
      }

      let newReport = {
        ...report,
        reportParameters,
      };
      await storeReport(reportKey, newReport);
      setReport(newReport);
    };

    saveReport();
  };

  return (
    <ReportParameter
      report={report}
      nextParameter={nextParameter}
      storeReportParameterValue={storeReportParameterValue}
      parameterId={params.parameter}
    />
  );
}
