import React, {useState, useEffect} from 'react';
import styled from 'styled-components/native';
import {View, Image, TouchableOpacity, Platform} from 'react-native';
import Text from '../../Text';
import UploadPhotoIcon from '../../../assets/images/upload-photo-icon.png';
import CameraPhotoIcon from '../../../assets/images/camera-photo-icon.png';
import ArrowForwardIcon from '../../../assets/images/forward-arrow.png';
import {useNavigation, useRoute, RouteProp} from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import {ImageInfo} from 'expo-image-picker/build/ImagePicker.types';
import ListItemButton from '../../ListItemButton';
import {RootStackParamList} from '../../../types';
import {useReport} from '../../../hooks/useReportContext';
import {useLoading} from '../../../hooks/useLoadingContext';
import {Report} from '../../../storage/report';
import {useNotification} from '../../../hooks/useNotificationContext';
import colors from '../../../constants/colors';

const Container = styled(View)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const ButtonsContainer = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const PhotosContainer = styled(View)`
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
`;

const ItemButton = styled(TouchableOpacity)`
  display: flex;
  width: 28%;
  height: 100px;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

const Item = styled(Image)`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ButtonItem = styled(TouchableOpacity)`
  display: flex;
  width: 28%;
  height: 100px;
  justify-content: center;
  align-items: center;
`;

const ItemText = styled(Text)``;

const Icon = styled(Image)`
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
`;

const NextButton = styled(ListItemButton)`
  background-color: ${colors.app.white};
`;

export type ImageResult = ImagePicker.ImagePickerResult & ImageInfo;

export type BCRSSelectPhotosScreenRouteProp = RouteProp<
  RootStackParamList,
  'BCRSSelectPhotosScreen'
>;

export default function UploadPhotos() {
  const {store: storeReport, get: getReport} = useReport();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const navigation = useNavigation();
  const {params} = useRoute<BCRSSelectPhotosScreenRouteProp>();
  const [report, setReport] = useState<Report | undefined>(undefined);
  const {set: setNotification} = useNotification();

  const reportKey = ['bcrs', params.beach, 'current'];

  useEffect(() => {
    let isSubscribed = true;

    const fetchReport = async () => {
      let report = await getReport(reportKey);

      if (isSubscribed) {
        setReport(report);
      }
    };

    fetchReport();

    return () => {
      isSubscribed = false;
    };
  }, [params]);

  useEffect(() => {
    let isSubscribed = true;

    const getPermissions = async () => {
      const {
        status: camera,
      } = await ImagePicker.requestCameraPermissionsAsync();
      if (isSubscribed && camera !== 'granted') {
        setNotification({
          type: 'error',
          message: 'Access denied for camera.',
        });
      }

      const {
        status: media,
      } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (isSubscribed && media !== 'granted') {
        setNotification({
          type: 'error',
          message: 'Access denied to photos.',
        });
      }
    };

    if (Platform.OS !== 'web') {
      getPermissions();
    }

    return () => {
      isSubscribed = false;
    };
  }, []);

  if (!report) {
    return null;
  }

  const selectPhoto = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });

    if (result.cancelled) {
      return;
    }

    let updated: ImageResult[] = [...report.photos, result as ImageResult];
    setReport({
      ...report,
      photos: updated,
    });
  };

  const takePhoto = async () => {
    let result = await ImagePicker.launchCameraAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 0.5,
    });

    if (result.cancelled) {
      return;
    }

    let updated: ImageResult[] = [...report.photos, result as ImageResult];
    setReport({
      ...report,
      photos: updated,
    });
  };

  const removePhoto = (index: number) => {
    let photos = [...report.photos];
    photos.splice(index, 1);

    setReport({
      ...report,
      photos,
    });
  };

  const storeReportPhotos = async () => {
    setLoading();
    await storeReport(reportKey, report);
    clearLoading();

    navigation.navigate('BCRSReviewScreen', {beach: params.beach});
  };

  return (
    <Container>
      <ButtonsContainer>
        {Platform.OS !== 'web' ? (
          <ButtonItem
            disabled={report.photos.length >= 3}
            onPress={() => {
              takePhoto();
            }}>
            <Icon source={CameraPhotoIcon} />
            <ItemText>Camera</ItemText>
          </ButtonItem>
        ) : null}
        <ButtonItem
          disabled={report.photos.length >= 3}
          onPress={() => {
            selectPhoto();
          }}>
          <Icon source={UploadPhotoIcon} />
          <ItemText>Select</ItemText>
        </ButtonItem>
        <ButtonItem
          onPress={() => {
            navigation.navigate('BCRSReviewScreen', {beach: params.beach});
          }}>
          <Icon source={ArrowForwardIcon} />
          <ItemText>Skip</ItemText>
        </ButtonItem>
      </ButtonsContainer>
      <PhotosContainer>
        {report.photos.map((photo, index) => (
          <ItemButton key={index} onPress={() => removePhoto(index)}>
            <Item source={photo} />
          </ItemButton>
        ))}
      </PhotosContainer>
      <NextButton
        title="Next"
        icon={ArrowForwardIcon}
        onPress={() => storeReportPhotos()}
      />
    </Container>
  );
}
