import React, {useContext, PropsWithChildren, useState} from 'react';
import {useNetworkConnection} from './useNetworkConnectionContext';
import {ReportKey, Report} from '../storage/report';
import Storage from '../storage';
import {useLoading} from './useLoadingContext';
import {
  useSubmitReportMutation,
  useUploadImageAttachmentForReportMutation,
} from '../graphql-types';
import {getBlobForWeb, getFileForNative} from '../utils';
import {Platform} from 'react-native';
import {ReactNativeFile} from 'apollo-upload-client';
import {useNotification} from './useNotificationContext';

type SubmitReport = (key: ReportKey) => Promise<SubmitReportResult>;
type StoreReport = (key: ReportKey, report: Report) => Promise<void>;
type GetReport = (key: ReportKey) => Promise<Report | undefined>;

type ReportContext = {
  submit: SubmitReport;
  store: StoreReport;
  get: GetReport;
};

const ReportContext = React.createContext<ReportContext>({
  submit: async (_key: ReportKey) => {
    return 'failed';
  },
  store: async (_key: ReportKey, _report: Report) => {},
  get: async (_key: ReportKey) => {
    return undefined;
  },
});

export type SubmitReportResult = 'success' | 'pending' | 'failed';

const ReportProvider = ({children}: PropsWithChildren<{}>) => {
  const {set: setNotification} = useNotification();
  const {active: networkIsConnected} = useNetworkConnection();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const [submitReport] = useSubmitReportMutation();
  const [
    uploadImageAttachmentForReport,
  ] = useUploadImageAttachmentForReportMutation();

  const store: StoreReport = async (key, report) => {
    Storage.setReport(key, report);
  };

  const get: GetReport = async key => {
    let report = await Storage.getReport(key);
    return report;
  };

  const submit: SubmitReport = async key => {
    const result = (result: SubmitReportResult) => {
      clearLoading();
      return result;
    };

    setLoading();
    let report = await get(key);
    if (!report) {
      return result('failed');
    }

    // store current as completed
    await store(key, {...report, completed: true});

    if (!networkIsConnected) {
      return result('pending');
    }

    const {data} = await submitReport({
      variables: {
        input: {
          location: report.location,
          reportable: report.reportable,
          createdAt: report.createdAt.toISOString().split('.')[0] + 'Z',
          reportParameters:
            report.reportParameters.map(reportParameter => ({
              parameterId: reportParameter.parameterId,
              value: reportParameter.value,
              reportParameterValues:
                reportParameter.reportParameterValues ?? [],
            })) ?? [],
        },
      },
    });

    if (!data || !data.submitReport) {
      setNotification({
        type: 'error',
        message: 'Failed. Please try again later.',
      });
      return result('failed');
    }

    const submitReportResult = data.submitReport;

    if (
      submitReportResult &&
      submitReportResult.success &&
      submitReportResult.report
    ) {
      let publishedReport = submitReportResult.report;

      report.photos.forEach(async photo => {
        const image = Platform.select<Blob | ReactNativeFile>({
          web: await getBlobForWeb(photo.uri),
          ios: getFileForNative(photo.uri),
          android: getFileForNative(photo.uri),
        });

        await uploadImageAttachmentForReport({
          variables: {
            reportId: publishedReport.id,
            image: image,
          },
        });
      });

      if (key[key.length - 1] == 'current') {
        key.pop();
      }

      // store for later reference
      await store(key, {
        ...report,
        id: submitReportResult.report.id,
        publisedAt: new Date(),
        completed: true,
      });

      setNotification({
        type: 'success',
        message: 'Report published.',
      });

      return result('success');
    }

    return result('failed');
  };

  return (
    <>
      <ReportContext.Provider value={{submit, store, get}}>
        {children}
      </ReportContext.Provider>
    </>
  );
};

const useReport = () => useContext(ReportContext);

export {ReportProvider, useReport};
