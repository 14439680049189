import React, {useContext, PropsWithChildren, useState, useEffect} from 'react';
import NetInfo from '@react-native-community/netinfo';

type NetworkConnectionContext = {
  active: boolean;
};

const NetworkConnectionContext = React.createContext<NetworkConnectionContext>({
  active: false,
});

const NetworkConnectionProvider = ({
  children,
  ...props
}: PropsWithChildren<{}>) => {
  const [state, setState] = useState<boolean>(false);

  useEffect(() => {
    let isSubscribed = true;

    const getNetworkStatus = async () => {
      let state = await NetInfo.fetch();
      if (isSubscribed) {
        setState(state.isConnected);
      }
    };

    getNetworkStatus();

    return () => {
      isSubscribed = false;
    };
  }, []);

  useEffect(() => {
    const unsubscribe = NetInfo.addEventListener(state => {
      setState(state.isConnected);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      <NetworkConnectionContext.Provider value={{active: state}} {...props}>
        {children}
      </NetworkConnectionContext.Provider>
    </>
  );
};

const useNetworkConnection = () => useContext(NetworkConnectionContext);

export {NetworkConnectionProvider, useNetworkConnection};
