import gql from 'graphql-tag';
import {BeachFragment} from './beach';

export const AlertFragment = gql`
  fragment Alert on Alert {
    id
    uuid
    email
    beaches {
      ...Beach
    }
  }
  ${BeachFragment}
`;
