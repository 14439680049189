import React from 'react';
import {
  View,
  ImageSourcePropType,
  StyleProp,
  ViewProps,
  Text,
  Image,
} from 'react-native';
import styled from 'styled-components/native';

const Item = styled(View)`
  padding: 10px;
  flex-direction: row;
  display: flex;
  background: rgba(255, 255, 255, 1.0);
  margin-bottom: 1px;
  justify-content: center;
  align-items: center;
`;

const Content = styled(View)`
  flex-grow: 1;
  display: flex;
  flex: 1;
`;

const IconLeft = styled(Image)`
  height: 22px;
  width: 22px;
  margin-right: 10px;
`;

const Icon = styled(Image)`
  height: 22px;
  width: 22px;
  margin-left: 10px;
`;

const Title = styled(View)`
  display: flex;
`;

const TitleText = styled(Text)`
  font-size: 18px;
`;

const Description = styled(View)`
  display: flex;
`;

const DescriptionText = styled(Text)``;

export type ListItemProps = {
  icon?: ImageSourcePropType;
  iconLeft?: ImageSourcePropType;
  style?: StyleProp<ViewProps>;
  title?: string;
  titleStyle?: StyleSheet;
  description?: string | null;
};

export default function ListItem({
  icon,
  iconLeft,
  style,
  title,
  titleStyle,
  description,
}: ListItemProps) {
  if (description?.length == 0) {
    description = undefined;
  }
  return (
    <Item style={style}>
      {iconLeft ? <IconLeft source={iconLeft} /> : null}
      <Content>
        {title ? (
          <Title>
            <TitleText style={titleStyle ? titleStyle : null}>{title}</TitleText>
          </Title>
        ) : null}
        {description ? (
          <Description>
            <DescriptionText>{description}</DescriptionText>
          </Description>
        ) : null}
      </Content>
      {icon ? <Icon source={icon} /> : null}
    </Item>
  );
}
