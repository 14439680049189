import gql from 'graphql-tag';
import {
  ParameterFragment,
  ParameterValueFragment,
  ParameterCategoryFragment,
} from './parameter';
import {UserFragment} from './user';

const ReportParameterFragment = gql`
  fragment ReportParameter on ReportParameter {
    parameter {
      ...Parameter
    }
    parameterValues {
      ...ParameterValue
    }
    value
    display
  }
  ${ParameterFragment}
  ${ParameterValueFragment}
`;

export const ReportFragment = gql`
  fragment Report on Report {
    id
    user {
      ...User
    }
    agree
    disagree
    createdAt
    latitude
    longitude
    reportParameters {
      ...ReportParameter
    }
    reportableType
    reportableId
  }
  ${ReportParameterFragment}
  ${UserFragment}
`;

const BeachReportFragment = gql`
  fragment BeachReport on Report {
    id
    user {
      ...User
    }
    createdAt
    latitude
    longitude
    beachReport {
      parameterCategory {
        ...ParameterCategory
      }
      reportParameters {
        parameter {
          ...Parameter
        }
        parameterValues {
          ...ParameterValue
        }
        value
      }
    }
  }
  ${ReportParameterFragment}
  ${ParameterFragment}
  ${ParameterCategoryFragment}
  ${ParameterValueFragment}
`;

export const WithLastThreeReportsFragment = gql`
  fragment WithLastThreeReports on Beach {
    lastThreeDaysOfReports {
      ...BeachReport
    }
  }
  ${BeachReportFragment}
`;

export const ClusterFragment = gql`
  fragment Cluster on Cluster {
    key
    center {
      latitude
      longitude
    }
    reports {
      ...Report
    }
  }
  ${ReportFragment}
`;
