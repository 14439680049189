import React, {useContext, PropsWithChildren, useState} from 'react';
import Storage from '../storage';
import {
  UserFragment,
  LayerType,
  BeachFragment,
  FirstParameterFragment,
} from '../graphql-types';

type AuthenticatedUser = UserFragment & {
  beaches: (BeachFragment & FirstParameterFragment)[];
};

type AuthenticatedUserContext =
  | {
      type: LayerType;
      user: AuthenticatedUser;
    }
  | undefined
  | false;

type SetUser = (value: AuthenticatedUser | false) => void;
type ClearUser = () => void;

const AuthenticatedUserContext = React.createContext<{
  user: AuthenticatedUserContext;
  set: SetUser;
  clear: ClearUser;
}>({
  user: undefined,
  set: _ => {},
  clear: () => {},
});

const AuthenticatedUserProvider = ({
  children,
  ...props
}: PropsWithChildren<{}>) => {
  const [state, setState] = useState<AuthenticatedUserContext>(undefined);

  const set: SetUser = value => {
    if (value) {
      Storage.setUser(value);
      return setState({
        type: value.beaches.length ? LayerType.Bcrs : LayerType.Csic,
        user: value,
      });
    }

    setState(false);
  };

  const clear: ClearUser = () => {
    Storage.clearUser();
    setState(false);
  };

  return (
    <AuthenticatedUserContext.Provider
      value={{user: state, set, clear}}
      {...props}>
      {children}
    </AuthenticatedUserContext.Provider>
  );
};

const useAuthenticatedUser = () => useContext(AuthenticatedUserContext);

export {AuthenticatedUserProvider, useAuthenticatedUser};
