import React from 'react';
import TextNode from '../../../../Text';
import {FrequentlyAskedQuestionAnswer} from '../../../../../graphql-types';
import styled from 'styled-components/native';
import {View} from 'react-native';

const StyledView = styled(View)`
  padding: 4px 8px;
`;

export function Text({answer}: {answer: FrequentlyAskedQuestionAnswer}) {
  const {value} = answer;
  return (
    <StyledView>
      <TextNode>{value.text}</TextNode>
    </StyledView>
  );
}
