import React from 'react';
import {
  ParameterValueFragment,
  NextParameterByValueFragment,
} from '../../../graphql-types';
import ListItemButton from '../../ListItemButton';
import Unchecked from '../../../assets/images/radio-unchecked.png';
import Checked from '../../../assets/images/radio-checked.png';

type ValueProps = {
  parameterValue: ParameterValueFragment & NextParameterByValueFragment;
  onPress: () => void;
  checked: boolean;
};

export default function Value({parameterValue, onPress, checked}: ValueProps) {
  return (
    <ListItemButton
      onPress={onPress}
      title={parameterValue.name}
      description={parameterValue.description}
      icon={checked ? Checked : Unchecked}
      iconLeft={{uri: parameterValue.icon ?? undefined}}
    />
  );
}
