import React, {useEffect, useState} from 'react';
import {BeachFragment} from '../../graphql-types';
import {ScrollView, Dimensions} from 'react-native';
import styled from 'styled-components/native';
import colors from '../../constants/colors';
import constants from '../../constants/constants';
import ForwardIcon from '../../assets/images/forward-icon.png';
import ListItemButton from '../ListItemButton';
import {useRoute, useNavigation} from '@react-navigation/native';
import {useLoading} from '../../hooks/useLoadingContext';
import * as Device from 'expo-device';

type BeachListProp = {
  beaches: BeachFragment[];
  onPress: () => void;
  searchText: string;
};

const Container = styled(ScrollView)<{isDesktop?: boolean}>`
  ${({isDesktop}) => {
    if (isDesktop) {
  		return `
  			background-color: ${colors.app.white};
 			margin-top: 5px;
  			max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
  		`;
  	}
  	
  	return `
  		background-color: ${colors.app.white};
 		margin-top: 5px;
  	`;
  	}}
`;

const Beach = styled(ListItemButton)`
  background-color: ${({zebra}: {zebra: boolean}) =>
    zebra ? colors.app.white : colors.app.zebraGray};
`;

export default function BeachList({beaches, onPress}: BeachListProp) {
	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);
  	const navigation = useNavigation();
  	const route = useRoute();
    
 	useEffect(() => {
		const getDeviceType = async () => {
	  		const type = await Device.getDeviceTypeAsync();
	  		setDeviceType(type);
		};

		getDeviceType();
	});

let index = 0;
  return (
    <Container isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      {beaches.map(beach => {
        index++;

        return (
          <Beach
            onPress={_ => {
              navigation.reset({
                index: 0,
                routes: [
                  {
                    name: 'Beach',
                    state: {
                      index: 0,
                      routes: [
                        {name: 'BeachScreen', params: {beach: beach.id}},
                      ],
                    },
                  },
                ],
              });
              onPress && onPress();
              navigation.navigate('Beach', {
                screen: 'BeachScreen',
                params: {beach: beach.id},
              });
            }}
            title={`${beach.name}`}
            description={beach.location}
            icon={ForwardIcon}
            key={beach.id}
            zebra={!!(index % 2)}
          />
        );
      })}
    </Container>
  );
}
