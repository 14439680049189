import * as React from 'react';
import ContentModal from '../components/content-modal';
import styled from 'styled-components/native';
import config from './config';
import Wizard from '../../components/bcrs/wizard';
import {useNavigation} from '@react-navigation/native';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
  z-index: 100000;
`;

export default function BCRSWizardScreen() {
  const navigation = useNavigation();

  return (
    <Screen
      title="Creating a Report"
      onCancel={() => {
        navigation.navigate('Map');
      }}>
      <Wizard />
    </Screen>
  );
}
