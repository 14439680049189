import React, {useEffect, useState} from 'react';
import {useReport} from '../../../hooks/useReportContext';
import {RouteProp, useRoute, useNavigation} from '@react-navigation/native';
import {RootStackParamList} from '../../../types';
import {ScrollView, View, Dimensions} from 'react-native';
import {Report} from '../../../storage/report';
import styled from 'styled-components/native';
import dateFormat from 'dateformat';
import ListItem from './ListItem';
import {useGetBeachQuery} from '../../../graphql-types';
import {useLoading} from '../../../hooks/useLoadingContext';
import ReportParameter from './ReportParameter';
import ListItemButton from '../../ListItemButton';
import colors from '../../../constants/colors';
import * as Device from 'expo-device';
import ForwardIcon from '../../../assets/images/forward-icon.png';
import constants from '../../../constants/constants';

export type BCRSReviewScreen = RouteProp<
  RootStackParamList,
  'BCRSReviewScreen'
>;

const Container = styled(View)`
  ${({isDesktop}) => {
    if (isDesktop) {
  		return `
 		 	display: flex;
  			flex-grow: 1;
			max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
  		`;
  	}
  	
  	return `
  			display: flex;
  			flex-grow: 1;
  			background: ${colors.app.white};
  	`;
  	}}
`;

const ScrollContainer = styled(ScrollView)`
  display: flex;
  flex-grow: 1;
`;

const NextButton = styled(ListItemButton)`
  bottom: 0;
  background-color: ${colors.app.white};
`;

export default function Review() {
  const navigation = useNavigation();
  const {get: getReport, submit: submitReport} = useReport();
  const {params} = useRoute<BCRSReviewScreen>();
  const reportKey = ['bcrs', params.beach, 'current'];
  const [report, setReport] = useState<Report | undefined>(undefined);
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading} = useGetBeachQuery({
    variables: {
      id: params.beach,
    },
  });

  useEffect(() => {
    if (loading) {
      setLoading();
    } else {
      clearLoading();
    }
  }, [loading]);

  useEffect(() => {
    let isSubscribed = true;

    const fetchReport = async () => {
      let report = await getReport(reportKey);

      if (isSubscribed) {
        setReport(report);
      }
    };

    fetchReport();

    return () => {
      isSubscribed = false;
    };
  }, [params]);

	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);

	useEffect(() => {
		const getDeviceType = async () => {
			const type = await Device.getDeviceTypeAsync();
			setDeviceType(type);
		};

		getDeviceType();
	});

  if (!report) {
    // @todo error check
    return null;
  }

  if (!data || !data.beach) {
    // @todo error check
    return null;
  }

  const publishReport = async () => {
    const result = await submitReport(reportKey);

    switch (result) {
      case 'success':
        navigation.reset({
          index: 0,
          routes: [{name: 'ReportSuccessScreen'}],
        });
        break;
      case 'pending':
        navigation.reset({
          index: 0,
          routes: [{name: 'ReportPendingScreen'}],
        });
        break;
      case 'failed':
      default:
        navigation.reset({
          index: 0,
          routes: [{name: 'ReportFailedScreen'}],
        });
    }
  };

  return (
    <Container isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      <NextButton
        title="Publish Report"
        icon={ForwardIcon}
        onPress={() => publishReport()}
      />
      <ScrollContainer>
        <ListItem title={'Beach'} values={[data.beach.name]} />
        <ListItem
          title={'Date'}
          values={[dateFormat(report.createdAt, 'mmmm dS, yyyy, h:MM:ss TT')]}
        />
        <ListItem title={'Pictures'} values={[`${report.photos.length}`]} />
        {report.reportParameters.map((reportParameter, idx) => {
          return (
            <ReportParameter
              key={idx}
              onPress={() => {
                navigation.navigate('BCRSWizardScreen', {
                  beach: params.beach,
                  parameter: reportParameter.parameterId,
                });
              }}
              reportParameter={reportParameter}
            />
          );
        })}
      </ScrollContainer>
    </Container>
  );
}
