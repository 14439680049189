import React, {useEffect, useState} from 'react';
import {Linking, Platform} from 'react-native';
import {getQueryVariable} from '../utils';
import {useAlertSubscription} from '../hooks/useAlertSubscriptionContext';
import {useGetAlertQuery} from '../graphql-types';
import {useLoading} from '../hooks/useLoadingContext';
import {useAuthenticatedUser} from '../hooks/useAuthenticatedUserContext';
import Storage from '../storage';

function LoadAlertSubscription({uuid}: {uuid: string}) {
  const {set: setSubcription} = useAlertSubscription();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading} = useGetAlertQuery({
    variables: {
      uuid,
    },
  });

  useEffect(() => {
    if (loading) {
      return setLoading();
    }

    clearLoading();
  }, [loading]);

  useEffect(() => {
    if (!data || !data.alert) {
      return;
    }

    setSubcription(data.alert);
  }, [data]);

  return null;
}

export default function AlertSubscriptionController() {
  const [uuid, setUuid] = useState<string | false>(false);
  const {user} = useAuthenticatedUser();

  useEffect(() => {
    const getAlertSubscription = async () => {
      const url = await Linking.getInitialURL();
      let uuid = getQueryVariable(url, 'uuid');

      if (uuid && Platform.OS !== 'web') {
        await Storage.saveAlertSubscription(uuid);
      }

      if (!uuid && user && user.user?.alert?.uuid) {
        uuid = user.user.alert.uuid;
      }

      if (!uuid && Platform.OS !== 'web') {
        uuid = await Storage.getAlertSubscription();
      }

      if (uuid) setUuid(uuid);
    };

    getAlertSubscription();
  }, []);

  const handleLink = async ({url}: {url: string}) => {
    const uuid = getQueryVariable(url, 'uuid');
    if (uuid) {
      if (uuid && Platform.OS !== 'web') {
        await Storage.saveAlertSubscription(uuid);
      }

      setUuid(uuid);
    }
  };

  useEffect(() => {
    Linking.addEventListener('url', handleLink);

    return () => {
      Linking.removeEventListener('url', handleLink);
    };
  });

  if (uuid) {
    return <LoadAlertSubscription uuid={uuid} />;
  }

  return null;
}
