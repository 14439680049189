import React, {useEffect} from 'react';
import {
  ParameterType,
  useGetParameterQuery,
  ParameterWithNextFragment,
} from '../../graphql-types';
import {useLoading} from '../../hooks/useLoadingContext';
import {
  Report,
  ReportParameter as ReportParameterType,
} from '../../storage/report';

import Select from './select';
import Image from './image';
import Multi from './multi';
import Range from './range';

export type StoreReportParameterValue = (
  reportParameter: ReportParameterType,
) => void;
export type NextParameter = (nextParameterId: string | undefined) => void;

export type ParameterTypeProps = {
  parameter: ParameterWithNextFragment;
  storeReportParameterValue: StoreReportParameterValue;
  nextParameter: NextParameter;
  reportParameter: ReportParameterType | undefined;
};

type ReportParameterProps = {
  parameterId: string;
  storeReportParameterValue: StoreReportParameterValue;
  nextParameter: NextParameter;
  report: Report | undefined;
};

const getReportParameter = (
  report: Report | undefined,
  parameterId: string,
): ReportParameterType | undefined => {
  if (!report) {
    return undefined;
  }

  return report.reportParameters.find(
    reportParameter => reportParameter.parameterId == parameterId,
  );
};

export default function ReportParameter({
  report,
  parameterId,
  storeReportParameterValue,
  nextParameter,
}: ReportParameterProps) {
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading, error} = useGetParameterQuery({
    fetchPolicy: 'cache-only',
    variables: {
      id: parameterId,
    },
  });

  useEffect(() => {
    if (loading) return setLoading();

    clearLoading();
  }, [loading]);

  if (!data) {
    return null;
  }

  const {parameter} = data;

  let ParameterComponent = (() => {
    switch (parameter.type) {
      case ParameterType.Image:
        return Image;
      case ParameterType.Multi:
        return Multi;
      case ParameterType.Range:
        return Range;
      case ParameterType.Select:
      default:
        return Select;
    }
  })();

  return (
    <ParameterComponent
      reportParameter={getReportParameter(report, parameterId)}
      nextParameter={nextParameter}
      storeReportParameterValue={storeReportParameterValue}
      parameter={parameter}
    />
  );
}
