import React, {useState} from 'react';
import TextBox from '../../TextBox';
import styled from 'styled-components/native';
import Value from './Value';
import {ParameterTypeProps} from '../';
import {View, Image, TouchableOpacity} from 'react-native';
import ArrowForward from '../../../assets/images/forward-arrow.png';
import ArrowBack from '../../../assets/images/arrow-back.png';
import DotIcon from '../../../assets/images/dot.png';
import DotIconWhite from '../../../assets/images/dot-white.png';
import {ParameterType} from '../../../graphql-types';

const Prompt = styled(TextBox)`
  padding-top: 20px;
  font-size: 18px;
  margin-bottom: 1px;
`;

const Description = styled(TextBox)`
  padding: 10px 10px 0 10px;
`;

const Selector = styled(View)`
  background-color: rgba(255, 255, 255, 0.5);
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 275px;
  flex-grow: 0;
`;

const Direction = styled(Image)`
  width: 32px;
  height: 32px;
  margin: 10px;
`;

const Dots = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  flex-grow: 0;
  padding-bottom: 20px;
`;

const Dot = styled(Image)`
  width: 8px;
  height: 8px;
  margin: 4px;
`;
export default function Select({
  parameter,
  storeReportParameterValue,
  nextParameter,
}: ParameterTypeProps) {
  const [index, setIndex] = useState(0);

  const parameterValue = parameter.parameterValues[index];
  let i = 0;

  return (
    <>
      {parameter.parameterCategory ? (
        <TextBox>{parameter.parameterCategory.name}</TextBox>
      ) : null}
      <Description>{parameter.description}</Description>
      <Prompt bold={true}>{parameter.prompt}</Prompt>
      <Selector>
        <TouchableOpacity
          onPress={_ =>
            setIndex(
              index >= 1 ? index - 1 : parameter.parameterValues.length - 1,
            )
          }>
          <Direction source={ArrowBack} />
        </TouchableOpacity>
        <Value
          key={parameterValue.id}
          parameterValue={parameterValue}
          onPress={() => {
            storeReportParameterValue({
              parameterId: parameter.id,
              nextParameterId:
                parameterValue.nextParameterByValue?.nextParameter.id ??
                parameter.nextParameter?.id,
              value: parameterValue.value,
              reportParameterValues: [
                {
                  parameterValueId: parameterValue.id,
                  value: parameterValue.value,
                },
              ],
            });
            nextParameter(
              parameterValue.nextParameterByValue?.nextParameter.id ??
                parameter.nextParameter?.id,
            );
          }}
        />
        <TouchableOpacity
          onPress={_ =>
            setIndex(
              index < parameter.parameterValues.length - 1 ? index + 1 : 0,
            )
          }>
          <Direction source={ArrowForward} />
        </TouchableOpacity>
      </Selector>
      <Dots>
        {parameter.parameterValues.map(() => (
          <Dot source={i == index ? DotIconWhite : DotIcon} key={i++} />
        ))}
      </Dots>
    </>
  );
}
