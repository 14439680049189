import {useEffect} from 'react';
import {useNetworkConnection} from '../hooks/useNetworkConnectionContext';
import Storage from '../storage';
import {useReport} from '../hooks/useReportContext';
import {useNotification} from '../hooks/useNotificationContext';

export default function PendingReportController() {
  const {active} = useNetworkConnection();
  const {get, submit} = useReport();
  const {set: setNotification} = useNotification();

  useEffect(() => {
    const uploadPendingReports = async () => {
      const keys = await Storage.getAllReportKeys();

      if (!keys) {
        return;
      }

      for (let i = 0; i < keys.length; i++) {
        const key = keys[i].split(':');
        key.shift();

        if (key[key.length - 1] == 'current') {
          // dont upload current
          continue;
        }

        if (!key || !key.length) {
          continue;
        }

        const report = await get(key);

        if (!report) {
          continue;
        }

        if (report.id) {
          continue;
        }

        setNotification({
          type: 'info',
          message: 'Uploading pending report.',
        });

        await submit(key);
      }
    };

    if (active) {
      uploadPendingReports();
    }
  }, [active]);

  return null;
}
