import React, {useEffect} from 'react';
import {
  ReportParametersValue,
  ReportParameter as ReportParameterType,
} from '../../../storage/report';
import ListItem from './ListItem';
import {useGetParameterQuery} from '../../../graphql-types';
import {useLoading} from '../../../hooks/useLoadingContext';
import {GestureResponderEvent} from 'react-native';

type ReportParametersValueProps = {
  reportParameter: ReportParameterType;
  onPress: (e: GestureResponderEvent) => void;
};

export default function ReportParameter({
  reportParameter,
  onPress,
}: ReportParametersValueProps) {
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading} = useGetParameterQuery({
    variables: {
      id: reportParameter.parameterId,
    },
  });

  useEffect(() => {
    if (loading) {
      setLoading();
    } else {
      clearLoading();
    }
  }, [loading]);

  if (!data) {
    return null;
  }

  if (
    !reportParameter ||
    !reportParameter.reportParameterValues ||
    reportParameter.reportParameterValues.length == 0
  ) {
    // @todo error check
    return null;
  }

  return (
    <ListItem
      onPress={onPress}
      title={data.parameter.prompt ?? data.parameter.name}
      values={
        reportParameter.value
          ? [reportParameter.value]
          : [
              ...reportParameter.reportParameterValues.map(
                value =>
                  data.parameter.parameterValues.find(
                    parameterValue =>
                      value.parameterValueId === parameterValue.id,
                  )?.name ?? '',
              ),
            ]
      }
    />
  );
}
