import AsyncStorage from '@react-native-async-storage/async-storage';
import {ReportableType, LocationInput} from '../graphql-types';
import {ImageResult} from '../components/bcrs/select-photos';

export type ReportKey = string[];

export type ReportParameterValue = {
  parameterValueId?: string;
  value?: string;
};

export type ReportParameter = {
  parameterId: string;
  value?: string;
  reportParameterValues?: ReportParameterValue[];
  nextParameterId?: string;
};

export type Report = {
  id?: string;
  createdAt: Date;
  publisedAt?: Date | undefined;
  completed: boolean;
  location: LocationInput;
  photos: ImageResult[];
  reportable: {
    type: ReportableType;
    id: string;
  };
  reportParameters: ReportParameter[];
};

const reISO = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
const reMsAjax = /^\/Date\((d|-|.*)\)[\/|\\]$/;

const dateParser = function (key: string, value: string) {
  if (typeof value === 'string') {
    let a = reISO.exec(value);
    if (a) return new Date(value);
    a = reMsAjax.exec(value);
    if (a) {
      let b = a[1].split(/[-+,.]/);
      return new Date(b[0] ? +b[0] : 0 - +b[1]);
    }
  }
  return value;
};

const setReport = async (key: ReportKey, report: Report) => {
  await AsyncStorage.setItem(
    ['report', ...key].join(':'),
    JSON.stringify(report),
  );
};

const getReport = async (key: ReportKey): Promise<Report | undefined> => {
  let report = await AsyncStorage.getItem(['report', ...key].join(':'));

  if (report) {
    return JSON.parse(report, dateParser);
  }

  return undefined;
};

const getAllReportKeys = async (): Promise<string[] | undefined> => {
  let keys = await AsyncStorage.getAllKeys();
  return keys.filter(key => key.startsWith('report'));
};
const validateReport = async (reportId: string, agrees: boolean) => {
  await AsyncStorage.setItem(
    ['report', reportId, 'validated'].join(':'),
    JSON.stringify(agrees),
  );
};

const hasValidatedReport = async (
  reportId: string,
): Promise<boolean | undefined> => {
  const result = await AsyncStorage.getItem(
    ['report', reportId, 'validated'].join(':'),
  );

  if (!result) {
    return undefined;
  }

  return JSON.parse(result);
};

export {
  getReport,
  setReport,
  getAllReportKeys,
  validateReport,
  hasValidatedReport,
};
