import React, {SetStateAction, useEffect, useState} from 'react';
import styled from 'styled-components/native';
import Text from '../../../Text';
import {View, TouchableOpacity, Image} from 'react-native';
import {
  ReportFragment as ReportFragmentType,
  useAgreeWithReportMutation,
  useDisagreeWithReportMutation,
} from '../../../../graphql-types';
import IAgreeIcon from '../../../../assets/images/i-agree-button.png';
import IDisagreeIcon from '../../../../assets/images/i-disagree-button.png';
import ArrowDownChevron from '../../../../assets/images/arrow-down-caret.png';
import colors from '../../../../constants/colors';
import dateFormat from 'dateformat';
import {useLoading} from '../../../../hooks/useLoadingContext';
import Storage from '../../../../storage';
import {agreeWithReportMutation, ReportFragment} from '../../../../graphql';

const Container = styled(TouchableOpacity)`
  background-color: ${colors.app.white}
  border-radius: 5px;
  margin-bottom: 5px;
  z-index: 10000000;
`;
const ReportDate = styled(Text)`
  padding: 5px 10px;
`;

const ReportContent = styled(View)`
  background-color: ${colors.mote.secondary.manateeGray};
`;
const ReportContentInfo = styled(View)`
  padding: 10px;
  border-radius: 5px;
`;

const ReportInfo = styled(View)`
  flex-grow: 1;
`;

const ReportInfoTitle = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ReportInfoTitleText = styled(Text)`
  font-weight: bold;
`;

const ReportInfoParameterValuesText = styled(Text)``;

const Chevron = styled(Image)`
  width: 24px;
  height: 24px;
`;

const ReportInfoDescription = styled(Text)`
  color: ${colors.mote.secondary.sharkGray};
  font-size: 11px;
`;

const ReportInfoValidation = styled(Text)`
  font-size: 11px;
`;

const ValidationOptions = styled(View)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colors.app.white};
`;

const ValidationOptionButton = styled(TouchableOpacity)`
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 5px 5px 5px;
`;

const ValidationOptionButtonText = styled(Text)`
  padding-left: 10px;
`;
const ValidationIcon = styled(Image)`
  width: 25px;
  height: 20px;
`;

const ValidationText = styled(Text)`
  padding: 5px;
`;

type ReportProps = {
  report: ReportFragmentType;
  selected: boolean;
  setSelected: React.Dispatch<SetStateAction<string | null>>;
};

function whoAgrees(count: number) {
  if (count === 0) {
    return 'No one agrees';
  }

  if (count === 1) {
    return `${count} person agrees`;
  }

  return `${count} people agree`;
}

function whoDisagrees(count: number) {
  if (count === 0) {
    return 'No one disagrees';
  }

  if (count === 1) {
    return `${count} person disagrees`;
  }

  return `${count} people disagree`;
}

export default function Report({report, setSelected, selected}: ReportProps) {
  const {set: setLoading, clear: clearLoading} = useLoading();
  const date = dateFormat(new Date(report.createdAt), 'dddd h:MM TT');
  const [agreed, setAgreed] = useState<boolean | undefined>(undefined);

  const input = {
    variables: {
      input: {
        reportId: report.id,
      },
    },
  };

  const [agree] = useAgreeWithReportMutation(input);
  const [disagree] = useDisagreeWithReportMutation(input);

  useEffect(() => {
    const getValidation = async () => {
      const result = await Storage.hasValidatedReport(report.id);

      if (typeof result === 'undefined') {
        return;
      }

      setAgreed(result);
    };

    getValidation();
  }, [report]);

  const first = report.reportParameters.find(report => report.parameter.first);
  if (!first) {
    // @todo check error here, probably bad parameter creation
    return null;
  }

  const reportParameters = report.reportParameters.filter(
    reportParameter => reportParameter.parameter.id !== first.parameter.id,
  );

  return (
    <Container key={report.id} onPress={() => setSelected(report.id)}>
      <ReportDate>{date}</ReportDate>
      <ReportContent>
        <ReportContentInfo>
          <ReportInfoTitle>
            <ReportInfo>
              <ReportInfoTitleText>{first.parameter.name}</ReportInfoTitleText>
              <ReportInfoParameterValuesText>
                {first.parameterValues
                  .map(parameterValue => parameterValue.name)
                  .join(', ')}
              </ReportInfoParameterValuesText>
            </ReportInfo>
            {!selected ? <Chevron source={ArrowDownChevron} /> : null}
          </ReportInfoTitle>
          {selected
            ? reportParameters &&
              reportParameters.length > 0 &&
              reportParameters.map(reportParameter => (
                <ReportInfoDescription key={reportParameter.parameter.id}>
                  {reportParameter.parameterValues
                    .map(parameterValue => parameterValue.name)
                    .join(', ')}
                </ReportInfoDescription>
              ))
            : null}
          {selected ? (
            <ReportInfoValidation>
              {whoAgrees(report.agree)}, {whoDisagrees(report.disagree)}
            </ReportInfoValidation>
          ) : null}
        </ReportContentInfo>
        {selected ? (
          <ValidationOptions>
            {typeof agreed === 'undefined' ? (
              <>
                <ValidationOptionButton
                  onPress={async _ => {
                    setLoading();
                    const {data} = await agree({
                      update(cache, {data}) {
                        if (
                          data?.agreeWithReport?.success &&
                          data?.agreeWithReport.report
                        ) {
                          cache.writeFragment({
                            data: data.agreeWithReport.report,
                            fragment: ReportFragment,
                            id: `ReportFragment:${report.id}`,
                            fragmentName: 'Report',
                          });
                        }
                      },
                    });

                    if (data?.agreeWithReport?.success) {
                      await Storage.validateReport(report.id, true);
                      setAgreed(true);
                    }

                    clearLoading();
                  }}>
                  <ValidationIcon source={IAgreeIcon} />
                  <ValidationOptionButtonText>
                    I Agree
                  </ValidationOptionButtonText>
                </ValidationOptionButton>
                <ValidationOptionButton
                  onPress={async _ => {
                    setLoading();
                    const {data} = await disagree({
                      update(cache, {data}) {
                        if (
                          data?.disagreeWithReport?.success &&
                          data?.disagreeWithReport.report
                        ) {
                          cache.writeFragment({
                            data: data.disagreeWithReport.report,
                            fragment: ReportFragment,
                            id: `ReportFragment:${report.id}`,
                            fragmentName: 'Report',
                          });
                        }
                      },
                    });

                    if (data?.disagreeWithReport?.success) {
                      await Storage.validateReport(report.id, false);
                      setAgreed(false);
                    }

                    clearLoading();
                  }}>
                  <ValidationIcon source={IDisagreeIcon} />
                  <ValidationOptionButtonText>
                    I Disagree
                  </ValidationOptionButtonText>
                </ValidationOptionButton>
              </>
            ) : (
              <ValidationText>
                {agreed
                  ? 'You have agreed with this report.'
                  : 'You have disagreed with this report.'}
              </ValidationText>
            )}
          </ValidationOptions>
        ) : null}
      </ReportContent>
    </Container>
  );
}
