import * as React from 'react';
import VideosIcon from '../../assets/images/videos-icon.png';
import styled from 'styled-components/native';
import Videos from '../../components/education/videos';
import Text from '../../components/Text';
import {TouchableOpacity} from 'react-native';
import YouTube from 'react-youtube';
import {RouteProp, useNavigation, useRoute} from '@react-navigation/native';
import YouTubeVideo from '../../components/YouTubeVideo';
import {EducationStackParamList} from '../../types';

const Screen = styled(TouchableOpacity)`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
`;

type VideoScreenRouteProps = RouteProp<EducationStackParamList, 'Video'>;

export default function VideoScreen() {
  const navigation = useNavigation();
  const {params} = useRoute<VideoScreenRouteProps>();

  return (
    <Screen
      onPress={() => {
        if (navigation.canGoBack()) {
          return navigation.goBack();
        }

        navigation.reset({
          index: 0,
          routes: [
            {
              name: 'Root',
              state: {
                index: 0,
                routes: [{name: 'Map'}],
              },
            },
          ],
        });
      }}>
      <YouTubeVideo id={params.video} />
    </Screen>
  );
}
