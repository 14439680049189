import React from 'react';
import {Image, Platform, TouchableOpacity} from 'react-native';
import {Infographic as InfographicType} from '../../../graphql-types';
import styled from 'styled-components/native';
import colors from '../../../constants/colors';
import ListItemButton from '../../ListItemButton';
import ArrowForward from '../../../assets/images/forward-arrow.png';
import * as WebBrowser from 'expo-web-browser';

const Container = styled(TouchableOpacity)`
  background-color: ${colors.mote.secondary.manteeGray};
`;

const Thumbnail = styled(Image)`
  width: 100%;
  height: ${Platform.OS == 'web' ? '20vh' : '180px'};
`;

export default function Infographic({
  infographic,
}: {
  infographic: InfographicType;
}) {
  const onPress = ({infographic}: {infographic: InfographicType}) => {
    if (Platform.OS === 'web') {
      window.open(infographic.url, '_blank');
    } else {
      WebBrowser.openBrowserAsync(infographic.url);
    }
  };

  return (
    <Container onPress={() => onPress({infographic})}>
      <Thumbnail source={{uri: infographic.thumbnailUrl}} />
      <ListItemButton
        title={infographic.name}
        icon={ArrowForward}
        onPress={() => onPress({infographic})}
      />
    </Container>
  );
}
