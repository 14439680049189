import React, {useEffect, useState} from 'react';
import {ScrollView, View, Image} from 'react-native';
import styled from 'styled-components/native';
import PendingIcon from '../assets/images/report-pending-icon.png';
import Text from './Text';
import * as Device from 'expo-device';

const Container = styled(ScrollView)`
  ${({isDesktop}) => {
    if (isDesktop) {
  		return `
  			flex: 1;
  			justify-content: center;
 			align-items: center;
  			padding: 30px;
			height: ${Dimensions.get('window').height - constants.heightReduce}px;
  		`;
  	}
  	
  	return `
  		display: flex;
  		flex-grow: 1;
  		justify-content: center;
  		align-items: center;
  		padding: 30px;
  		background: ${colors.app.white};
  	`;
  	}}
`;

const Icon = styled(Image)`
  width: 60px;
  height: 60px;
`;

const Title = styled(Text)`
  font-size: 18px;
  text-align: center;
`;

const Content = styled(Text)`
  text-align: center;
  margin: 10px 0;
`;

const Thanks = styled(Text)`
  font-weight: bold;
`;

export default function ReportPending() {
	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);

	useEffect(() => {
		const getDeviceType = async () => {
			const type = await Device.getDeviceTypeAsync();
			setDeviceType(type);
		};

		getDeviceType();
	});

  return (
    <Container isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      <Icon source={PendingIcon} />
      <Title>Report Pending</Title>
      <Content>
        Once reconnected your report will be published. Check the status on the
        Create Report screen.
      </Content>
      <Thanks>Thank you!</Thanks>
    </Container>
  );
}
