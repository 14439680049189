import React from 'react';
import styled from 'styled-components/native';
import Text from './Text';
import {StyleProp, TextStyle} from 'react-native';

const StyledTextBox = styled(Text)<{bold?: boolean}>`
  padding: 10px;
  font-weight: ${({bold}: {bold: boolean}) => (bold ? 'bold' : 'normal')};
  display: flex;
  width: 100%;
  border: none;
`;

export default function TextBox({
  children,
  bold = false,
  style,
}: React.PropsWithChildren<{bold?: boolean; style?: StyleProp<TextStyle>}>) {
  return (
    <StyledTextBox bold={bold} style={style}>
      {children}
    </StyledTextBox>
  );
}
