import React, {useState, useEffect} from 'react';
import {useMutation} from '@apollo/client';
import {sendContactMessageMutation} from '../../graphql';
import {SendContactMessageMutation} from '../../graphql-types';
import Form from './Form';
import Success from './Success';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useNavigation} from '@react-navigation/native';
import {useLoading} from '../../hooks/useLoadingContext';
import styled from 'styled-components/native';
import {ScrollView, Dimensions} from 'react-native';
import constants from '../../constants/constants';
import colors from '../../constants/colors';

const Container = styled(ScrollView)`
  ${({isDesktop}) => {
    if (isDesktop) {
  		return `
 		 	display: flex;
  			flex-grow: 1;
			max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
  		`;
  	}
  	
  	return `
  			display: flex;
  			flex-grow: 1;
  	`;
  	}}
`;

const schema = yup.object().shape({
  name: yup.string().required('Please include your name'),
  email: yup.string().email().required('Please include your email'),
  emailVerified: yup
    .string()
    .oneOf([yup.ref('email')], 'Please retype your email')
    .required('Please verify your email'),
  message: yup.string().required('Please include a message'),
});

export type FormInputs = {
  name: string;
  email: string;
  emailVerified: string;
  message: string;
};

export default function Contact() {
  const navigation = useNavigation();
  const [sent, setSent] = useState<boolean>(false);
  const {set: setLoading, clear: clearLoading} = useLoading();

  const [sendContactMessage] = useMutation<SendContactMessageMutation>(
    sendContactMessageMutation,
  );
  const form = useForm<FormInputs>({
    resolver: yupResolver(schema),
  });

  const {setError, setValue, clearErrors} = form;

  const clearState = () => {
    setValue('name', '');
    setValue('email', '');
    setValue('message', '');
    setValue('emailVerified', '');
    setSent(false);
    clearErrors();
  };

  useEffect(() => {
    navigation.addListener('blur', _ => clearState());

    return () => {
      navigation.removeListener('blur', _ => clearState());
    };
  }, []);

  const onSubmit = async ({
    name,
    email,
    message,
    emailVerified,
  }: FormInputs) => {
    setLoading();
    try {
      const {data} = await sendContactMessage({
        variables: {
          input: {
            name,
            email,
            message,
            emailVerified,
          },
        },
      });

      if (data?.sendContactMessage?.success) {
        clearLoading();
        setSent(true);
      }
    } catch ({graphQLErrors}) {
      clearLoading();
      const validation = graphQLErrors.find(({extensions: {category}}) => {
        return category === 'validation';
      }).extensions.validation;
      for (const error in validation) {
        const [_, field] = error.split('.');
        if (!['name', 'email', 'emailVerified', 'message'].includes(field)) {
          continue;
        }
        setError(field as 'name' | 'email' | 'message' | 'emailVerified', {
          type: 'manual',
          message: validation[error][0],
        });
      }
    }
  };

  return (
    <Container>
      {!sent ? <Form onSubmit={onSubmit} {...form} /> : null}
      {sent ? <Success /> : null}
    </Container>
  );
}
