import * as React from 'react';
import {
  useNavigationBuilder,
  DefaultNavigatorOptions,
  StackRouter,
  StackRouterOptions,
  StackNavigationState,
  createNavigatorFactory,
  StackActionHelpers,
} from '@react-navigation/native';
import MapScreen from '../screens/MapScreen';

// Props accepted by the view
type DesktopNavigationConfig = {};

// Supported screen options
type DesktopNavigationOptions = {
  title?: string;
};

// Map of event name and the type of data (in event.data)
//
// canPreventDefault: true adds the defaultPrevented property to the
// emitted events.
type DesktopNavigationEventMap = {};

// The props accepted by the component is a combination of 3 things
type Props = DefaultNavigatorOptions<DesktopNavigationOptions> &
  StackRouterOptions &
  DesktopNavigationConfig;

type ParamListBase = {};

function DesktopNavigator({children, screenOptions, initialRouteName}: Props) {
  const {state, navigation, descriptors} = useNavigationBuilder<
    StackNavigationState<ParamListBase>,
    StackRouterOptions,
    StackActionHelpers<ParamListBase>,
    DesktopNavigationOptions,
    DesktopNavigationEventMap
  >(StackRouter, {
    children,
    screenOptions,
    initialRouteName,
  });

  return (
    <>
      <MapScreen />
      {descriptors[state.routes[state.index].key].render()}
    </>
  );
}

export default createNavigatorFactory<
  StackNavigationState<ParamListBase>,
  DesktopNavigationOptions,
  DesktopNavigationEventMap,
  typeof DesktopNavigator
>(DesktopNavigator);
