import React from 'react';
import {TouchableOpacity, ImageSourcePropType} from 'react-native';

import * as S from './styles';

import BeachStock from '../../assets/images/beach-stock.jpeg';
import CancelIcon from '../../assets/images/cancel-icon.png';
import {useNavigation} from '@react-navigation/native';
import ReportPending from '../../components/ReportPending';

export default function ReportPendingScreen() {
  const navigation = useNavigation();

  return (
    <>
      <S.Header>
        <S.Title>Pending</S.Title>
        <TouchableOpacity
          onPress={_ => {
            navigation.navigate('Root', {screen: 'Map'});
          }}>
          <S.Cancel source={CancelIcon as ImageSourcePropType} />
        </TouchableOpacity>
      </S.Header>
      <S.Container>
        <S.Background source={BeachStock as ImageSourcePropType}>
          <ReportPending />
        </S.Background>
      </S.Container>
    </>
  );
}
