import React, {useEffect} from 'react';
import styled from 'styled-components/native';
import {View, Dimensions, ScrollView} from 'react-native';
import ListItemButton from '../../ListItemButton';
import ArrowForward from '../../../assets/images/forward-arrow.png';
import {useGetFrequentlyAskedQuestionsCategoriesQuery} from '../../../graphql-types';
import {useLoading} from '../../../hooks/useLoadingContext';
import {useNavigation} from '@react-navigation/native';
import constants from '../../../constants/constants';

const Container = styled(ScrollView)`
  	display: flex;
  	flex-grow: 1;
  	background-color: "white";
`;


export default function FrequentlyAskedQuestionsCategories() {
  const navigation = useNavigation();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {data, loading} = useGetFrequentlyAskedQuestionsCategoriesQuery();

  useEffect(() => {
    if (loading) {
      return setLoading();
    }

    clearLoading();
  }, [loading]);

  if (!data || !data.frequentlyAskedQuestionCategories) {
    return null;
  }

  return (
    <Container>
      {data.frequentlyAskedQuestionCategories.map(category => (
        <ListItemButton
          onPress={() => {
            navigation.navigate('FrequentlyAskedQuestions', {
              category: category?.id,
            });
          }}
          icon={ArrowForward}
          title={category?.name}
          description={category?.description}
          key={category.id}
        />
      ))}
    </Container>
  );
}
