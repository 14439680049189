import React, {useContext, PropsWithChildren, useState} from 'react';

type SetLayerSelection = (layerId: string) => void;

const LayerSelectionContext = React.createContext<{
  layer: string;
  set: SetLayerSelection;
}>({
  layer: '2',
  set: _ => {},
});

const LayerSelectionProvider = ({
  children,
  ...props
}: PropsWithChildren<{}>) => {
  const [state, setState] = useState<string>('2');
  // @todo getDefaultLayer API call, set to 2 for now

  const set: SetLayerSelection = value => {
    setState(value);
  };

  return (
    <LayerSelectionContext.Provider value={{layer: state, set}} {...props}>
      {children}
    </LayerSelectionContext.Provider>
  );
};

const useLayerSelection = () => useContext(LayerSelectionContext);

export {LayerSelectionProvider, useLayerSelection};
