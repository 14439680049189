import React, {useEffect, useState} from 'react';
import {View, Image, TouchableOpacity} from 'react-native';
import {useLayerSelection} from '../../hooks/useLayerSelectionContext';
import styled from 'styled-components/native';
import Text from '../components/Text';
import LegendInfo from './LegendInfo';
import BeachMapMarkerIcon from '../assets/images/map-marker-blue.png';
import LayerMapMarkerIcon from '../assets/images/map-marker-lightblue.png';
import * as Device from 'expo-device';
import constants from '../constants/constants';
import colors from '../constants/colors';
import HelpIcon from '../assets/images/help-icon.png';


const Legend = styled(View)<{isDesktop?: boolean}>`
  ${({isDesktop}) => {
    if (!isDesktop) {
      return `
    	position: absolute;
        bottom: 45px;
		left: ${constants.mapOverlaysLeftMargin};
       	z-index: 1000;
      	border-radius: 5px;
        box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
      	border: 1px solid ${colors.mote.primary.powderBlue};
      `;
    }

    return `
      position: absolute;
      top: 105px;
      left: ${constants.mapOverlaysLeftMargin};
      padding: 2px;
      background-color: ${colors.mote.primary.powderBlue};
      border-radius: 5px;
      box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
      border: 1px solid ${colors.mote.primary.powderBlue};
    `;
  }}
`;

const LegendTitle = styled(Text)`
	align-items: left
	padding: 5px
	color: ${colors.app.white};
`;

const LegendInner = styled(View)`
	background-color: ${colors.mote.primary.lightBlue};
	border-radius: 2px;
`;

const LegendKey = styled(TouchableOpacity)`
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const LegendIcon = styled(Image)`
  width: 16px;
  height: 16px;
`;

const LegendText = styled(Text)`
  flex-grow: 1;
  padding-left: 5px;
`;

const LegendInfoIcon = styled(Image)`
  width: 15px;
  height: 15px;
  margin-left: 5px;
  margin-bottom: 5px;
`;


export default function LegendBox() {
	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);
	const [showInfo, setShowInfo] = useState<string>("");

	function clickedItem(item: string) {
		if (showInfo == item) {
			setShowInfo("");
		} else {
			setShowInfo(item);
		}
	}
	
	useEffect(() => {
		const getDeviceType = async () => {
	  		const type = await Device.getDeviceTypeAsync();
	  		setDeviceType(type);
		};

		getDeviceType();
	});
	
	return (
	  <>
	  <Legend isDesktop={deviceType == Device.DeviceType.DESKTOP}>
		{ deviceType == Device.DeviceType.DESKTOP ? (<LegendTitle>Legend</LegendTitle>) : null}
		<LegendInner>
			<LegendKey onPress={() => clickedItem("beach")}>
			  <LegendIcon source={BeachMapMarkerIcon} />
			  <LegendText>Beach Ambassador Report</LegendText>
			  <LegendInfoIcon source={HelpIcon} />
			</LegendKey>
			
			<LegendKey onPress={() => clickedItem("layer")}>
			  <LegendIcon source={LayerMapMarkerIcon} />
			  <LegendText>Community Report</LegendText>
			  <LegendInfoIcon source={HelpIcon} />
			</LegendKey>
		</LegendInner>
	  </Legend>
	  {showInfo ? ( 
	  	<LegendInfo info={showInfo} setShowInfo={setShowInfo}/>
	  ) : null}
	  </>
	);
}
