import React, {useEffect, useState} from 'react';
import {View, Image} from 'react-native';
import styled from 'styled-components';
import HelpIcon from '../assets/images/help-icon.png';
import CancelIcon from '../assets/images/cancel-icon.png';
import * as Device from 'expo-device';
import colors from '../constants/colors';

const Container = styled.div`
  position: absolute;
  left: 50%;
  width: 320px;
  top: 5%;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  transform: translate(-50%, 50%);
  background-color: #fff;
  padding: 5px 10px 10px 10px;
`;

const Info = styled.div`
  margin-bottom: 10px;
`;

const InfoNameBox = styled.div`
  display: flex;
  flex-direction: row;
  font-family: tablet-gothic;
  padding-bottom: 10px;
`;

const InfoNameText = styled.div`
  flex-grow: 1;
  font-size: 18px;
  font-family: tablet-gothic;
`;
const InfoDescription = styled.div`
  font-family: tablet-gothic;
`;

const InfoIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 2px;
`;

const CloseIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 10px;
`;

const CloseText = styled.div`
  flex-grow: 1;
  text-align: right;
  font-family: tablet-gothic;
`;

const CloseButton = styled.a`
  display: flex;
  flex-direction: row;
  text-direction: none;
`;

export default function LegendInfo({
	info,
	setShowInfo,
}: {
	info: string;
	setShowInfo: (value: string | "") => void
})  {

	const [deviceType, setDeviceType] = useState<Device.DeviceType | false>(
		false,
	);

	useEffect(() => {
		const getDeviceType = async () => {
	  		const type = await Device.getDeviceTypeAsync();
	  		setDeviceType(type);
		};

		getDeviceType();
	});

	function name(info: string) {
		if (info == "beach") {
			return "Beach Ambassador Report"
		} else if (info == "layer") {
			return "Community Report";
		} else {
			return "Name not found."
		}
	}

	function description(info: string) {
		if (info == "beach") {
			return "Beach Ambassador Reports are submitted by trained volunteers. These reports include information based on the volunteer’s observations of their reporting area, as well as quantitative information provided through external weather APIs."
		} else if (info == "layer") {
			return "Community Reports are submitted by untrained members of the general public. These reports enable anyone to submit simplified reports of their observations from anywhere at any time.";
		} else {
			return "Description not found."
		}
	}

  return (
    <Container isDesktop={deviceType == Device.DeviceType.DESKTOP}>
      <CloseButton
        href="#"
        onClick={e => {
          e.preventDefault();
          setShowInfo("");
        }}>
        <CloseText></CloseText>
        <CloseIcon src={CancelIcon} />
      </CloseButton>
      
      <Info>
        <InfoNameBox>
          <InfoIcon src={HelpIcon} />
          <InfoNameText>{ name(info) }</InfoNameText>
        </InfoNameBox>
        <InfoDescription>{ description(info) }</InfoDescription>
      </Info>
    </Container>
  );
} 
