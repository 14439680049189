import {useAuthenticatedUser} from '../hooks/useAuthenticatedUserContext';
import {useEffect} from 'react';
import {useAllParametersLazyQuery} from '../graphql-types';
import {useLoading} from '../hooks/useLoadingContext';

export default function ParametersController() {
  const {user} = useAuthenticatedUser();
  const [getAllParameters, {loading}] = useAllParametersLazyQuery();
  const {set: setLoading, clear: clearLoading} = useLoading();

  useEffect(() => {
    if (loading) {
      setLoading();
      return;
    }

    clearLoading();
  }, [loading]);

  useEffect(() => {
    if (user) {
      getAllParameters();
    }
  }, [user]);

  return null;
}
