import gql from 'graphql-tag';
import {ImageAttachmentFragment} from './image-attachment';
import {ParameterFragment} from './parameter';

export const StateFragment = gql`
  fragment State on State {
    name
    abbreviation
    latitude
    longitude
  }
`;

export const CountyFragment = gql`
  fragment County on County {
    name
    latitude
    longitude
    state {
      ...State
    }
  }
  ${StateFragment}
`;

export const CityFragment = gql`
  fragment City on City {
    name
    latitude
    longitude
    county {
      ...County
    }
    state {
      ...State
    }
  }
  ${CountyFragment}
  ${StateFragment}
`;

export const BeachFragment = gql`
  fragment Beach on Beach {
    id
    name
    website
    logo
    latitude
    longitude
    amenities {
      description
      link
    }
    imageAttachments(orderBy: [{column: CREATED_AT, order: DESC}]) {
      ...ImageAttachment
    }
    city {
      ...City
    }
    location
  }
  ${CityFragment}
  ${ImageAttachmentFragment}
`;

export const FirstParameterFragment = gql`
  fragment FirstParameter on Beach {
    firstParameter {
      ...Parameter
    }
  }
  ${ParameterFragment}
`;
