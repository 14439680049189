import gql from 'graphql-tag';

export const getFrequentlyAskedQuestionCategoriesQuery = gql`
  query GetFrequentlyAskedQuestionsCategories {
    frequentlyAskedQuestionCategories {
      id
      name
      description
    }
  }
`;

export const getFrequentlyAskedQuestionsQuery = gql`
  query GetFrequentlyAskedQuestions($frequentlyAskedQuestionCategory: ID!) {
    frequentlyAskedQuestions(
      frequentlyAskedQuestionCategory: $frequentlyAskedQuestionCategory
    ) {
      id
      question
      answers {
        id
        type
        value {
          text
          link
          bullet
        }
      }
    }
  }
`;

export const getInfographicsQuery = gql`
  query GetInfographics {
    infographics {
      id
      name
      thumbnailUrl
      url
    }
  }
`;
