import React, {useState} from 'react';
import Header from '../components/header';
import styled from 'styled-components/native';
import FloatingActionButton from '../../components/FloatingActionButton';
import CreateReportIcon from '../../assets/images/create-report-icon-blue.png';
import SelectLayerIcon from '../../assets/images/select-layer-icon.png';
import UserAuthenticated from '../../assets/images/user-authenticated-background.png';
import {View, Image} from 'react-native';
import {useNavigation} from '@react-navigation/native';
import Text from '../../components/Text';
import Button from '../../components/Button';
import {useAuthenticatedUser} from '../../hooks/useAuthenticatedUserContext';
import {useToken} from '../../hooks/useTokenContext';
import {useGraphqlCache} from '../../hooks/useGraphqlCacheContext';
import MappedReports from '../../components/mapped-reports';
import {useLocation} from '../../hooks/useLocationContext';
import DefaultLocation from '../../constants/location';
import {useLayerSelection} from '../../hooks/useLayerSelectionContext';
import SelectLayer from '../../components/select-layer';
import constants from '../../constants/constants';
import colors from '../../constants/colors';
import Linking from 'react-native';
import LogoBox from '../../components/LogoBox';
import LegendBox from '../../components/LegendBox';


//	Login button
const CreateReportButton = styled(FloatingActionButton)`
  position: absolute;
  top: 215px;
  left: ${constants.mapOverlaysLeftMargin};
  width: 220px;
  height: 40px;
`;

const SelectLayerButton = styled(FloatingActionButton)`
  position: absolute;
  top: 265px;
  left: ${constants.mapOverlaysLeftMargin};
  width: 280px;
  height: 40px;
`;

const SelectLayerContainer = styled(View)`
  position: absolute;
  left: 40%;
  width: 320px;
  top: 10%;
`;

//	Logout button
const UserBox = styled(View)`
  position: absolute;
  bottom: 30px;
  right: 70px;
  width: 250;
  height: 64px;
  background-image: url(${UserAuthenticated});
  border-radius: 5px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  padding: 0 15px;
`;

const LogoView = styled(View)`
  position: absolute;
  bottom: 20px;
  left: 20px;
`;

const UserName = styled(Text)`
  display: block;
  margin: 5px 0;
`;

export default function MapScreen() {
  const navigation = useNavigation();
  const {persistor} = useGraphqlCache();
  const {clear: clearToken} = useToken();
  const {user, clear: clearUser} = useAuthenticatedUser();
  const {location} = useLocation();
  const {layer} = useLayerSelection();
  const [selectLayer, setSelectLayer] = useState<boolean>(false);

  return (
    <>
      <Header />
      <MappedReports
        location={
          location || {
            latitude: DefaultLocation.latitude,
            longitude: DefaultLocation.longitude,
          }
        }
        layer={layer}
      />
      {user ? (
        <UserBox>
          <div>
            <UserName>Hello {user.user.name}</UserName>
            <Button
              onPress={e => {
                e.preventDefault();
                clearUser();
                clearToken();
                persistor?.purge();
              }}>
              Logout
            </Button>
          </div>
        </UserBox>
      ) : null}
      <CreateReportButton
        icon={CreateReportIcon}
        title="Login / Create a Report"
        onPress={() => navigation.navigate('CreateReport')}
      />
      <SelectLayerButton
        icon={SelectLayerIcon}
        title="Filter Community Reports by Layer"
        onPress={_ => setSelectLayer(!selectLayer)}
      />
      {selectLayer ? (
        <SelectLayerContainer>
          <SelectLayer onClose={() => setSelectLayer(false)} />
        </SelectLayerContainer>
      ) : null}
      <LogoView>
      	<LogoBox />
      </LogoView>
      <LegendBox />
    </>
  );
}
