import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as React from 'react';
import {RootStackParamList} from '../../types';
import Linking from './Linking';

const Stack = createStackNavigator<RootStackParamList>();

export default function Navigation({
  navigator,
}: {
  navigator: React.FunctionComponent | React.ComponentClass;
}) {
  return (
    <NavigationContainer linking={Linking}>
      <Stack.Navigator screenOptions={{headerShown: false}}>
        <Stack.Screen name="Root" component={navigator} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
