import React, {useContext, PropsWithChildren} from 'react';
import {CachePersistor} from 'apollo3-cache-persist';
import {NormalizedCacheObject} from '@apollo/client';

type Persistor = CachePersistor<NormalizedCacheObject> | undefined;

type GraphqlCacheProviderProps = {
  persistor: Persistor;
};

type GraphqlCacheContext = {
  persistor: Persistor;
};

const GraphqlCacheContext = React.createContext<GraphqlCacheContext>({
  persistor: undefined,
});

const GraphqlCacheProvider = ({
  children,
  persistor,
}: PropsWithChildren<GraphqlCacheProviderProps>) => {
  return (
    <>
      <GraphqlCacheContext.Provider value={{persistor}}>
        {children}
      </GraphqlCacheContext.Provider>
    </>
  );
};

const useGraphqlCache = () => useContext(GraphqlCacheContext);

export {GraphqlCacheProvider, useGraphqlCache};
