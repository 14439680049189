import React, {useState} from 'react';
import {View} from 'react-native';
import ArrowForward from '../../../../assets/images/forward-arrow.png';
import ArrowDown from '../../../../assets/images/arrow-down.png';
import ListItemButton from '../../../ListItemButton';
import {
  FrequentlyAskedQuestion,
  FrequentlyAskedQuestionAnswerType,
} from '../../../../graphql-types';
import * as Answer from './answers';
import styled from 'styled-components/native';

const AnswerView = styled(View)`
  background-color: rgb(255, 255, 255)
  padding: 10px;
`;

export default function Question({
  question,
}: {
  question?: FrequentlyAskedQuestion;
}) {
  const [show, setShow] = useState(false);

  return (
    <View>
      <ListItemButton
        onPress={() => setShow(!show)}
        icon={show ? ArrowDown : ArrowForward}
        title={question?.question}
      />
      {show ? (
        <AnswerView>
          {question?.answers.map(answer => {
            switch (answer.type) {
              case FrequentlyAskedQuestionAnswerType.Text:
                return <Answer.Text answer={answer} />;
              case FrequentlyAskedQuestionAnswerType.Link:
                return <Answer.Link answer={answer} />;
              case FrequentlyAskedQuestionAnswerType.Bullet:
                return <Answer.Bullet answer={answer} />;
              default:
                return <Answer.Text answer={answer} />;
            }
          })}
        </AnswerView>
      ) : null}
    </View>
  );
}
