import React, {PropsWithChildren} from 'react';
import {
  Pressable,
  PressableProps,
  ImageSourcePropType,
  Image,
  Text,
} from 'react-native';
import styled from 'styled-components/native';

const PressableFab = styled(Pressable)`
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  elevation: 5;
`;

const Icon = styled(Image)`
  width: 20px;
  height: 20px;
  margin-left: 10px;
`;

const TitleText = styled(Text)`
  font-size: 14px;
  margin-left: 20px;
`;

type FloatingActionButtonProps = PropsWithChildren<{
  icon: ImageSourcePropType;
  title: string;
}> &
  PressableProps;

export default function FloatingActionButton({
  children,
  icon,
  title,
  ...props
}: FloatingActionButtonProps) {
  return (
	<PressableFab {...props}>
	  <Icon source={icon} />
	  <TitleText>{title}</TitleText>
	</PressableFab>
  );
}
