import React, {useEffect, useState} from 'react';
import {Image, View} from 'react-native';
import Constants from 'expo-constants';
import Text from '../../components/Text';
import styled from 'styled-components/native';
import {useNavigation} from '@react-navigation/native';
import {useLoading} from '../../hooks/useLoadingContext';
import VideoPlayer from './Video';
import colors from '../../constants/colors';


const {google} = Constants.manifest.extra;

const Container = styled(View)`
  display: flex;
  background-color: ${colors.app.white};
`;

const Video = styled(View)`
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  flex-direction: column;
  padding: 5px 5px 0 5px;
  align-items: center;
`;

const VideoContent = styled(View)`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

const VideoTitle = styled(Text)`
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  padding-bottom: 5px;
`;

const VideoDescription = styled(Text)`
  width: 100%;
  flex-grow: 1;
`;

const NoVideosText = styled(Text)`
  text-align: center;
  padding: 50px 0;
`;

export default function Videos() {
  const {set: setLoading, clear: clearLoading} = useLoading();
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    setLoading();

    const getVideos = async () => {
      let response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?key=${google.youtubeApiKey}&playlistId=PLlEtdiUrTQAI9sBiZNz5pQjiYh3DgYbel&part=contentDetails,id,snippet`,
      );

      let body = await response.json();
      setVideos(body.items);
      clearLoading();
    };

    getVideos();
  }, []);

  if (!videos || videos.length == 0) {
    return (
      <Container>
        <NoVideosText>No videos available.</NoVideosText>
      </Container>
    );
  }

  console.log(videos);

  return (
    <Container>
      {videos.map(video => {
        let {contentDetails, snippet} = video;
        return (
          <Video key={contentDetails.videoId}>
            <VideoPlayer id={contentDetails.videoId} />
            <VideoContent>
              <VideoTitle>
                {snippet.title.length > 45
                  ? snippet.title.substr(0, 45) + '...'
                  : snippet.title}
              </VideoTitle>
              <VideoDescription>{snippet.description}</VideoDescription>
            </VideoContent>
          </Video>
        );
      })}
    </Container>
  );
}
