import React from 'react';
import {View} from 'react-native';
import styled from 'styled-components/native';
import Text from '../../components/Text';
import {
  NotificationType,
  Notification as INotification,
} from '../../hooks/useNotificationContext';
import colors from '../../constants/colors';

type ContainerProps = {
  top?: number;
  type?: NotificationType;
};

const Container = styled(View)<ContainerProps>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  background-color: ${({type}) => {
    switch (type) {
      case 'info':
        return colors.mote.secondary.manateeGray;
      case 'error':
        return colors.app.error;
      case 'success':
        return colors.app.success;
    }
  }};
  padding: 20px;
  padding-top: ${({top}) => (top ? `${top}` : '20')}px;
  text-align: center;
`;

const Message = styled(Text)<ContainerProps>`
  color: ${({type}) => {
    switch (type) {
      case 'info':
        return 'black';
      case 'error':
        return 'white';
      case 'success':
        return 'white';
    }
  }};
`;

export default function Notification({
  notification,
}: {
  notification: INotification;
}) {
  return (
    <Container type={notification.type}>
      <Message type={notification.type}>{notification.message}</Message>
    </Container>
  );
}
