import React from 'react';
import styled from 'styled-components';
import colors from '../../../constants/colors';
import MoteLogoHeader from '../../../assets/images/logos/mote-white-logo.png';
import Navigation from './Navigation';
import Search from '../../../components/search';
import {View} from 'react-native';

const HeaderContainer = styled.div`
  display: flex;
  border-left: 10px solid ${colors.mote.secondary.coral};
  background-color: ${colors.mote.primary.blue};
  font-family: 'tablet-gothic';
  height: 85px;
  color: ${colors.app.white};
`;

const MoteLogo = styled.img`
  padding: 18px 6px 0px 12px;
  height: 50px;
`;

const Title = styled.div`
  padding: 16px 0 6px 0;
  font-size: 26px;
  text-transform: uppercase;
`;

const RightSide = styled.div`
  display: flex;
  border-left: 10px solid ${colors.mote.secondary.coral};
  background-color: ${colors.app.white};
  height: 85px;
`;

const SearchBox = styled(View)`
	height: 85px;
`;

export default function Header() {
  return (
    <HeaderContainer>
      <MoteLogo src={MoteLogoHeader} />
      <Title>
        <span>Beach Conditions</span> <br />
        <span>Reporting System</span>
      </Title>
      <div style={{flexGrow: 1}}></div>
      <RightSide> 
	    <SearchBox>
		  <Search />
		</SearchBox>
        <Navigation />
      </RightSide>
    </HeaderContainer>
  );
}
