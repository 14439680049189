import React from 'react';
import {TextInput as TextInputRN, TextInputProps, Platform} from 'react-native';
import styled from 'styled-components/native';

const SearchBoxTextInput = styled(TextInputRN)`
  border-color: #fff;
  padding: 6px;

  &:focus-visible,
  &:focus {
    border: none;
    outline: none;
  }
`;

export default function TextInput({style, ...props}: TextInputProps) {
  return (
    <SearchBoxTextInput
      style={[Platform.OS === 'web' ? {outline: 'none'} : {}, style]}
      {...props}
    />
  );
}
