import React, { useContext, PropsWithChildren, useState } from 'react';

export type UserLocation = {
  latitude: number
  longitude: number
} | false;

type SetLocation = (value: UserLocation) => void;
type ClearLoaction = () => void;

const LocationContext = React.createContext<{
  location?: UserLocation;
  set: SetLocation;
  clear: ClearLoaction;
}>({
  clear: () => { },
  set: _ => { },
});

const LocationProvider = ({ children, ...props }: PropsWithChildren<{}>) => {
  const [state, setState] = useState<
    UserLocation | undefined
  >(undefined);

  const set: SetLocation = value => {
    setState(value);
  };

  const clear: ClearLoaction = () => {
    setState(false);
  };

  return (
    <LocationContext.Provider value={{ location: state, set, clear }} {...props}>
      {children}
    </LocationContext.Provider>
  );
};

const useLocation = () => useContext(LocationContext);

export { LocationProvider, useLocation };
