import React from 'react';
import { Picker as ExpoPicker } from '@react-native-community/picker';
import styled from 'styled-components/native';
import { Platform } from 'react-native';

const StyledPicker = styled(ExpoPicker)`
  background: transparent;
  ${Platform.select({ web: `border: none;` })}
  width: 100px;
`;

type PickerProps = {
  min: number;
  max: number;
  unit?: string | null;
  selected: number;
  onValueChange: (value: React.ReactText, index: number) => void;
};

export default function Picker({
  max,
  min,
  unit,
  onValueChange,
  selected,
}: PickerProps) {
  let items = [];

  for (let i = min; i <= max; i++) {
    items.push(<ExpoPicker.Item key={i} label={`${i} ${unit}`} value={i} />);
  }
  return (
    <StyledPicker selectedValue={selected} onValueChange={onValueChange}>
      {items}
    </StyledPicker>
  );
}
