import React from 'react';
import styled from 'styled-components';
import AlertsIcon from '../../../assets/images/nav-alerts-icon.svg';
import ResourcesIcon from '../../../assets/images/nav-resources-icon.svg';
import ContactIcon from '../../../assets/images/nav-contact-icon.svg';
import EducationIcon from '../../../assets/images/nav-education-icon.svg';
import {useNavigation} from '@react-navigation/native';
import colors from '../../../constants/colors';

const NavigationContainer = styled.div`
  display: flex;
  height: 85px;
  margin-bottom: -20px;
  z-index: 10;
  background: ${colors.app.white};
  margin-right: 15px;
`;

const Button = styled.a`
  text-decoration: none;
  display: block;
  height: 86px;
  color: ${colors.mote.secondary.sharkGray};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 121px;
`;

const Icon = styled.img`
  display: block;
  padding-bottom: 3px;
`;

const IconForEducation = styled.img`
  display: block;
  padding-bottom: 5px;
`;

export default function Navigation() {
  const navigation = useNavigation();

  return (
    <NavigationContainer>
      <Button
        href="#"
        onClick={e => {
          e.preventDefault();
          navigation.reset({
            index: 0,
            routes: [{name: 'Alerts'}],
          });
        }}>
        <Icon src={AlertsIcon} />
        Alerts
      </Button>
      <Button
        href="#"
        onClick={e => {
          e.preventDefault();
          navigation.reset({
            index: 0,
            routes: [{name: 'Resources'}],
          });
        }}>
        <Icon src={ResourcesIcon} />
        Resources
      </Button>
      <Button
        href="#"
        onClick={e => {
          e.preventDefault();
          navigation.reset({
            index: 0,
            routes: [{name: 'Contact'}],
          });
        }}>
        <Icon src={ContactIcon} />
        Contact
      </Button>
      <Button
        href="#"
        onClick={e => {
          e.preventDefault();
          navigation.reset({
            index: 0,
            routes: [{name: 'Education'}],
          });
        }}>
        <IconForEducation src={EducationIcon} />
        Education
      </Button>
    </NavigationContainer>
  );
}
