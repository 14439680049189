import * as React from 'react';
import ContentModal from '../components/content-modal';
import CreateReportIcon from '../../assets/images/create-report-icon.png';
import styled from 'styled-components/native';
import config from './config';
import Text from '../../components/Text';
import ReportSuccess from '../../components/ReportSuccess';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
  z-index: 100000;
`;

export default function ReportSuccessScreen() {
  return (
    <Screen title="Success" icon={CreateReportIcon}>
      <ReportSuccess />
    </Screen>
  );
}
