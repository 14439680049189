import React from 'react';
import styled from 'styled-components';
import HelpIcon from '../../assets/images/help-icon.png';
import CancelIcon from '../../assets/images/cancel-icon.png';

const Container = styled.div`
  position: absolute;
  left: 50%;
  width: 400px;
  bottom: 50%;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  transform: translate(-50%, 50%);
  background-color: #fff;
  padding: 5px 10px 10px 10px;
`;

const Parameter = styled.div`
  margin-bottom: 10px;
`;

const ParameterName = styled.div`
  display: flex;
  flex-direction: row;
  font-family: tablet-gothic;
`;

const ParameterNameText = styled.div`
  flex-grow: 1;
  font-size: 18px;
  font-family: tablet-gothic;
`;
const ParameterDescription = styled.div`
  font-family: tablet-gothic;
`;

const ParameterValue = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const ParameterValueName = styled.div`
  font-weight: bold;
  margin-right: 5px;
  font-size: 18px;
  font-family: tablet-gothic;
`;

const ParameterValueDescription = styled.div`
  font-family: tablet-gothic;
`;

const InfoIcon = styled.img`
  width: 15px;
  height: 15px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-top: 2px;
`;

const CloseIcon = styled.img`
  width: 18px;
  height: 18px;
  margin-left: 10px;
`;

const CloseText = styled.div`
  flex-grow: 1;
  text-align: right;
  font-family: tablet-gothic;
`;

const CloseButton = styled.a`
  display: flex;
  flex-direction: row;
  text-direction: none;
`;

type ParameterValueInfo = {
  name: string;
  description?: string | null;
};

export type IParameterInfo = {
  name: string;
  description?: string | null;
  values: ParameterValueInfo[];
};

export default function ParameterInfo({
  parameterInfo,
  setParameterInfo,
}: {
  parameterInfo: IParameterInfo;
  setParameterInfo: (value: IParameterInfo | false) => void;
}) {
  let {name, description, values} = parameterInfo;
  return (
    <Container>
      <CloseButton
        href="#"
        onClick={e => {
          e.preventDefault();
          setParameterInfo(false);
        }}>
        <CloseText></CloseText>
        <CloseIcon src={CancelIcon} />
      </CloseButton>
      <Parameter>
        <ParameterName>
          <InfoIcon src={HelpIcon} />
          <ParameterNameText>{name}</ParameterNameText>
        </ParameterName>
        {description ? (
          <ParameterDescription>{description}</ParameterDescription>
        ) : null}
      </Parameter>
      {values.map(value => (
        <ParameterValue key={value.name}>
          <ParameterValueName>{value.name}</ParameterValueName>
          {value.description ? (
            <ParameterValueDescription>
              {value.description}
            </ParameterValueDescription>
          ) : null}
        </ParameterValue>
      ))}
    </Container>
  );
}
