import gql from 'graphql-tag';

export const ParameterValueFragment = gql`
  fragment ParameterValue on ParameterValue {
    id
    name
    description
    value
    imagePath
    icon
  }
`;

const NextParameterByValueFragment = gql`
  fragment NextParameterByValue on ParameterValue {
    nextParameterByValue {
      nextParameter {
        ...Parameter
      }
    }
  }
`;

export const ParameterCategoryFragment = gql`
  fragment ParameterCategory on ParameterCategory {
    id
    name
    icon
    description
    slug
    order
  }
`;

export const ParameterFragment = gql`
  fragment Parameter on Parameter {
    id
    name
    icon
    prompt
    description
    type
    rangeMin
    rangeMax
    unit
    first
    parameterCategory {
      ...ParameterCategory
    }
    parameterValues {
      ...ParameterValue
    }
  }
  ${ParameterCategoryFragment}
  ${ParameterValueFragment}
`;

export const ParameterWithNextFragment = gql`
  fragment ParameterWithNext on Parameter {
    ...Parameter
    parameterCategory {
      ...ParameterCategory
    }
    parameterValues {
      ...ParameterValue
      ...NextParameterByValue
    }
    nextParameter {
      ...Parameter
    }
  }
  ${ParameterFragment}
  ${ParameterCategoryFragment}
  ${ParameterValueFragment}
  ${NextParameterByValueFragment}
  ${ParameterFragment}
`;

export const ParameterCategoryFirstParameterFragment = gql`
  fragment ParameterCategoryFirstParameter on ParameterCategory {
    first {
      ...Parameter
    }
  }
`;
