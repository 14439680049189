
// App specific colors
const app = {
	white: '#FFFFFF',
	black: '#000000',
	error: '#FF5733',
	success: '#3D6111',
	zebraGray: '#f2f2f2',
};
	
// Mote official colors
const mote = {
	primary: {
		blue: '#0054a6',				// Primary palette 
		darkBlue: '#023f88',
		powderBlue: '#4a83c4',
		lightBlue: '#badef5',
		seafoamWhite: '#f4f4f4',
	},

	secondary: {
		coral: '#f37163',				// Secondary palette colors
		brightBlue: '#00aae7',
		manateeGray: '#b1b3b3',
		sharkGray: '#63666a',
		black: '#000000'
	},

	tertiary: {
		seagrassGreen: '#85b034',		// Tertiary palette
		mangroveGreen: '#007b41',
		estuaryGreen: '#638a63',
	}
};

const tabBar = {
	activeTintColor: app.white,
	inactiveTintColor: mote.primary.lightBlue,
	activeBackgroundColor: mote.primary.blue,
	inactiveBackgroundColor: mote.primary.blue,
	labelStyle: {
		marginBottom: 5,
	},
};



export default {
  tabBar: {
  	...tabBar,
  },
  mote: {
  	...mote,
  },
  app: {
  	...app,
  }
};
