import React, {useState, useEffect} from 'react';
import ForwardIcon from '../../../assets/images/forward-icon.png';
import RefreshIcon from '../../../assets/images/refresh-pending.png';
import TextBox from '../../../components/TextBox';
import {Field} from '../../shared/styles';
import {View, Linking} from 'react-native';
import styled from 'styled-components/native';
import ListItemButton from '../../ListItemButton';
import {useRoute, RouteProp} from '@react-navigation/native';
import {AlertsStackParamList} from '../../../types';
import {
  useSubscribeToAlertsMutation,
  useVerifyEmailMutation,
} from '../../../graphql-types';
import {useLoading} from '../../../hooks/useLoadingContext';
import {useNotification} from '../../../hooks/useNotificationContext';
import {useAlertSubscription} from '../../../hooks/useAlertSubscriptionContext';
import Storage from '../../../storage';
import {getQueryVariable} from '../../../utils';

const Container = styled(View)`
  display: flex;
  flex-grow: 1;
`;

export type AlertsVerifyEmailRouteProps = RouteProp<
  AlertsStackParamList,
  'AlertsVerifyEmailScreen'
>;

export default function VerifyEmail() {
  const {params} = useRoute<AlertsVerifyEmailRouteProps>();
  const [subscribeToAlerts] = useSubscribeToAlertsMutation();
  const [verifyEmail] = useVerifyEmailMutation();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const {set: setNotification} = useNotification();
  const [code, setCode] = useState<string>('');
  const {set: setSubcription} = useAlertSubscription();

  const email = decodeURIComponent(params.email);

  useEffect(() => {
    const getCode = async () => {
      const url = await Linking.getInitialURL();
      if (url) {
        const code = getQueryVariable(url, 'code');
        if (code) setCode(code);
      }
    };

    getCode();
  }, []);

  const resubscribe = async () => {
    setLoading();
    const {data} = await subscribeToAlerts({
      variables: {
        input: {
          email,
        },
      },
    });

    if (data?.subscribeToAlerts.success) {
      setNotification({
        type: 'success',
        message: 'Verification email sent.',
      });
    }

    clearLoading();
  };

  const submit = async () => {
    setLoading();

    try {
      const {data} = await verifyEmail({
        variables: {
          input: {
            email,
            verificationCode: code,
          },
        },
      });

      if (data?.verifyEmail?.success) {
        if (data.verifyEmail.alert) {
          await Storage.saveAlertSubscription(data.verifyEmail.alert.uuid);
          setSubcription(data.verifyEmail.alert);
          setNotification({
            type: 'success',
            message: 'Email verified.',
          });
        }

        // @todo error checking, provde some user feedback maybe?
      }
    } catch (e) {
      setNotification({
        type: 'error',
        message: 'Email could not be verified. Please try again.',
      });
    }

    clearLoading();
  };

  return (
    <Container>
      <TextBox>Please enter the code from your email.</TextBox>
      <TextBox bold={true}>Verification Code</TextBox>
      <Field
        placeholder="12345678"
        onChangeText={setCode}
        value={code}
        keyboardType="numeric"
      />
      <ListItemButton
        title="Verify Email"
        icon={ForwardIcon}
        onPress={() => submit()}
      />
      <ListItemButton
        title="Resend Email"
        icon={RefreshIcon}
        onPress={() => resubscribe()}
      />
      <View style={{flexGrow: 1}} />
    </Container>
  );
}
