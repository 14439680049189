import React, {useState, useEffect} from 'react';
import TextBox from '../../../components/TextBox';
import {View} from 'react-native';
import styled from 'styled-components/native';
import {useAlertSubscription} from '../../../hooks/useAlertSubscriptionContext';
import CheckedIcon from '../../../assets/images/checkbox-checked.png';
import UncheckedIcon from '../../../assets/images/checkbox-unchecked.png';
import ListItemButton from '../../ListItemButton';
import {
  useSubscribeToBeachMutation,
  useUnsubscribeFromBeachMutation,
  BeachFragment,
} from '../../../graphql-types';
import {useNotification} from '../../../hooks/useNotificationContext';
import {useLoading} from '../../../hooks/useLoadingContext';

const Container = styled(View)`
  display: flex;
  flex-grow: 1;
`;

export default function BeachAlerts() {
  const {subscription, set: setSubscription} = useAlertSubscription();
  const [subscribeToBeach] = useSubscribeToBeachMutation();
  const [unsubscribeFromBeach] = useUnsubscribeFromBeachMutation();
  const {set: setNotificaiton} = useNotification();
  const {set: setLoading, clear: clearLoading} = useLoading();

  if (!subscription) {
    // should be able to get here
    return null;
  }

  const handleToggle = async (isSubscribed: boolean, beachId: string) => {
    setLoading();
    if (!isSubscribed) {
      const {data} = await subscribeToBeach({
        variables: {
          input: {
            uuid: subscription.uuid,
            beachId,
          },
        },
      });

      if (data?.subscribeToBeach.success && data.subscribeToBeach.alert) {
        setSubscription(data.subscribeToBeach.alert);
        setNotificaiton({
          type: 'success',
          message: 'Subscribed to beach alert.',
        });
      }
    } else {
      const {data} = await unsubscribeFromBeach({
        variables: {
          input: {
            uuid: subscription.uuid,
            beachId,
          },
        },
      });

      if (
        data?.unsubscribeFromBeach.success &&
        data.unsubscribeFromBeach.alert
      ) {
        setSubscription(data.unsubscribeFromBeach.alert);
        setNotificaiton({
          type: 'success',
          message: 'Unsubscribed from beach alert.',
        });
      }
    }
    clearLoading();
  };

  return (
    <Container>
      <TextBox>Manage your beach alerts for</TextBox>
      <TextBox bold={true}>{subscription.email}</TextBox>
      {subscription.beaches.map(beach => (
        <ListItemButton
          key={beach.id}
          title={beach.name}
          onPress={() => {
            handleToggle(
              !!subscription.beaches.find(search => search.id === beach.id),
              beach.id,
            );
          }}
          icon={
            subscription.beaches.find(search => search.id === beach.id)
              ? CheckedIcon
              : UncheckedIcon
          }
        />
      ))}
      {subscription.beaches.length === 0 ? (
        <TextBox>
          Search for a beach to subscribe and receive beach alerts in your
          email.
        </TextBox>
      ) : null}
    </Container>
  );
}
