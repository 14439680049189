import React, {useEffect} from 'react';
import ContentModal from '../components/content-modal';
import MailIcon from '../../assets/images/mail-icon.png';
import styled from 'styled-components/native';
import config from './config';
import TextBox from '../../components/TextBox';
import {useNavigation} from '@react-navigation/native';
import Storage from '../../storage';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
`;

const StyledTextBox = styled(TextBox)`
  padding: 10px 20px;
`;

export default function WelcomeScreen() {
  const navigation = useNavigation();

  useEffect(() => {
    const checkWelcomeScreenStatus = async () => {
      const hasSeenWelcomeScreen = await Storage.hasSeenWelcomeScreen();

      if (hasSeenWelcomeScreen) {
        navigation.navigate('Map');
      }

      await Storage.saveHasSeenWelcomeScreen();
    };

    checkWelcomeScreenStatus();
  }, []);

  return (
    <Screen title="Welcome" icon={null}>
      <StyledTextBox>
        This service offers beach conditions information as reported by trained volunteers, 
        known as Beach Ambassadors. It aims to guide and inform the public about conditions 
        at participating locations. Please note that conditions can change throughout the day, 
        and these changes may not always be reflected in the reports.
      </StyledTextBox>
      <StyledTextBox>
        Many of our volunteers are lifeguards and beach/park personnel whose primary 
        responsibility is swimmer safety. Therefore, if a report is slightly outdated, 
        it may be because the volunteer for that location is attending to other priorities 
        and has not yet updated the information.
      </StyledTextBox>
    </Screen>
  );
}
