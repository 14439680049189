import React, {useEffect, useState} from 'react';
import {Image, TouchableOpacity, View, ScrollView, Dimensions} from 'react-native';
import {useLoading} from '../../../hooks/useLoadingContext';
import Constants from 'expo-constants';
import Text from '../../Text';
import styled from 'styled-components/native';
import {useNavigation} from '@react-navigation/native';
import constants from '../../../constants/constants';
import colors from '../../../constants/colors';

const {google} = Constants.manifest.extra;

const Container = styled(ScrollView)`
  	display: flex;
  	flex-grow: 1;
	max-height: ${Dimensions.get('window').height - constants.heightReduce}px;
	background-color: ${colors.app.white};
`;

const Video = styled(TouchableOpacity)`
  background-color: ${colors.app.white};
  display: flex;
  flex-direction: row;
  padding: 5px 5px 0 5px;
  align-items: center;
`;

const VideoContent = styled(View)`
  display: flex;
  flex-direction: col;
  align-items: center;
  width: 66%;
  padding: 10px;
`;

const VideoTitle = styled(Text)`
  font-weight: bold;
  font-size: 14px;
  width: 100%;
  background-color: ${colors.app.white};
`;

const VideoDescription = styled(Text)`
  width: 100%;
  background-color: ${colors.app.white};
`;

const Photo = styled(Image)`
  width: 33%;
  height: 100px;
`;

const NoVideosLabel = styled(Text)`
  padding: 12px;
`;

export default function Videos() {
  const navigation = useNavigation();
  const {set: setLoading, clear: clearLoading} = useLoading();
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    setLoading();

    const getVideos = async () => {
      let response = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?key=${google.mapsApiKey}&playlistId=PLlEtdiUrTQAI9sBiZNz5pQjiYh3DgYbel&part=contentDetails,id,snippet`,
      );

      let body = await response.json();
      setVideos(body.items);
      clearLoading();
    };

    getVideos();
  }, []);

  if (!videos || videos.length == 0) {
    return (
      <Container>
        <NoVideosLabel>No videos available.</NoVideosLabel>
      </Container>
    );
  }

  return (
    <Container>
      {videos.map(video => {
        let {contentDetails, snippet} = video;
        return (
          <Video
            key={video.id}
            onPress={() =>
              navigation.navigate('Video', {video: contentDetails.videoId})
            }>
            <Photo source={{uri: snippet.thumbnails.high.url}} />
            <VideoContent>
              <VideoTitle>{snippet.title}</VideoTitle>
              <VideoDescription>{snippet.description}</VideoDescription>
            </VideoContent>
          </Video>
        );
      })}
    </Container>
  );
}
