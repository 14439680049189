import React from 'react';
import TextBox from '../../TextBox';
import styled from 'styled-components/native';
import Value from './Value';
import {ParameterTypeProps} from '..';
import colors from '../../../constants/colors';

const Prompt = styled(TextBox)`
  padding-top: 20px;
  font-size: 18px;
  margin-bottom: 1px;
  background: ${colors.app.white};
`;

const Description = styled(TextBox)`
  background: ${colors.app.white};
  padding-bottom: 0;
`;

export default function Select({
  parameter,
  storeReportParameterValue,
  nextParameter,
  reportParameter,
}: ParameterTypeProps) {
  return (
    <>
      {parameter.parameterCategory ? (
        <Description>{parameter.parameterCategory.name}</Description>
      ) : null}
      {parameter.description ? (
        <Description>{parameter.description}</Description>
      ) : null}
      <Prompt bold={true}>{parameter.prompt ?? parameter.name}</Prompt>
      {parameter.parameterValues.map(parameterValue => (
        <Value
          checked={
            !!(
              reportParameter &&
              reportParameter.reportParameterValues &&
              reportParameter.reportParameterValues.find(
                value =>
                  value.parameterValueId &&
                  value.parameterValueId === parameterValue.id,
              )
            )
          }
          parameterValue={parameterValue}
          key={parameterValue.id}
          onPress={() => {
            storeReportParameterValue({
              parameterId: parameter.id,
              nextParameterId:
                parameterValue.nextParameterByValue?.nextParameter.id ??
                parameter.nextParameter?.id,
              reportParameterValues: [
                {
                  parameterValueId: parameterValue.id,
                  value: parameterValue.value,
                },
              ],
            });
            nextParameter(
              parameterValue.nextParameterByValue?.nextParameter.id ??
                parameter.nextParameter?.id,
            );
          }}
        />
      ))}
    </>
  );
}
