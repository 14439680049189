import React, {useContext, PropsWithChildren, useState, useEffect} from 'react';
import {UserLocation, useLocation} from './useLocationContext';

type SetMapLocation = (value: UserLocation) => void;

const MapLocationContext = React.createContext<{
  location?: UserLocation;
  set: SetMapLocation;
}>({
  set: _ => {},
});

const MapLocationProvider = ({children, ...props}: PropsWithChildren<{}>) => {
  const {location} = useLocation();
  const [state, setState] = useState<UserLocation | undefined>(undefined);

  const set: SetMapLocation = value => {
    setState(value);
  };

  useEffect(() => {
    setState(location);
  });

  return (
    <MapLocationContext.Provider value={{location: state, set}} {...props}>
      {children}
    </MapLocationContext.Provider>
  );
};

const useMapLocation = () => useContext(MapLocationContext);

export {MapLocationProvider, useMapLocation};
