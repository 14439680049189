import * as Token from './token';
import * as User from './user';
import * as Report from './report';
import AsyncStorage from '@react-native-async-storage/async-storage';

const saveHasSeenWelcomeScreen = async () => {
  await AsyncStorage.setItem('@has-seen-welcome-screen', 'true');
};

const hasSeenWelcomeScreen = async () => {
  const result = await AsyncStorage.getItem('@has-seen-welcome-screen');
  return JSON.parse(result ?? 'false');
};

const saveAlertSubscription = async (uuid: string) => {
  await AsyncStorage.setItem('alert-subscription', uuid);
};

const getAlertSubscription = async () => {
  return await AsyncStorage.getItem('alert-subscription');
};

const saveAppleSignInInfo = async (info: {name: string; email: string}) => {
  await AsyncStorage.setItem('apple-sigin', JSON.stringify(info));
};

const getAppleSignInInfo = async () => {
  const info = await AsyncStorage.getItem('apple-sigin');

  if (!info) {
    return undefined;
  }

  return JSON.parse(info);
};

export default {
  ...Token,
  ...Report,
  ...User,
  saveHasSeenWelcomeScreen,
  hasSeenWelcomeScreen,
  saveAlertSubscription,
  getAlertSubscription,
  getAppleSignInInfo,
  saveAppleSignInInfo,
};
