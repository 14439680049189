import React from 'react';
import {ImageSourcePropType, View} from 'react-native';
import styled from 'styled-components/native';

import BeachReportIcon from '../../assets/images/beach-report-icon.png';
import BeachPicturesIcon from '../../assets/images/beach-pictures-icon.png';
import BeachAmenitiesIcon from '../../assets/images/beach-amenities-icon.png';
import ArrowForward from '../../assets/images/forward-arrow.png';
import ListItemButton from '../ListItemButton';
import {BeachStackParamList} from '../../types';
import {useNavigation} from '@react-navigation/native';
import Subscription from '../Subscription';

type TabKeys = 'reports' | 'pictures' | 'amenities';

type TabsProps = {
  beachId: string;
  reportId?: string;
  hasPhotos?: boolean;
  hasAmenities?: boolean;
};

type Tab = {
  name: string;
  key: TabKeys;
  icon: ImageSourcePropType;
  screen: keyof BeachStackParamList;
};

type Tabs = Tab[];

const Container = styled(View)`
  display: flex;
  flex-direction: column;
`;

export default function Tabs({
  beachId,
  reportId,
  hasPhotos,
  hasAmenities,
}: TabsProps) {
  const navigation = useNavigation();

  return (
    <Container>
      {reportId ? (
        <ListItemButton
          onPress={() =>
            navigation.navigate('BeachReportScreen', {
              beach: beachId,
              report: reportId,
            })
          }
          title="Reports"
          iconLeft={BeachReportIcon}
          icon={ArrowForward}
        />
      ) : null}
      {hasPhotos ? (
        <ListItemButton
          onPress={() => {
            navigation.navigate('BeachPhotosScreen', {
              beach: beachId,
            });
          }}
          title="Pictures"
          iconLeft={BeachPicturesIcon}
          icon={ArrowForward}
        />
      ) : null}
      {hasAmenities ? (
        <ListItemButton
          onPress={() => {
            navigation.navigate('BeachAmenitiesScreen', {
              beach: beachId,
            });
          }}
          title="Amenities"
          iconLeft={BeachAmenitiesIcon}
          icon={ArrowForward}
        />
      ) : null}
      <Subscription beachId={beachId} />
    </Container>
  );
}
