import React from 'react';
import Text from '../../../../Text';
import {FrequentlyAskedQuestionAnswer} from '../../../../../graphql-types';
import {Platform, View} from 'react-native';
import styled from 'styled-components/native';
import colors from '../../../../../constants/colors';

const BulletSymbol = styled(Text)`
  font-size: ${Platform.OS == 'web' ? '4px' : '8px'};
  padding-right: 8px;
  padding-top: 3px;
`;

const BulletStyle = styled(View)`
  padding: 4px 0;
  flex-direction: row;
  padding-left: 15px;
  display: flex;
  align-items: flex-start;
`;

const BulletText = styled(Text)`
  color: ${colors.mote.secondary.sharkGray};
`;

export function Bullet({answer}: {answer: FrequentlyAskedQuestionAnswer}) {
  const {value} = answer;
  return (
    <BulletStyle>
      <BulletSymbol>{'\u2B24'}</BulletSymbol>
      <BulletText>{value.bullet}</BulletText>
    </BulletStyle>
  );
}
