import React, {
  useContext,
  PropsWithChildren,
  useState,
  SetStateAction,
} from 'react';
import {AlertFragment} from '../graphql-types';

type SubscriptionType = AlertFragment | false | undefined;

type AlertSubscriptionContext = {
  subscription: AlertFragment | false | undefined;
  set: React.Dispatch<SetStateAction<SubscriptionType>>;
};

const AlertSubscriptionContext = React.createContext<AlertSubscriptionContext>({
  subscription: false,
  set: _ => {},
});

const AlertSubscriptionProvider = ({children}: PropsWithChildren<{}>) => {
  const [subscription, setSubscription] = useState<SubscriptionType>(undefined);

  return (
    <>
      <AlertSubscriptionContext.Provider
        value={{subscription, set: setSubscription}}>
        {children}
      </AlertSubscriptionContext.Provider>
    </>
  );
};

const useAlertSubscription = () => useContext(AlertSubscriptionContext);

export {AlertSubscriptionProvider, useAlertSubscription};
