import React from 'react';
import styled from 'styled-components/native';
import config from './config';
import Beach from '../../components/beach';
import {useNavigation} from '@react-navigation/native';
import ContentModal from '../components/content-modal';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
  display: flex;
  flex-direction: column;
`;

export default function BeachScreen() {
  return (
    <Screen title={'Beach'}>
      <Beach />
    </Screen>
  );
}
