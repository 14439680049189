import React, {PropsWithChildren, useEffect, useState, ReactNode} from 'react';
import {ApolloProvider} from '@apollo/client';
import {AuthenticatedUserProvider} from '../hooks/useAuthenticatedUserContext';
import {TokenProvider, useToken} from '../hooks/useTokenContext';
import {ErrorProvider} from '../hooks/useErrorContext';
import {LoadingProvider} from '../hooks/useLoadingContext';
import TokenController from './TokenController';
import AuthenticatedUserController from './AuthenticatedUserController';
import {LocationProvider} from '../hooks/useLocationContext';
import LocationController from './LocationController';
import {NotificationProvider} from '../hooks/useNotificationContext';
import useCachedResources from '../hooks/useCachedResources';
import {GraphqlCacheProvider} from '../hooks/useGraphqlCacheContext';
import {NetworkConnectionProvider} from '../hooks/useNetworkConnectionContext';
import {ReportProvider} from '../hooks/useReportContext';
import setupGraphQL from '../graphql';
import {MapLocationProvider} from '../hooks/useMapLocationContext';
import {LayerSelectionProvider} from '../hooks/useLayerSelectionContext';
import {Platform} from 'react-native';
import WebLoading from './WebLoading';
import {AlertSubscriptionProvider} from '../hooks/useAlertSubscriptionContext';
import AlertSubscriptionController from './AlertSubscriptionController';
import PendingReportController from './PendingReportController';
import ParametersController from './ParametersController';

function GraphQLController({
  children,
}: {
  children: (graphql: any) => ReactNode;
}) {
  const [graphql, setGraphql] = useState<any>(undefined);
  const {token} = useToken();

  useEffect(() => {
    setupGraphQL(undefined);

    const getGraphqlClient = async () => {
      let graphql = await setupGraphQL(token ? token : undefined);
      setGraphql(graphql);
    };

    getGraphqlClient();
  }, [token]);

  if (!graphql) {
    return Platform.select({
      web: <WebLoading />,
      default: null,
    });
  }

  return (
    <GraphqlCacheProvider persistor={graphql.persistor}>
      {children(graphql)}
    </GraphqlCacheProvider>
  );
}

export default function Bridge({children}: PropsWithChildren<{}>) {
  const loaded = useCachedResources();

  if (!loaded) {
    return Platform.select({
      web: <WebLoading />,
      default: null,
    });
  }

  return (
    <ErrorProvider>
      <TokenProvider>
        <TokenController>
          <GraphQLController>
            {({client}) => (
              <ApolloProvider client={client}>
                <AuthenticatedUserProvider>
                  <AuthenticatedUserController>
                    <NetworkConnectionProvider>
                      <LoadingProvider>
                        <NotificationProvider>
                          <LocationProvider>
                            <ReportProvider>
                              <ParametersController />
                              <PendingReportController />
                              <MapLocationProvider>
                                <LayerSelectionProvider>
                                  <AlertSubscriptionProvider>
                                    <AlertSubscriptionController />
                                    {children}
                                  </AlertSubscriptionProvider>
                                </LayerSelectionProvider>
                              </MapLocationProvider>
                            </ReportProvider>
                            <LocationController />
                          </LocationProvider>
                        </NotificationProvider>
                      </LoadingProvider>
                    </NetworkConnectionProvider>
                  </AuthenticatedUserController>
                </AuthenticatedUserProvider>
              </ApolloProvider>
            )}
          </GraphQLController>
        </TokenController>
      </TokenProvider>
    </ErrorProvider>
  );
}
