import React, {useState, useEffect} from 'react';
import GoogleMapReact from 'google-map-react';
import {MapProps} from './Map';
import {BoundsInput} from '../graphql-types';
import Text from './Text';
import styled from 'styled-components';
import colors from '../constants/colors';
import {convertBoundsInputToRegion} from '../utils';
import {useMapLocation} from '../hooks/useMapLocationContext';
import Constants from 'expo-constants';
import location from '../constants/location';

const {google} = Constants.manifest.extra;

export default function Map({children, onSetBounds, ...props}: MapProps) {
  const [bounds, setBounds] = useState<BoundsInput>(props.bounds);
  const {location} = useMapLocation();

  const region = convertBoundsInputToRegion(bounds);
  const [center, setCenter] = useState({
    lat: region.latitude,
    lng: region.longitude,
  });

  useEffect(() => {
    if (!location) {
      return;
    }

    setCenter({
      lat: location.latitude,
      lng: location.longitude,
    });
  }, [location]);

  return (
    <div style={{height: 'calc(100vh - 86px)', width: '100%'}}>
      <GoogleMapReact
        onChange={(e: GoogleMapReact.ChangeEventValue) => {
          setBounds({
            northEast: {
              latitude: e.bounds.ne.lat,
              longitude: e.bounds.ne.lng,
            },
            southWest: {
              latitude: e.bounds.sw.lat,
              longitude: e.bounds.sw.lng,
            },
          });
        }}
        options={{
          zoomControl: true,
          fullscreenControl: false,
          scaleControl: true,
        }}
        bootstrapURLKeys={{key: google.mapsApiKey}}
        defaultZoom={6}
        center={{...center}}>
        {children}
      </GoogleMapReact>
    </div>
  );
}
