import * as React from 'react';
import ContentModal from '../components/content-modal';
import MailIcon from '../../assets/images/mail-icon.png';
import styled from 'styled-components/native';
import config from './config';
import {Text} from 'react-native';
import Resources from '../../components/Resources';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
`;

export default function ResourcesScreen() {
  return (
    <Screen title="Resources" icon={MailIcon}>
      <Resources />
    </Screen>
  );
}
