import React from 'react';
import styled from 'styled-components/native';
import CancelIcon from '../../../assets/images/cancel-icon-white.png';
import {useNavigation, StackActions} from '@react-navigation/native';
import {
  Image,
  TouchableOpacity,
  View,
  GestureResponderEvent,
  Dimensions,
} from 'react-native';
import colors from '../../../constants/colors';
import Text from '../../../components/Text';
import {ScrollView} from 'react-native';
import constants from '../../../constants/constants';

function findHeight() {
	let windowHeight = Dimensions.get('window').height;
	let height = ((windowHeight - constants.heightReduce) < constants.minModalHeight) ? 
		constants.minModalHeight : windowHeight - constants.heightReduce;
	return height;
}

const Content = styled(View)<{width?: number | false}>`
  background: #ffffff;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: ${({width = false}) => (width ? `${width}` : constants.defaultWidth)}px;
  margin: auto;
  font-family: tablet-gothic;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const Header = styled(View)`
  font-size: 20px;
  font-weight: bold;
  flex-direction: row;
  align-items: center;
  padding: 8px 15px 5px 15px;
  background: ${colors.mote.primary.blue};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;

const Icon = styled(Image)`
  margin-right: 10px;
  margin-bottom: 5px;
  width: 24px;
  height: 24px;
`;

const Cancel = styled(Image)`
  width: 24px;
  height: 24px;
`;

const CloseButton = styled(TouchableOpacity)``;

const Title = styled(View)`
  color: ${colors.app.white};
  flex-grow: 1;
`;

const TitleText = styled(Text)`
  color: ${colors.app.white};
  font-size: 20px;
`;

//	Can have scrollable or non-scrollable content views
const ContentContainer = styled(ScrollView).attrs({})`
`;

const ContentNoScroll = styled(View).attrs({})`
`;

type ContentModalProps = React.PropsWithChildren<{
  title?: string;
  icon?: any;
  width?: number | false;
  scrollEnable?: boolean | false;
  onCancel?: (e: GestureResponderEvent) => void;
}>;

export default function ContentModal({
  title,
  children,
  icon,
  width,
  scrollEnable,
  onCancel,
  ...props
}: ContentModalProps) {
  const navigation = useNavigation();

  const defaultOnCancel = (e: GestureResponderEvent) => {
    e.preventDefault();

    if (navigation.canGoBack()) {
      return navigation.goBack();
    }

    navigation.reset({
      index: 0,
      routes: [
        {
          name: 'Root',
          state: {
            index: 0,
            routes: [{name: 'Map'}],
          },
        },
      ],
    });
  };

  return (
    <Content width={width} maxHeight={findHeight()} {...props}>
      {title ? (
        <Header>
          {icon ? <Icon source={icon} /> : null}
          <Title>
            <TitleText>{title}</TitleText>
          </Title>
          <CloseButton onPress={onCancel ? onCancel : defaultOnCancel}>
            <Cancel source={CancelIcon} />
          </CloseButton>
        </Header>
      ) : null}
      {scrollEnable ? (
      	<ContentContainer nestedScrollEnabled = {true}>{children}</ContentContainer>
      ) : (
      	<ContentNoScroll>{children}</ContentNoScroll>
	   )}
      <View style={{height: 5}} />
    </Content>
  );
}
