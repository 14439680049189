import React, {useState} from 'react';
import {
  View,
  ImageBackground,
  Pressable,
  ImageSourcePropType,
} from 'react-native';
import FloatingActionButton from '../../components/FloatingActionButton';
import styled from 'styled-components/native';
import Search from '../../components/search';
import * as S from './styles';
import {useNavigation} from '@react-navigation/native';
import Text from '../../components/Text';
import constants from '../../constants/constants';
import CreateReportIcon from '../../assets/images/create-report-icon-blue.png';
import UserAuthenticatedBackground from '../../assets/images/user-authenticated-background.png';
import SelectLayerIcon from '../../assets/images/select-layer-icon.png';
import {useAuthenticatedUser} from '../../hooks/useAuthenticatedUserContext';
import {useToken} from '../../hooks/useTokenContext';
import {useGraphqlCache} from '../../hooks/useGraphqlCacheContext';
import MappedReports from '../../components/mapped-reports';
import {useLocation} from '../../hooks/useLocationContext';
import DefaultLocation from '../../constants/location';
import {useLayerSelection} from '../../hooks/useLayerSelectionContext';
import SelectLayer from '../../components/select-layer';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import LegendBox from '../../components/LegendBox';

const MapContainer = styled(View)`
  flex: 1;

  align-items: center;
  justify-content: center;
`;

const UserBox = styled(ImageBackground)`
  position: absolute;
  bottom: 0%;
  height: 40px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 15px;
  z-index: 10000;
`;

const User = styled(View)`
  flex-grow: 1;
  display: flex;
`;

const Logout = styled(Pressable)`
  font-family: tablet-gothic;
`;

export default function MapScreen() {
  const insets = useSafeAreaInsets();
  const navigation = useNavigation();
  const {persistor} = useGraphqlCache();
  const {clear: clearToken} = useToken();
  const {user, clear: clearUser} = useAuthenticatedUser();
  const {layer} = useLayerSelection();
  const {location} = useLocation();
  const [selectLayer, setSelectLayer] = useState<boolean>(false);

// Login button
  const CreateReportButton = styled(FloatingActionButton)`
    position: absolute;
    bottom: 180px;
    left: 20px;
  	width: 40px;
  	height: 40px;
  `;

  const SelectLayerButton = styled(FloatingActionButton)`
    position: absolute;
    bottom: 120px;
    left: 20px;
  	height: 40px;
  	width: 40px;
    zIndex: 1;
  `;

  const SelectLayerContainer = styled(View)`
    position: absolute;
    top: 20%;
    left: 15px;
    right: 15px;
    zIndex: 1;
  `;
 
  return (
    <>
      <MapContainer>
        <MappedReports
          location={
            location || {
              latitude: DefaultLocation.latitude,
              longitude: DefaultLocation.longitude,
            }
          }
          layer={layer}
        />
        {user ? (
          <UserBox source={UserAuthenticatedBackground as ImageSourcePropType}>
            <User>
              <Text>Hello {user.user.name}</Text>
            </User>
            <Logout
              onPress={() => {
                clearToken();
                clearUser();
                persistor?.purge();
              }}>
              <Text>Logout</Text>
            </Logout>
          </UserBox>
        ) : null}
      
      <S.SearchBox top={insets.top}>
        <Search />
      </S.SearchBox>
      <CreateReportButton
        icon={CreateReportIcon}
        onPress={() => navigation.navigate('CreateReportScreen')}
      />
      <SelectLayerButton
        icon={SelectLayerIcon}
        onPress={_ => setSelectLayer(true)}
      />
      {selectLayer ? (
        <SelectLayerContainer>
          <SelectLayer
            onClose={() => {
              setSelectLayer(false);
            }}
          />
        </SelectLayerContainer>
      ) : null}
        <LegendBox />
      </MapContainer>
    </>
  );
}
