import {NavigationContainer} from '@react-navigation/native';
import {createStackNavigator} from '@react-navigation/stack';
import * as React from 'react';
import {RootStackParamList, BeachStackParamList, SearchStackParamList} from '../../types';
import Linking from './Linking';
import CreateReportScreen from '../screens/CreateReportScreen';
import SelectParameterCategoryScreen from '../screens/SelectParameterCategoryScreen';
import SignInUserScreen from '../screens/SignInUserScreen';
import {useAuthenticatedUser} from '../../hooks/useAuthenticatedUserContext';
import WelcomeScreen from '../screens/WelcomeScreen';
import BCRSWizardScreen from '../screens/BCRSWizardScreen';
import CSICWizardScreen from '../screens/CSICWizardScreen';
import ReportSuccessScreen from '../screens/ReportSuccessScreen';
import ReportPendingScreen from '../screens/ReportPendingScreen';
import ReportFailedScreen from '../screens/ReportFailedScreen';
import BeachScreen from '../screens/BeachScreen';
import BCRSSelectPhotosScreen from '../screens/BCRSSelectPhotosScreen';
import BCRSReviewScreen from '../screens/BCRSReviewScreen';
import BeachReportScreen from '../screens/BeachReportScreen';
import BeachAmenitiesScreen from '../screens/BeachAmenitiesScreen';
import BeachPhotosScreen from '../screens/BeachPhotosScreen';
import SearchScreen from '../screens/SearchScreen';
import colors from '../../constants/colors';

const Stack = createStackNavigator<RootStackParamList>();

function AuthenticatedRoutes() {
  return (
    <>
      <Stack.Screen name="CreateReportScreen" component={CreateReportScreen} />
      <Stack.Screen
        name="SelectParameterCategoryScreen"
        component={SelectParameterCategoryScreen}
      />
      <Stack.Screen name="BCRSWizardScreen" component={BCRSWizardScreen} />
      <Stack.Screen
        name="BCRSSelectPhotosScreen"
        component={BCRSSelectPhotosScreen}
      />
      <Stack.Screen name="BCRSReviewScreen" component={BCRSReviewScreen} />
      <Stack.Screen name="CSICWizardScreen" component={CSICWizardScreen} />
      <Stack.Screen
        name="ReportSuccessScreen"
        component={ReportSuccessScreen}
      />
      <Stack.Screen
        name="ReportPendingScreen"
        component={ReportPendingScreen}
      />
      <Stack.Screen name="ReportFailedScreen" component={ReportFailedScreen} />
    </>
  );
}

function UnauthenticatedRoutes() {
  return (
    <>
      <Stack.Screen name="CreateReportScreen" component={SignInUserScreen} />
    </>
  );
}

const BeachScreenStack = createStackNavigator<BeachStackParamList>();

function BeachScreenNavigator() {
  return (
    <BeachScreenStack.Navigator
      headerMode="none"
      screenOptions={{
        headerShown: false,
        animationEnabled: false,
      }}>
      <BeachScreenStack.Screen name="BeachScreen" component={BeachScreen} />
      <BeachScreenStack.Screen
        name="BeachReportScreen"
        component={BeachReportScreen}
      />
      <BeachScreenStack.Screen
        name="BeachAmenitiesScreen"
        component={BeachAmenitiesScreen}
      />
      <BeachScreenStack.Screen
        name="BeachPhotosScreen"
        component={BeachPhotosScreen}
      />
    </BeachScreenStack.Navigator>
  );
}

const SearchScreenStack = createStackNavigator<SearchStackParamList>();

function SearchScreenNavigator() {
  return (
    <SearchScreenStack.Navigator headerMode="none">
      <SearchScreenStack.Screen
        name="Search"
        component={SearchScreen}
        options={{
        	headerTitle: "Search",
        	headerStyle: {
        		backgroundColor: colors.mote.primary.blue,
        	},
        }}
      />
    </SearchScreenStack.Navigator>
  );
}

export default function Navigation({
  navigator,
}: {
  navigator: React.FunctionComponent | React.ComponentClass;
}) {
  const {user} = useAuthenticatedUser();

  return (
    <NavigationContainer linking={Linking}>
      <Stack.Navigator
        initialRouteName="Welcome"
        screenOptions={{
          headerShown: false,
          animationEnabled: false,
          safeAreaInsets: {top: 0},
        }}>
        <Stack.Screen name="Root" component={navigator} />
        <Stack.Screen name="Welcome" component={WelcomeScreen} />
        {user ? AuthenticatedRoutes() : UnauthenticatedRoutes()}
        <Stack.Screen name="Beach" component={BeachScreenNavigator} />
        <Stack.Screen name="Search" component={SearchScreenNavigator} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}
