import React from 'react';
import CreateBCRSReport from '../../components/bcrs/create-report';
import CreateCSICReport from '../../components/csic/create-report';
import {useNavigation, StackActions} from '@react-navigation/native';
import {TouchableOpacity, ImageSourcePropType} from 'react-native';

import * as S from './styles';

import BeachStock from '../../assets/images/beach-stock.jpeg';
import CancelIcon from '../../assets/images/cancel-icon.png';
import {useAuthenticatedUser} from '../../hooks/useAuthenticatedUserContext';
import {LayerType} from '../../graphql-types';

export default function CreateReportScreen() {
  const navigation = useNavigation();
  const {user} = useAuthenticatedUser();

  if (!user) {
    // should alrady be authenticated at this point
    return null;
  }
 
  return (
    <>
      <S.Header>
        <S.Title>{user.type === LayerType.Csic ? 'Community' : 'BCRS'}</S.Title>
        <TouchableOpacity
          onPress={_ => {
            if (navigation.canGoBack()) {
              return navigation.goBack();
            }

            navigation.navigate('Root', {screen: 'Map'});
          }}>
          <S.Cancel source={CancelIcon as ImageSourcePropType} />
        </TouchableOpacity>
      </S.Header>
      <S.Container>
        <S.Background source={BeachStock as ImageSourcePropType}>
          {user.type === LayerType.Csic ? (
            <CreateCSICReport />
          ) : (
            <CreateBCRSReport />
          )}
        </S.Background>
      </S.Container>
    </>
  );
}
