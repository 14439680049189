import React from 'react';
import ForwardIcon from '../../assets/images/forward-icon.png';
import TextBox from '../../components/TextBox';
import {Field} from '../shared/styles';
import FormError from '../shared/FormError';
import {FormInputs} from '.';
import {UseFormMethods, Controller} from 'react-hook-form';
import {View} from 'react-native';
import ListItemButton from '../ListItemButton';
import styled from 'styled-components/native';
import colors from '../../constants/colors';

const Container = styled(View)`
  background: ${colors.mote.primary.lightBlue};
`;

type FormProps = {
  onSubmit: (props: FormInputs) => void;
} & UseFormMethods<FormInputs>;

export default function Form({
  onSubmit,
  control,
  handleSubmit,
  errors,
}: FormProps) {
  return (
    <Container>
      <TextBox>
        Contact us below with questions regarding BCRS reports or how you can
        become a BCRS Beach Ambassador!
      </TextBox>
      <TextBox bold={true}>Your Name</TextBox>
      <Controller
        control={control}
        render={({onChange, onBlur, value}) => (
          <Field
            value={value}
            onBlur={onBlur}
            placeholder={`Enter your name`}
            onChangeText={value => onChange(value)}
          />
        )}
        name="name"
        defaultValue=""
      />
      {errors.name ? <FormError>{errors.name.message}</FormError> : null}

      <TextBox bold={true}>Your Email</TextBox>
      <Controller
        control={control}
        render={({onChange, onBlur, value}) => (
          <Field
            value={value}
            onBlur={onBlur}
            placeholder={`Enter your email`}
            onChangeText={value => onChange(value)}
          />
        )}
        name="email"
        defaultValue=""
      />
      {errors.email ? <FormError>{errors.email.message}</FormError> : null}

      <TextBox bold={true}>Verify Your Email</TextBox>
      <Controller
        control={control}
        render={({onChange, onBlur, value}) => (
          <Field
            value={value}
            onBlur={onBlur}
            placeholder={`Confirm your email`}
            onChangeText={value => onChange(value)}
          />
        )}
        name="emailVerified"
        defaultValue=""
      />
      {errors.emailVerified ? (
        <FormError>{errors.emailVerified.message}</FormError>
      ) : null}

      <TextBox bold={true}>Message</TextBox>
      <Controller
        control={control}
        render={({onChange, onBlur, value}) => (
          <Field
            value={value}
            onBlur={onBlur}
            placeholder={`Enter a message`}
            onChangeText={value => onChange(value)}
            multiline={true}
            numberOfLines={5}
          />
        )}
        name="message"
        defaultValue=""
      />
      {errors.message ? <FormError>{errors.message.message}</FormError> : null}

      <ListItemButton
        title="Send Message"
        icon={ForwardIcon}
        onPress={handleSubmit(onSubmit)}
      />
      <View style={{flexGrow: 1, paddingBottom: 5}} />
    </Container>
  );
}
