import React from 'react';
import {Text as TextRN, TextProps} from 'react-native';
import styled from 'styled-components/native';

const StyledText = styled(TextRN)`
  font-family: tablet-gothic;
`;

export default function Text({
  children,
  ...props
}: React.PropsWithChildren<TextProps>) {
  return <StyledText {...props}>{children}</StyledText>;
}
