import * as React from 'react';
import ContentModal from '../components/content-modal';
import styled from 'styled-components/native';
import config from './config';
import Review from '../../components/bcrs/review';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
  display: flex;
  flex-direction: column;
`;

export default function BCRSReviewScreen() {
  return (
    <Screen title="Review">
      <Review />
    </Screen>
  );
}
