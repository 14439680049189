import React, {useState} from 'react';
import TextBox from '../../TextBox';
import styled from 'styled-components/native';
import Value from './Value';
import {ParameterTypeProps} from '..';
import {ParameterValueFragment} from '../../../graphql-types';
import colors from '../../../constants/colors';
import ListItemButton from '../../ListItemButton';
import ForwardIcon from '../../../assets/images/forward-icon.png';
import {View} from 'react-native';

const Prompt = styled(TextBox)`
  padding-top: 20px;
  font-size: 18px;
  margin-bottom: 1px;
  background: ${colors.app.white};
`;

const Description = styled(TextBox)`
  background: ${colors.app.white};
  padding-bottom: 0;
`;

const NextButton = styled(ListItemButton)`
  background: ${colors.app.white};
`;

export default function Multi({
  parameter,
  storeReportParameterValue,
  nextParameter,
  reportParameter,
}: ParameterTypeProps) {
  const selectValue = (parameterValue: ParameterValueFragment) => {
    let reportParameterValues = reportParameter?.reportParameterValues ?? [];

    reportParameterValues.push({
      parameterValueId: parameterValue.id,
      value: parameterValue.value,
    });

    storeReportParameterValue({
      parameterId: parameter.id,
      nextParameterId: parameter.nextParameter?.id,
      reportParameterValues: reportParameterValues,
    });
  };

  const deselectValue = (parameterValue: ParameterValueFragment) => {
    storeReportParameterValue({
      parameterId: parameter.id,
      nextParameterId: parameter.nextParameter?.id,
      reportParameterValues:
        reportParameter?.reportParameterValues?.filter(
          value => value.parameterValueId !== parameterValue.id,
        ) ?? [],
    });
  };

  return (
    <>
      {parameter.parameterCategory ? (
        <Description>{parameter.parameterCategory.name}</Description>
      ) : null}
      {parameter.description ? (
        <Description>{parameter.description}</Description>
      ) : null}
      <Prompt bold={true}>{parameter.prompt ?? parameter.name}</Prompt>
      {parameter.parameterValues.map(parameterValue => (
        <Value
          parameterValue={parameterValue}
          key={parameterValue.id}
          checked={
            !!(
              reportParameter &&
              reportParameter.reportParameterValues?.find(
                value => value.parameterValueId == parameterValue.id,
              )
            )
          }
          onPress={() => {
            if (
              !!(
                reportParameter &&
                reportParameter.reportParameterValues?.find(
                  value => value.parameterValueId == parameterValue.id,
                )
              )
            ) {
              deselectValue(parameterValue);
            } else {
              selectValue(parameterValue);
            }
          }}
        />
      ))}
      <View style={{flexGrow: 1}} />
      <NextButton
        disabled={!reportParameter?.reportParameterValues?.length}
        title="Next"
        icon={ForwardIcon}
        onPress={() => {
          nextParameter(parameter.nextParameter?.id);
        }}
      />
    </>
  );
}
