import React, {useContext, PropsWithChildren, useState} from 'react';
import Storage from '../storage';

type SetToken = (token: string) => void;
type ClearToken = () => void;
type Token = string | false;

const TokenContext = React.createContext<{
  token?: Token;
  set: SetToken;
  clear: ClearToken;
}>({
  set: _ => {},
  clear: () => {},
});

const TokenProvider = ({children, ...props}: PropsWithChildren<{}>) => {
  const [state, setState] = useState<string | false | undefined>(undefined);

  const set: SetToken = value => {
    if (value) {
      Storage.setToken(value);
      setState(value);
    }
  };

  const clear: ClearToken = () => {
    Storage.clearToken();
    setState(false);
  };

  return (
    <TokenContext.Provider value={{token: state, set, clear}} {...props}>
      {children}
    </TokenContext.Provider>
  );
};

const useToken = () => useContext(TokenContext);

export {TokenProvider, useToken};
