import {FontAwesome} from '@expo/vector-icons';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {createStackNavigator} from '@react-navigation/stack';
import * as React from 'react';

import colors from '../../constants/colors';
import MapScreen from '../screens/MapScreen';
import AlertsSubscribeScreen from '../screens/AlertsSubscribeScreen';
import ResourcesScreen from '../screens/ResourcesScreen';
import ContactScreen from '../screens/ContactScreen';
import {
  MapStackParamList,
  AlertsStackParamList,
  ResourcesStackParamList,
  ContactStackParamList,
  BottomTabStackParamList,
  EducationStackParamList,
} from '../../types';
import {useAlertSubscription} from '../../hooks/useAlertSubscriptionContext';
import BeachAlertsScreen from '../screens/BeachAlertsScreen';
import AlertsVerifyEmailScreen from '../screens/AlertsVerifyEmailScreen';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import {View, Platform} from 'react-native';
import FrequentlyAskedQuestionsScreen from '../screens/FrequentlyAskedQuestionsScreen';
import FrequentlyAskedQuestionsCategoriesScreen from '../screens/FrequentlyAskedQuestionsCategoriesScreen';
import VideosScreen from '../screens/VideosScreen';
import EducationScreen from '../screens/EducationScreen';
import InfographicsScreen from '../screens/InfographicsScreen';

const BottomTab = createBottomTabNavigator<BottomTabStackParamList>();

export default function BottomTabNavigator() {
  const insets = useSafeAreaInsets();

  return (
    <View
      style={{
        backgroundColor: colors.mote.primary.blue,
        flex: 1,
      }}>
      <BottomTab.Navigator
        initialRouteName="Map"
        tabBarOptions={{
          style: {
            marginBottom: Platform.OS === 'android' ? 0 : insets.bottom,
            marginTop: 0,
          },
          keyboardHidesTabBar: true,
          safeAreaInsets: {bottom: 0, top: 0},
          ...colors.tabBar,
        }}>
        <BottomTab.Screen
          name="Map"
          component={MapScreenNavigator}
          options={{
            tabBarIcon: ({color}) => (
              <TabBarIcon name="map-marker" color={color} />
            ),
          }}
        />
        <BottomTab.Screen
          name="Alerts"
          component={AlertsScreenNavigator}
          options={{
            tabBarIcon: ({color}) => (
              <TabBarIcon name="envelope" color={color} />
            ),
          }}
        />
        <BottomTab.Screen
          name="Resources"
          component={ResourcesScreenNavigator}
          options={{
            tabBarIcon: ({color}) => <TabBarIcon name="book" color={color} />,
          }}
        />
        <BottomTab.Screen
          name="Contact"
          component={ContactScreenNavigator}
          options={{
            tabBarIcon: ({color}) => (
              <TabBarIcon name="comment" color={color} />
            ),
          }}
        />
        <BottomTab.Screen
          name="Education"
          component={EducationScreenNavigator}
          options={{
            tabBarIcon: ({color}) => (
              <TabBarIcon name="graduation-cap" color={color} />
            ),
          }}
        />
      </BottomTab.Navigator>
    </View>
  );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: {name: string; color: string}) {
  return <FontAwesome size={20} style={{marginBottom: -3}} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const MapScreenStack = createStackNavigator<MapStackParamList>();

function MapScreenNavigator() {
  return (
    <MapScreenStack.Navigator headerMode="none">
      <MapScreenStack.Screen name="MapScreen" component={MapScreen} />
    </MapScreenStack.Navigator>
  );
}

const AlertsScreenStack = createStackNavigator<AlertsStackParamList>();

function HasSubscriptionScreens() {
  return (
    <>
      <AlertsScreenStack.Screen
        name="BeachAlertsScreen"
        component={BeachAlertsScreen}
        options={{headerTitle: 'Manage Beach Alerts'}}
      />
    </>
  );
}

function NoSubscriptionScreens() {
  return (
    <>
      <AlertsScreenStack.Screen
        name="AlertsSubscribeScreen"
        component={AlertsSubscribeScreen}
        options={{headerTitle: 'Subscribe to Beach Alerts'}}
      />
      <AlertsScreenStack.Screen
        name="AlertsVerifyEmailScreen"
        component={AlertsVerifyEmailScreen}
        options={{headerTitle: 'Verify Email'}}
      />
      <AlertsScreenStack.Screen
        name="BeachAlertsScreen"
        component={AlertsSubscribeScreen}
        options={{headerTitle: 'Manage Beach Alerts'}}
      />
    </>
  );
}

function AlertsScreenNavigator() {
  const {subscription} = useAlertSubscription();

  return (
    <AlertsScreenStack.Navigator
      headerMode="none"
      screenOptions={{animationEnabled: false}}>
      {subscription ? HasSubscriptionScreens() : NoSubscriptionScreens()}
    </AlertsScreenStack.Navigator>
  );
}

const ResourcesScreenStack = createStackNavigator<ResourcesStackParamList>();

function ResourcesScreenNavigator() {
  return (
    <ResourcesScreenStack.Navigator headerMode="none">
      <ResourcesScreenStack.Screen
        name="ResourcesScreen"
        component={ResourcesScreen}
        options={{headerTitle: 'Resources'}}
      />
    </ResourcesScreenStack.Navigator>
  );
}

const ContactScreenStack = createStackNavigator<ContactStackParamList>();

function ContactScreenNavigator() {
  return (
    <ContactScreenStack.Navigator headerMode="none">
      <ContactScreenStack.Screen
        name="ContactScreen"
        component={ContactScreen}
        options={{headerTitle: 'Contact'}}
      />
    </ContactScreenStack.Navigator>
  );
}

const EducationScreenStack = createStackNavigator<EducationStackParamList>();

function EducationScreenNavigator() {
  return (
    <EducationScreenStack.Navigator headerMode="none">
      <EducationScreenStack.Screen
        name="EducationScreen"
        component={EducationScreen}
        options={{title: 'Education'}}
      />
      <EducationScreenStack.Screen
        name="FrequentlyAskedQuestionCategories"
        component={FrequentlyAskedQuestionsCategoriesScreen}
        options={{headerTitle: 'FAQs'}}
      />
      <EducationScreenStack.Screen
        name="FrequentlyAskedQuestions"
        component={FrequentlyAskedQuestionsScreen}
        options={{headerTitle: 'Frequently Asked Questions'}}
      />
      <EducationScreenStack.Screen
        name="Infographics"
        component={InfographicsScreen}
        options={{headerTitle: 'Infographics'}}
      />
      <EducationScreenStack.Screen
        name="Videos"
        component={VideosScreen}
        options={{headerTitle: 'Videos'}}
      />
    </EducationScreenStack.Navigator>
  );
}
