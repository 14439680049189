import * as React from 'react';
import {useState, useEffect} from 'react';
import ContentModal from '../components/content-modal';
import styled from 'styled-components/native';
import config from './config';
import {Text} from 'react-native';
import Search from '../../components/search/index ';
import BeachList from '../../components/search/BeachList';
import SearchResults from '../../components/search/SearchResults';
import AllResults from '../../components/search/AllResults';
import {useRoute, RouteProp, useNavigation} from '@react-navigation/native';

type SearchScreenProps = RouteProp<
  SearchStackParamList,
  'SearchScreen'
>;

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
`;

export default function SearchScreen() {
  	const route = useRoute();
  	const nav = useNavigation();

  	var searchText = (route.params && route.params.searchParam) ? route.params.searchParam : "";
  	if (global.saveSearch != "") {
  		searchText = global.saveSearch
  	}

  	return (
		<>
			{searchText ? (
				<Screen title="Search Results">
					<SearchResults searchText={searchText} />
				</Screen>
			) : (
				<Screen title="All Beaches">
					<AllResults searchText={searchText} />
				</Screen>
			)}
		</>
  	);
}
