import * as React from 'react';
import * as S from './styles';
import styled from 'styled-components/native';
import {View, Platform, Image, StyleSheet, ScrollView, Dimensions, Text, TouchableOpacity} from 'react-native';
import {useState, useEffect} from 'react';
import config from './config';
import SearchResults from '../../components/search/SearchResults';
import AllResults from '../../components/search/AllResults';
import {useRoute, RouteProp, useNavigation} from '@react-navigation/native';
import constants from '../../constants/constants';
import colors from '../../constants/colors';
import BackwardIcon from '../../assets/images/arrow-back.png';

type SearchScreenProps = RouteProp<
  SearchStackParamList,
  'SearchScreen'
>;

const Button = styled(TouchableOpacity)`
  flex-grow:1;
  flex: 1;
  flex-direction: row;
`;

const ButtonText = styled(Text)`
  padding: 14px 5px;
`;

const ButtonIcon = styled(Image)`
  width: 24px;
  height: 24px;
  margin: 10px 0 0 0;
`;

const SearchContainer = styled(ScrollView)`
	padding-top: 45px;
	padding-bottom: 40px;
  	display: flex;
  	flex-grow: 1;
`;

export default function SearchScreen() {
  	const route = useRoute();
  	const nav = useNavigation();

  	var searchText = (route.params && route.params.searchParam) ? route.params.searchParam : "";
  	if (global.saveSearch != "") {
  		searchText = global.saveSearch
  	}

  	return (
		<>
      		<S.Header style={{backgroundColor: colors.mote.primary.powderBlue}}>
        		<S.Title>{searchText ? "Search: Results" : "Search: All Beaches"}</S.Title>
				<Button
				  onPress={_ => {
					nav.reset({
						index:0,
						routes:[
							{	name: 'Root',
								state: {
									index:0,
									routes: [{screen: "Map"},],
								}
							}
						]
					})
				  }}>
				  <ButtonIcon source={BackwardIcon} />
				  <ButtonText>Back to Map</ButtonText>
				</Button>
      		</S.Header>
      		<SearchContainer>
      				{ searchText ? (
      					<SearchResults searchText={searchText} />

					) :(
						<AllResults searchText={searchText} />
					)}
			</SearchContainer>
		</>
  	);
}