import * as React from 'react';
import BeachStock from '../../assets/images/beach-stock.jpeg';
import * as S from './styles';
import {ImageSourcePropType} from 'react-native';
import BeachAlerts from '../../components/alerts/beach-alerts';

export default function BeachAlertsScreen() {
  return (
    <>
      <S.Header>
        <S.Title>Alerts</S.Title>
      </S.Header>
      <S.Container>
        <S.Background source={BeachStock as ImageSourcePropType}>
          <BeachAlerts />
        </S.Background>
      </S.Container>
    </>
  );
}
