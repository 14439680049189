import React from 'react';
import styled from 'styled-components/native';
import {View} from 'react-native';
import Text from '../Text';

const Header = styled(View)`
  display: flex;
  flex-direction: row;
  background-color: rgba(255, 255, 255, 0.6);
`;

const Title = styled(View)`
  padding: 10px;
`;

const Name = styled(View)``;
const NameText = styled(Text)`
  font-size: 18px;
`;

const Location = styled(View)``;

type BeachHeaderProps = {
  name: string;
  location: string;
};

export default function BeachHeader({name, location}: BeachHeaderProps) {
  return (
    <Header>
      <Title>
        <Name>
          <NameText>{name}</NameText>
        </Name>
        <Location>
          <Text>{location}</Text>
        </Location>
      </Title>
    </Header>
  );
}
