import gql from 'graphql-tag';
import {
  UserFragment,
  BeachFragment,
  FirstParameterFragment,
} from '../fragments';

export const getMeQuery = gql`
  query GetMe {
    me {
      ...User
      beaches {
        ...Beach
        ...FirstParameter
      }
    }
  }
  ${UserFragment}
  ${BeachFragment}
  ${FirstParameterFragment}
`;
