import React from 'react';
import {View, Image} from 'react-native';
import styled from 'styled-components/native';
import CheckIcon from '../assets/images/check-success.png';
import Text from './Text';

const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 30px;
  min-height: 300px;
`;

const Icon = styled(Image)`
  width: 60px;
  height: 60px;
`;

const Title = styled(Text)`
  font-size: 18px;
  text-align: center;
`;

const Content = styled(Text)`
  text-align: center;
  margin: 10px 0;
`;

const Thanks = styled(Text)`
  font-weight: bold;
`;

export default function ReportSuccess() {
  return (
    <Container>
      <Icon source={CheckIcon} />
      <Title>Report Success</Title>
      <Content>
        The report you just created has been successfully uploaded to the beach
        conditions reporting system. 
      </Content>
      <Thanks>Thank you!</Thanks>
    </Container>
  );
}
