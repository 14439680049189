import React from 'react';
import styled from 'styled-components/native';
import {View} from 'react-native';
import ListItemButton from '../ListItemButton';
import FaqIcon from '../../assets/images/faq-icon.png';
import VideosIcon from '../../assets/images/videos-icon.png';
import InfographicsIcon from '../../assets/images/infographics-icon.png';
import ArrowForward from '../../assets/images/forward-arrow.png';
import {useNavigation} from '@react-navigation/native';

const Container = styled(View)`
  	background-color: "white";
`;

export default function Education() {
  const navigation = useNavigation();

  return (
    <Container>
      <ListItemButton
        onPress={() => {
          navigation.navigate('FrequentlyAskedQuestionCategories');
        }}
        icon={ArrowForward}
        iconLeft={FaqIcon}
        title={'Frequently Asked Questions'}
      />
      <ListItemButton
        onPress={() => {
          navigation.navigate('Videos');
        }}
        icon={ArrowForward}
        iconLeft={VideosIcon}
        title={'Videos'}
      />
      <ListItemButton
        onPress={() => {
          navigation.navigate('Infographics');
        }}
        icon={ArrowForward}
        iconLeft={InfographicsIcon}
        title={'Infographics'}
      />
    </Container>
  );
}
