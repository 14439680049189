import React, {useEffect} from 'react';
import {useGetBeachesQuery} from '../../graphql-types';
import {useLoading} from '../../hooks/useLoadingContext';
import BeachList from './BeachList';
import Text from '../Text';
import colors from '../../constants/colors';
import styled from 'styled-components/native';
import {View} from 'react-native';

type AllResultsProps = {
  searchText: string;
  onPress: () => void;
};

const NoResultsFound = styled(View)`
  margin-top: 5px;
  background-color: ${colors.app.white};
  padding: 10px;
`;

export default function AllResults({
  searchText,
  onPress,
}: AllResultsProps) {
  	const {set: setLoading, clear: clearLoading} = useLoading();
  	const {data, loading} = useGetBeachesQuery();

  useEffect(() => {
    if (loading) {
     setLoading();
    }
    clearLoading();
  }, [loading]);

  if (!data || !data.beaches) {
    // @todo error check this
    return null;
  }
	
  return (
    <>
      {data.beaches.length ? (
        <BeachList beaches={data.beaches} onPress={onPress} searchText={searchText}/>
      ) : (
        <NoResultsFound>
          <Text>No results found.</Text>
        </NoResultsFound>
      )}
    </>
  );
}
