import React, {useState} from 'react';
import styled from 'styled-components/native';
import config from './config';
import ContentModal from '../components/content-modal';
import BeachReport from '../../components/beach/report';
import ParameterInfo, {IParameterInfo} from '../components/ParameterInfo';

const Screen = styled(ContentModal)`
  position: absolute;
  top: ${config.modals.top}px;
  right: 15px;
  width: 33vw;
`;

export default function BeachReportScreen() {
  const [parameterInfo, setParameterInfo] = useState<false | IParameterInfo>(
    false,
  );
  return (
    <>
      <Screen title={'Reports'} scrollEnable={false}>
        <BeachReport setParameterInfo={setParameterInfo} />
      </Screen>
      {parameterInfo ? (
        <ParameterInfo
          parameterInfo={parameterInfo}
          setParameterInfo={setParameterInfo}
        />
      ) : null}
    </>
  );
}
