import React, {useState} from 'react';
import BeachStock from '../../assets/images/beach-stock.jpeg';
import * as S from './styles';
import {ImageSourcePropType, TouchableOpacity, Image, View} from 'react-native';
import styled from 'styled-components/native';
import Text from '../../components/Text';
import BackwardIcon from '../../assets/images/arrow-back.png';
import {useNavigation} from '@react-navigation/native';
import BeachReport from '../../components/beach/report';
import ParameterInfo, {IParameterInfo} from '../components/ParameterInfo';

const Button = styled(TouchableOpacity)`
  flex-grow: 1;
  flex: 1;
  flex-direction: row;
`;

const ButtonText = styled(Text)`
  padding: 14px 5px;
`;

const ButtonIcon = styled(Image)`
  width: 24px;
  height: 24px;
  margin: 8px 0 0 10px;
`;

export default function BeachReportScreen() {
  const navigation = useNavigation();
  const [parameterInfo, setParameterInfo] = useState<false | IParameterInfo>(
    false,
  );

  return (
    <>
      <S.Header>
        <S.Title>Beach Reports</S.Title>
        <Button
          onPress={_ => {
            if (navigation.canGoBack()) {
              return navigation.goBack();
            }

            navigation.navigate('Root', {screen: 'Map'});
          }}>
          <ButtonIcon source={BackwardIcon} />
          <ButtonText>Back</ButtonText>
        </Button>
      </S.Header>
      <S.Container>
        <S.Background source={BeachStock as ImageSourcePropType}>
          <BeachReport setParameterInfo={setParameterInfo} />
        </S.Background>
      </S.Container>
      {parameterInfo ? (
        <ParameterInfo
          parameterInfo={parameterInfo}
          setParameterInfo={setParameterInfo}
        />
      ) : null}
    </>
  );
}
